import React, { Suspense, lazy } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { Select, Switch } from 'components/form'
import { convertDate, createExcel, readExcel } from 'utilities/excel'
import {
  getSelectOptions,
  handleSelectChange,
  handleTextChange,
  initializeState,
  parseAddress,
  validateForm,
} from 'utilities/form'
import { getLocation } from 'actions/location'
import { request } from 'utilities/request'
import { Center, Definition, LoadingIcon } from 'components/core'
import { ALERT_ADD } from 'constants/actionType'
import { filterLocations, hasAccess } from 'utilities/permission'

const FileInput = lazy(() => import('components/file/FileInput'))

export const initialState = (value = {}) => {
  const dealerMap = value.dealers?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const recipientMap = value.recipients?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const billingMap = value.billings?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const shippingMap = value.shippings?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const warehouseGroupMap = value.warehouseGroups?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const warehouseMap = value.warehouses?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const productMap = value.products?.reduce((result, item) => {
    result[item.sku] = { value: item.id, label: item.name }
    return result
  }, {})

  const salesMap = value.sales?.reduce((result, item) => {
    result[item.name] = item.id
    return result
  }, {})

  return {
    deferId: value.deferId,
    dealers: value.dealers || [],
    dealerLocations: value.dealerLocations || [],
    dealerMap,
    recipients: value.recipients || [],
    recipientLocations: value.recipientLocations || [],
    recipientMap,
    dealerRecipients: [],
    billings: value.billings || [],
    billingMap,
    shippings: value.shippings || [],
    shippingMap,
    warehouseMap,
    warehouseGroupMap,
    products: getSelectOptions(value.products),
    productMap,
    sales: getSelectOptions(value.sales),
    salesMap,
    backups: getSelectOptions(value.backups),
    ticketItems: value.ticketItems || [],
    warehouseGroups: value.warehouseGroups,
    allWarehouses: value.warehouses,
    warehouses: value.warehouses,
    backupId: [],
    ...initializeState({
      warehouseGroupId: '',
      warehouseId: '',
      dealerId: '',
      recipientId: '',
      shippingId: '',
      salesId: [],
      isDirect: false,
      // breakCombo: false,
      skipConfirmation: false,
      file: '',
    }),
  }
}

const columns = [
  { id: 'ticketNo', label: 'dispatch.field.ticketNo' },
  { id: 'isUrgent', label: 'dispatch.field.isUrgent' },
  { id: 'isDirect', label: 'dispatch.field.isDirect' },
  { id: 'transDate', label: 'dispatch.field.transDate' },
  { id: 'arriveDate', label: 'dispatch.field.arriveDate' },
  { id: 'arrivePeriod', label: 'dispatch.field.arrivePeriod' },
  { id: 'billingId', label: 'dispatch.field.billingId' },
  { id: 'dealerId', label: 'dispatch.field.dealerId' },
  { id: 'dealerEIN', label: 'dispatch.field.dealerEIN' },
  { id: 'dealerAddress', label: 'dispatch.field.dealerAddress' },
  { id: 'recipientId', label: 'dispatch.field.recipientId' },
  { id: 'recipientContact', label: 'dispatch.field.recipientContact' },
  { id: 'recipientAddress', label: 'dispatch.field.recipientAddress' },
  { id: 'elevator', label: 'field.elevator' },
  { id: 'recipientPhone', label: 'dispatch.field.recipientPhone' },
  { id: 'recipientCellphone', label: 'dispatch.field.recipientCellphone' },
  { id: 'recipientEmail', label: 'dispatch.field.recipientEmail' },
  { id: 'receiptNo', label: 'dispatch.field.receiptNo' },
  { id: 'warehouseGroupId', label: 'dispatch.field.warehouseGroupId' },
  { id: 'warehouseId', label: 'dispatch.field.warehouseId' },
  { id: 'shippingId', label: 'dispatch.field.shippingId' },
  { id: 'salesId', label: 'dispatch.field.salesId' },
  { id: 'memo', label: 'dispatch.field.memo' },
  { id: 'handleType', label: 'dispatch.field.handleType' },
  { id: 'handleNo', label: 'dispatch.field.handleNo' },
  { id: 'productVariantId', label: 'dispatch.field.sku' },
  { id: 'isGift', label: 'dispatch.field.isGift' },
  { id: 'quantity', label: 'dispatch.field.quantity' },
  { id: 'price', label: 'dispatch.field.price' },
  { id: 'expireAt', label: 'dispatch.field.expireAt' },
  { id: 'batchNo', label: 'dispatch.field.batchNo' },
  { id: 'itemMemo', label: 'dispatch.field.itemMemo' },
]

const validation = {
  // warehouseGroupId: [{ type: 'required', message: 'error.required' }],
  // warehouseId: [{ type: 'required', message: 'error.required' }],
  file: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    deferId: (
      <Definition
        show={state.deferId}
        label="dispatch.field.deferId"
        value={state.deferId}
      />
    ),
    warehouseGroupId: (
      <Select
        isClearable={false}
        label="dispatch.field.warehouseGroupId"
        options={state.warehouseGroups}
        value={state.warehouseGroupId}
        onChange={onSelectChange('warehouseGroupId', ({ value }) => {
          const warehouses = state.allWarehouses.filter(
            (item) => item.parentId === value,
          )
          return { warehouses }
        })}
        errMsg={state.__error__.warehouseGroupId}
      />
    ),
    warehouseId: (
      <Select
        label="field.warehouse"
        options={state.warehouses}
        value={state.warehouseId}
        onChange={onSelectChange('warehouseId', (val) => {
          const value = val?.value || ''
          const dealerId = state.dealerId?.value
          if (!dealerId) return { skipConfirmation: false }

          const dealer = state.dealerLocations.find(
            (item) => item.id === dealerId,
          )
          if (!dealer) return { skipConfirmation: false }

          const { skipDispatchConfirm, skipDispatchWarehouseId } = dealer.extra
          if (!skipDispatchConfirm) return { skipConfirmation: false }

          const matchWarehouse = skipDispatchWarehouseId.includes(value)
          if (!matchWarehouse) return { skipConfirmation: false }

          return { skipConfirmation: true }
        })}
        errMsg={state.__error__.warehouseId}
      />
    ),
    dealerId: (
      <Select
        isClearable={false}
        label="field.dealer"
        options={state.dealers}
        value={state.dealerId}
        onChange={onSelectChange('dealerId', ({ value }) => {
          const dealerRecipients = state.recipients.filter(
            ({ parentId }) => parentId === 'all' || parentId === value,
          )

          const warehouseId = state.warehouseId?.value
          if (!warehouseId) return { dealerRecipients, skipConfirmation: false }

          const dealer = state.dealerLocations.find((item) => item.id === value)
          if (!dealer) return { dealerRecipients, skipConfirmation: false }

          const { skipDispatchConfirm, skipDispatchWarehouseId } = dealer.extra
          if (!skipDispatchConfirm) {
            return { dealerRecipients, skipConfirmation: false }
          }

          const matchWarehouse = skipDispatchWarehouseId.includes(warehouseId)
          if (!matchWarehouse) {
            return { dealerRecipients, skipConfirmation: false }
          }

          return { dealerRecipients, skipConfirmation: true }
        })}
        errMsg={state.__error__.dealerId}
      />
    ),
    recipientId: (
      <Select
        isClearable={false}
        label="field.storage"
        options={state.dealerRecipients}
        value={state.recipientId}
        onChange={onSelectChange('recipientId')}
        errMsg={state.__error__.recipientId}
      />
    ),
    shippingId: (
      <Select
        label="dispatch.field.shippingId"
        options={state.shippings}
        value={state.shippingId}
        onChange={onSelectChange('shippingId')}
        errMsg={state.__error__.shippingId}
      />
    ),
    sales: (
      <Select
        label="dispatch.field.salesId"
        isClearable={false}
        isMulti
        options={state.sales}
        value={state.salesId}
        onChange={onSelectChange('salesId')}
        errMsg={state.__error__.salesId}
      />
    ),
    backup: (
      <Select
        disabled={!hasAccess(app.state.staff, 'lockDispatchTicket')}
        label="dispatch.field.backup"
        isClearable={false}
        isMulti
        options={state.sales}
        value={state.backupId}
        onChange={onSelectChange('backupId')}
        errMsg={state.__error__.backupId}
      />
    ),
    isDirect: (
      <Definition
        direction="row"
        label="dispatch.field.isDirect"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isDirect}
          onClick={() => {
            setState({ ...state, isDirect: !state.isDirect })
          }}
        />
      </Definition>
    ),
    // breakCombo: (
    //   <Definition
    //     direction="row"
    //     label="dispatch.field.breakCombo"
    //     labelProps={{ flex: 1 }}
    //   >
    //     <Switch
    //       disabled={!state.dealerId.value}
    //       checked={state.breakCombo}
    //       onClick={() => {
    //         setState({ ...state, breakCombo: !state.breakCombo })
    //       }}
    //     />
    //   </Definition>
    // ),
    skipConfirmation: (
      <Definition
        direction="row"
        label="dispatch.field.skipConfirmation"
        labelProps={{ flex: 1 }}
      >
        <Switch
          disabled
          checked={state.skipConfirmation}
          onClick={() => {
            setState({ ...state, skipConfirmation: !state.skipConfirmation })
          }}
        />
      </Definition>
    ),
    file: (
      <Suspense
        fallback={
          <Center>
            <LoadingIcon />
          </Center>
        }
      >
        <FileInput
          id="file"
          label="dispatch.field.importFile"
          accept=".xlsx"
          value={state.file}
          onUpload={onTextChange('file')}
          onDelete={onTextChange('file')}
          onError={(errorMessages) =>
            errorMessages.forEach((item) => {
              session.dispatch({
                type: ALERT_ADD,
                item: { type: 'error', message: item },
              })
            })
          }
          errMsg={state.__error__.file}
        />
      </Suspense>
    ),
  }
}

export const handlers = ({
  deferId,
  app,
  session,
  state,
  setState,
  message,
  data: { cityMap, districtMap },
  action,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, message })
    data.deferId = deferId
    setState(initialState(data))
  },
  downloadTemplate: () => {
    const title = 'dispatch.message.template'
    createExcel({ message, title, timestamp: false, cols: columns, rows: [] })
  },
  loadExcel: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const comboCodeMap = await getComboCodeMap({ session, app, state })
    const cities = Object.keys(cityMap)
    const districts = Object.keys(districtMap)
    const excelFile = await readFile(state.file)
    const data = await readExcel(excelFile)
    const ticketItems = data.reduce((result, item) => {
      const row = parseRow({
        state,
        item,
        message,
        cityMap,
        cities,
        districtMap,
        districts,
      })

      const comboItems = getComboItems(row, comboCodeMap)
      if (comboItems) {
        comboItems.forEach((item) => {
          result.push(item)
        })
      } else {
        result.push(row)
      }

      return result
    }, [])

    const deferMap = await getDeferMap(app, session, state.deferId)
    if (state.deferId && !validDeferData(deferMap, ticketItems)) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'error.dispatch.invalidImportItem' },
      })
      return
    }

    setItemParentId(ticketItems, deferMap)
    setState({ ...state, ticketItems })
    action.setOpen(true)
  },
})

async function getDeferMap(app, session, id) {
  if (!id) return {}

  const variables = { id }
  const query = `
    query($id: ID) {
      deferTicketItems(ticketId: $id) {
        id
        sku
        isGift
        balance
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const itemMap = data.deferTicketItems.reduce((result, item) => {
    if (item.status !== 'ACTIVE') return result
    if (item.extra?.isPayment) return result
    if (item.extra?.isRevert) return result

    const key = `${item.sku}_${item.isGift}`
    const deferItem = result[key] || item
    const balance = deferItem.balance || 0
    deferItem.balance = balance + parseInt(item.balance)
    result[key] = deferItem
    return result
  }, {})

  return itemMap || {}
}

async function validDeferData(deferMap, ticketItems) {
  const itemMap = ticketItems.reduce((result, item) => {
    const key = `${item.sku}_${item.isGift}`
    const balance = result[key] || 0
    result[key] = balance + parseInt(item.quantity)
    return result
  }, {})

  for (const deferItem of Object.values(deferMap)) {
    const quantity = itemMap[`${deferItem.sku}_${deferItem.isGift}`]
    if (deferItem.balance > quantity) return false
  }

  for (const item of Object.values(itemMap)) {
    const deferItem = deferMap[`${item.sku}_${item.isGift}`]
    if (!deferItem) return false
  }

  return true
}

async function setItemParentId(ticketItems, deferMap) {
  ticketItems.forEach((item) => {
    const deferItem = deferMap[`${item.sku}_${item.isGift}`]
    item.parentItemId = deferItem?.id
  })
}

async function getData({ app, session, message }) {
  const staffInput = { type: ['SALES'] }
  const locationInput = {
    type: ['WAREHOUSE_GROUP', 'WAREHOUSE', 'DEALER', 'STORAGE', 'COMPANY'],
  }
  const variables = { locationInput, staffInput }
  const query = `
    query($locationInput: LocationQueryInput, $staffInput: StaffQueryInput) {
      locations(input: $locationInput) {
        id
        parentId
        name
        type
        extra
      }
      staffs(input: $staffInput) {
        id
        name
      }
      productVariants {
        id
        name
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { staffs, productVariants } = data
  const warehouseGroups = []
  const warehouses = []
  const dealers = []
  const dealerLocations = []
  const recipients = [
    {
      value: '__INDIVIDUAL__',
      label: message({ id: 'dispatch.recipient.individual' }),
      parentId: 'all',
    },
  ]
  const recipientLocations = []
  const billings = []
  const shippings = []
  const locations = filterLocations(app.state.staff, data.locations)

  data.locations.forEach((item) => {
    if (item.type === 'WAREHOUSE_GROUP') {
      warehouseGroups.push({ label: item.name, value: item.id })
    }
  })

  locations.forEach((item) => {
    const { type, extra } = item
    const result = { label: item.name, value: item.id }
    switch (type) {
      // case 'WAREHOUSE_GROUP':
      //   warehouseGroups.push(result)
      //   break
      case 'WAREHOUSE':
        result.parentId = item.parentId
        warehouses.push(result)
        break
      case 'DEALER':
        dealers.push(result)
        dealerLocations.push(item)
        break
      case 'STORAGE':
        result.parentId = item.parentId
        recipients.push(result)
        recipientLocations.push(item)
        break
      case 'COMPANY':
        if (extra.type === 'BILLING') billings.push(result)
        if (extra.type === 'SHIPPING') shippings.push(result)
        break
      default:
        break
    }
  })

  return {
    warehouseGroups,
    warehouses,
    dealers,
    dealerLocations,
    recipients,
    recipientLocations,
    billings,
    shippings,
    sales: staffs,
    products: productVariants,
  }
}

async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}

function getBackupId(locations, locationId) {
  if (!locationId || locationId === '__INDIVIDUAL__') return null
  const location = getLocation(locations, locationId)
  const extra = location?.extra || {}
  return extra.salesId?.map((id, index) => ({
    value: id,
    label: extra.salesName[index],
  }))
}

async function getComboCodeMap({ app, session }) {
  const variables = { input: {} }
  const query = `
    query($input: ProductComboCodeQueryInput) {
      productComboCodes(input: $input) {
        locationId
        code
        products
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  return data.productComboCodes.reduce((result, item) => {
    const key = `${item.locationId}::${item.code}`
    result[key] = item.products
    return result
  }, {})
}

function parseRow({
  state,
  item,
  message,
  cityMap,
  cities,
  districtMap,
  districts,
}) {
  const row = {}

  columns.forEach(({ id, label }) => {
    let value = item[message({ id: label })]
    if (typeof value === 'string') value = value.trim()

    const dealerId = state.dealerMap[value]
    const recipientId = state.recipientMap[value]
    const shippingId = state.shippingMap[value]
    const warehouseGroupId = state.warehouseGroupMap[value]
    const warehouseId = state.warehouseMap[value]
    const billingId = state.billingMap[value]
    const productOption = state.productMap[value]
    let backupId = null

    switch (id) {
      case 'ticketNo':
        value = `${value}`
        break
      case 'dealerId':
        value = dealerId ? { value: dealerId, label: value } : state.dealerId
        backupId = getBackupId(state.dealerLocations, value?.value)
        if (backupId) row.backupId = backupId
        break
      case 'recipientId':
        value = recipientId
          ? { value: recipientId, label: value }
          : state.recipientId
        backupId = getBackupId(state.recipientLocations, value?.value)
        if (backupId) row.backupId = backupId
        break
      case 'recipientAddress':
        value = parseAddress(cityMap, cities, districtMap, districts, value)
        break
      case 'elevator':
        const address = row.recipientAddress
        if (address) address.hasLift = getElevator(value)
        break
      case 'shippingId':
        value = shippingId
          ? { value: shippingId, label: value }
          : state.shippingId
        break
      case 'warehouseGroupId':
        value = warehouseGroupId
          ? { value: warehouseGroupId, label: value }
          : state.warehouseGroupId
        break
      case 'warehouseId':
        value = warehouseId
          ? { value: warehouseId, label: value }
          : state.warehouseId
        break
      case 'billingId':
        value = billingId ? { value: billingId, label: value } : null
        break
      case 'salesId':
        value = value?.split(',').reduce((result, item) => {
          const salesId = state.salesMap[item]
          if (!salesId) return result
          result.push({ value: salesId, label: item })
          return result
        }, [])
        if (!value || value.length === 0) value = state.salesId
        break
      case 'productVariantId':
        row.sku = value
        value = productOption || null
        break
      case 'transDate':
      case 'arriveDate':
      case 'expireAt':
        value = convertDate(value)
        break
      case 'arrivePeriod':
        const arrivePeriod = getArrivePeriod(value)
        value = {
          value: arrivePeriod,
          label: message({ id: `dispatch.arrivePeriod.${arrivePeriod}` }),
        }
        break
      case 'isUrgent':
      case 'isGift':
        value = value === 'Y'
        break
      case 'isDirect':
        value = !!value ? value === 'Y' : state.isDirect
        break
      case 'handleType':
        value = getHandleType(value, message)
        break
      default:
    }
    row[id] = value
  })

  return row
}

function getElevator(value) {
  if (!value) return null

  const val = value.toLowerCase()

  if (val.includes('y') || value.includes('有')) return true
  if (val.includes('n') || value.includes('無')) return false

  return null
}

function getArrivePeriod(value) {
  switch (value) {
    case 'AM':
    case '早上':
    case '上午':
      return 'AM'
    case 'PM':
    case '下午':
      return 'PM'
    default:
      return 'ALL'
  }
}

function getHandleType(value, message) {
  if (!value) return null

  if (value.includes('手機')) {
    return {
      value: 'PHONE',
      label: message({ id: 'dispatch.handleType.PHONE' }),
    }
  }

  if (value.includes('會員')) {
    return {
      value: 'MEMBER_ID',
      label: message({ id: 'dispatch.handleType.MEMBER_ID' }),
    }
  }

  if (value.includes('自然人')) {
    return {
      value: 'CITIZEN_ID',
      label: message({ id: 'dispatch.handleType.CITIZEN_ID' }),
    }
  }
  return null
}

function getComboItems(row, comboCodeMap) {
  if (!comboCodeMap) return null
  if (!row.dealerId?.value) return null

  const products = comboCodeMap[`${row.dealerId.value}::${row.sku}`]
  if (!products) return null

  return products.reduce((result, item) => {
    const newRow = cloneDeep(row)
    const quantity = parseInt(row.quantity)
    newRow.productVariantId = {
      value: item.productVariantId,
      label: item.productVariantName,
    }
    newRow.quantity = quantity * parseInt(item.quantity)
    newRow.price = quantity * (item.price || row.price || 0)
    newRow.isGift = item.isGift || row.isGift
    newRow.warehouseId = item.warehouseId
      ? {
          value: item.warehouseId,
          label: item.warehouseName,
        }
      : row.warehouseId
    newRow.batchNo = item.batchNo || row.batchNo
    newRow.expireAt = item.expireAt || row.expireAt
    result.push(newRow)
    return result
  }, [])
}
