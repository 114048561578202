import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { ALERT_ADD, CONFIRM_SET } from 'constants/actionType'
import {
  initializeState,
  getSelectOption,
  getMultiSelectOption,
  getSelectOptions,
  getDate,
  validateForm,
  showDate,
  renderDefinition,
  renderDateInput,
  renderTextInput,
  renderPhoneInput,
  renderTextAreaInput,
  renderSelectInput,
  renderAddressInput,
  renderTable,
  renderImageDropzone,
  renderCustomerLookup,
  renderSwitchInput,
} from 'utilities/form'
import { request } from 'utilities/request'
import { printHtml } from 'utilities/print'
import { getRecipients, handleDelete } from 'actions/ticket'
import { getLocation, parseRecipient, fetchLocations } from 'actions/location'
import { Button, StatusChip, Link, Icon, Center } from 'components/core'
import { MdCheck, MdDelete, MdEdit, MdEmail } from 'react-icons/md'
import { filterLocations, hasAccess } from 'utilities/permission'
import { getStatusColor } from 'constants/status'
import { getDiff } from 'utilities/list'
import { updateImages } from 'actions/image'
import { renderNumberInput } from 'utilities/form'
import {
  checkAddressExists,
  checkPhoneExists,
  getCustomers,
} from 'actions/customer'

const moduleName = 'defer'
const url = process.env.REACT_APP_STATIC_URL

export const initialState = (value = {}, message) => {
  const recipients = getRecipients(value.recipients, message)
  const sales = getSalesOptions(
    value.id,
    value.sales,
    value.extra?.salesId,
    value.extra?.salesName,
  )
  const backups = getSalesOptions(
    value.id,
    value.sales,
    value.extra?.backupId,
    value.extra?.backupName,
  )
  const { ticketItems, payments, reverts } = groupTicketItems(
    value.ticketItems,
    value.profile,
  )
  const oldTicketItems = cloneDeep(ticketItems)

  return {
    isReady: value.isReady || false,
    id: value.id || '',
    locked: value.locked || false,
    itemLocked: value.itemLocked || false,
    status: getStatus(value.status, value.extra),
    hash: value.hash,
    createdBy: value.createdBy,
    createdAt: value.createdAt,
    updatedBy: value.updatedBy,
    updatedAt: value.updatedAt,
    oldTicketItems: oldTicketItems || [],
    ticketItems: ticketItems,
    dispatchTickets: value.dispatchTickets,
    refundTickets: value.refundTickets,
    dealers: value.dealers,
    dealerCode: value.extra?.dealerCode || '',
    recipients,
    billings: value.billings,
    sales,
    salesName: value.extra?.salesName || [],
    backupName: value.extra?.backupName || [],
    products: value.products || [],
    productOptions: getSelectOptions(value.products),
    payments: payments || [],
    reverts: reverts || [],
    customerId: value.extra?.customerId,
    images: value.extra?.images || [],
    imagesToAdd: [],
    imagesToDel: [],
    source: value.extra?.source,
    pdfUrl: value.pdfUrl,
    ...initializeState({
      transDate: getDate(value.transDate),
      ticketNo: value.ticketNo || '',
      dealerId: getSelectOption(
        value.dealers,
        value.toLocationId,
        'id',
        'name',
      ),
      dealerName: value.toLocationName || '',
      recipientId: getSelectOption(
        recipients,
        value.extra?.recipientId,
        'id',
        'name',
      ),
      recipientName: value.extra?.recipientName || '',
      recipientContact: value.extra?.recipientContact || '',
      recipientAddress: value.extra?.recipientAddress || {},
      recipientPhone: value.extra?.recipientPhone || '',
      recipientCellphone: value.extra?.recipientCellphone || '',
      recipientEmail: value.extra?.recipientEmail || '',
      billingId: getSelectOption(value.billings, value.extra?.billingId),
      billingName: value.extra?.billingName || '',
      salesId: getMultiSelectOption(sales, value.extra?.salesId),
      backupId: getMultiSelectOption(backups, value.extra?.backupId),
      memo: value.extra?.memo || '',
      contractFromDate: value.extra?.contractFromDate || '',
      contractToDate: value.extra?.contractToDate || '',
      contractSignDate: value.extra?.contractSignDate || '',
      contractAmount: value.extra?.contractAmount || 0,
      issueReceipt: value.extra?.issueReceipt || false,
    }),
  }
}

export const getStatus = (status, extra) => {
  if (!status) return 'PENDING'
  if (extra.isRevert) return 'REVERTED'

  return status
}

function getSalesOptions(id, sales, salesId, salesName) {
  if (!id) return getSelectOptions(sales)

  const salesToAdd = []

  salesId.forEach((id, index) => {
    const idx = sales.findIndex((i) => i.id === id)
    if (idx === -1) {
      salesToAdd.push({ id, name: salesName[index] })
    }
  })

  sales.unshift(...salesToAdd)
  return getSelectOptions(sales)
}

const validation = (state) => {
  const fields = {
    ticketNo: [{ type: 'required', message: 'error.required' }],
    recipientId: [{ type: 'required', message: 'error.required' }],
    recipientContact: [{ type: 'required', message: 'error.required' }],
    recipientAddress: [
      { type: 'required', message: 'error.required' },
      { type: 'address', message: 'error.invalidAddress' },
    ],
    backupId: [{ type: 'required', message: 'error.required' }],
    salesId: [{ type: 'required', message: 'error.required' }],
  }

  if (!state.id) {
    fields.dealerId = [{ type: 'required', message: 'error.required' }]
  }

  return fields
}

const crossValidation = [
  {
    fields: ['recipientPhone', 'recipientCellphone'],
    type: 'required',
    message: 'dispatch.error.phoneRequired',
  },
]

export const fields = ({
  profile,
  format,
  app,
  session,
  state,
  setState,
  message,
  action,
}) => {
  const commonProps = {
    profile,
    format,
    state,
    setState,
    validation: validation(state),
    moduleName,
  }
  const definition = (inputProps) =>
    renderDefinition({ ...commonProps, ...inputProps })
  const dateInput = (inputProps) =>
    renderDateInput({ ...commonProps, ...inputProps })
  const textInput = (inputProps) =>
    renderTextInput({ ...commonProps, ...inputProps })
  const phoneInput = (inputProps) =>
    renderPhoneInput({ ...commonProps, ...inputProps })
  const textAreaInput = (inputProps) =>
    renderTextAreaInput({ ...commonProps, ...inputProps })
  const numberInput = (inputProps) =>
    renderNumberInput({ ...commonProps, ...inputProps })
  const selectInput = (inputProps) =>
    renderSelectInput({ ...commonProps, ...inputProps })
  const addressInput = (inputProps) =>
    renderAddressInput({ ...commonProps, ...inputProps, message })
  const switchInput = (inputProps) =>
    renderSwitchInput({ ...commonProps, ...inputProps })
  const tableInput = (inputProps) =>
    renderTable({ ...commonProps, ...inputProps })
  const customerLookup = (inputProps) =>
    renderCustomerLookup({ ...commonProps, ...inputProps })

  const isOwner = app.state.staff.staffType === 'OWNER'

  return {
    id: definition({ id: 'id', label: 'field.ticketId' }),
    transDate: dateInput({ id: 'transDate', min: '1970-01-01' }),
    ticketNo: textInput({ id: 'ticketNo' }),
    billing: selectInput({
      id: 'billingId',
      valKey: 'billingName',
      isClearable: false,
      options: state.billings,
    }),
    dealer: selectInput({
      profile: state.id && !isOwner ? 'view' : profile,
      id: 'dealerId',
      valKey: 'dealerName',
      isClearable: false,
      options: getSelectOptions(state.dealers),
      callback: ({ value }) =>
        onDealerChange(state, app, session, message, value),
    }),
    dealerCode: textInput({ id: 'dealerCode' }),
    recipient: selectInput({
      profile: state.id && !isOwner ? 'view' : profile,
      id: 'recipientId',
      valKey: 'recipientName',
      isClearable: false,
      options: getSelectOptions(state.recipients),
      callback: (item) => {
        const recipient = getLocation(state.recipients, item?.value)
        return parseRecipient(recipient, state)
      },
    }),
    recipientContact: customerLookup({
      id: 'recipientContact',
      href: state.customerId
        ? `${process.env.REACT_APP_STOCK_URL}/customer/${state.customerId}/view`
        : null,
      target: '_blank',
      callback: (value) => {
        setState({
          ...state,
          customerId: value.id || '',
          recipientContact: value.name || '',
          recipientPhone: value.phone || '',
          recipientCellphone: value.cellphone || '',
          recipientAddress: value.address || {},
        })
        return true
      },
    }),
    recipientAddress: addressInput({ id: 'recipientAddress' }),
    recipientPhone: phoneInput({ id: 'recipientPhone' }),
    recipientCellphone: phoneInput({
      id: 'recipientCellphone',
      type: 'cellphone',
    }),
    recipientEmail: textInput({ id: 'recipientEmail' }),
    sales: selectInput({
      id: 'salesId',
      valKey: 'salesName',
      isClearable: false,
      isMulti: true,
      options: state.sales,
    }),
    backup: selectInput({
      id: 'backupId',
      valKey: 'backupName',
      disabled: !hasAccess(app.state.staff, 'lockDeferTicket'),
      isClearable: false,
      isMulti: true,
      options: state.sales,
    }),
    memo: textAreaInput({ id: 'memo' }),
    contractFromDate: dateInput({
      id: 'contractFromDate',
      label: 'dealer.field.contractFromDate',
      min: '1970-01-01',
    }),
    contractToDate: dateInput({
      id: 'contractToDate',
      label: 'dealer.field.contractToDate',
      min: '1970-01-01',
    }),
    contractSignDate: dateInput({
      id: 'contractSignDate',
      label: 'dealer.field.contractSignDate',
      min: '1970-01-01',
    }),
    contractAmount: numberInput({
      id: 'contractAmount',
      label: 'dealer.field.contractAmount',
    }),
    issueReceipt: switchInput({
      id: 'issueReceipt',
      label: 'dealer.field.issueReceipt',
    }),
    product: tableInput({
      id: 'ticketItems',
      showSeq: true,
      columns: [
        {
          id: 'transDate',
          label: 'field.date',
          noWrap: true,
          render: ({ row }) => showDate(row.transDate),
        },
        {
          id: 'productVariantName',
          label: 'product.field.spu',
        },
        {
          id: 'isDemo',
          label: 'defer.field.isDemo',
          renderHtml: ({ row }) => (
            <Icon
              show={!!row.extra?.isDemo}
              color="success.1"
              value={<MdCheck />}
            />
          ),
          render: ({ row }) => (row.extra?.isDemo ? '&check;' : ''),
        },
        {
          id: 'isGift',
          label: 'defer.field.isGift',
          renderHtml: ({ row }) => (
            <Icon show={row.isGift} color="success.1" value={<MdCheck />} />
          ),
          render: ({ row }) => (row.isGift ? '&check;' : ''),
        },
        {
          id: 'isContract',
          label: 'defer.field.isContract',
          renderHtml: ({ row }) => (
            <Icon
              show={!!row.extra?.isContract}
              color="success.1"
              value={<MdCheck />}
            />
          ),
          render: ({ row }) => (row.extra?.isContract ? '&check;' : ''),
        },
        {
          id: 'quantity',
          label: 'field.quantity',
          align: 'right',
        },
        {
          id: 'balance',
          label: 'defer.field.balance',
          align: 'right',
        },
        {
          id: 'unitPrice',
          label: 'field.unitPrice',
          align: 'right',
          render: ({ row }) => row.extra?.unitPrice,
        },
        {
          id: 'price',
          label: 'field.price',
          align: 'right',
        },
        {
          id: 'memo',
          label: 'field.memo',
          profile: ['view'],
          render: ({ row }) => row.extra?.memo,
        },
        {
          id: 'status',
          label: 'field.status',
          profile: ['view'],
          renderHtml: ({ row }) => (
            <StatusChip
              label={`defer.itemStatus.${row.status || 'ACTIVE'}`}
              color={getStatusColor(row.status)}
            />
          ),
          render: ({ row }) =>
            message({ id: `defer.itemStatus.${row.status || 'ACTIVE'}` }),
        },
        {
          id: 'actions',
          noWrap: true,
          align: 'right',
          format: ['html'],
          profile: ['add', 'edit'],
          render: ({ row, index }) => (
            <Center justifyContent="flex-end">
              <Button
                disabled={state.itemLocked}
                mr={2}
                variant="icon"
                icon={<MdEdit />}
                onClick={() => action.handleOpen({ ...row, index })}
              />
              <Button
                disabled={state.itemLocked || row.quantity !== row.balance}
                icon={<MdDelete />}
                variant="icon"
                onClick={() => {
                  const ticketItems = [...state.ticketItems]
                  ticketItems.splice(index, 1)
                  setState({ ...state, ticketItems })
                }}
              />
            </Center>
          ),
        },
      ],
    }),
    images: renderImageDropzone({
      format,
      profile,
      session,
      state,
      setState,
      action,
      imagePath: `${app.state.staff.merchantId}/${state.id}`,
    }),
    payments: tableInput({
      id: 'payments',
      columns: [
        {
          id: 'transDate',
          label: 'field.date',
          noWrap: true,
          render: ({ row }) => showDate(row.transDate),
        },
        {
          id: 'paymentType',
          label: 'field.type',
          render: ({ row }) =>
            message({ id: `defer.paymentType.${row.paymentType}` }),
        },
        {
          id: 'productVariantName',
          label: 'field.productName',
        },
        {
          id: 'receiptType',
          label: 'defer.field.receiptType',
          render: ({ row }) => {
            if (!row.receiptType) return null
            return message({ id: `defer.receiptType.${row.receiptType}` })
          },
        },
        {
          id: 'receiptNo',
          label: 'defer.field.receiptNo',
        },
        {
          id: 'memo',
          label: 'field.memo',
        },
        {
          id: 'price',
          label: 'field.price',
          align: 'right',
        },
        {
          id: 'status',
          label: 'field.status',
          renderHtml: ({ row }) => (
            <StatusChip
              label={`status.${row.status}`}
              color={getStatusColor(row.status)}
            />
          ),
          render: ({ row }) => message({ id: `status.${row.status}` }),
        },
        {
          id: 'actions',
          align: 'right',
          noWrap: true,
          format: ['html'],
          render: ({ row: { id, status } }) => (
            <Button
              disabled={status === 'INACTIVE'}
              icon={<MdDelete />}
              variant="icon"
              onClick={() => {
                const item = {
                  open: true,
                  title: 'defer.title.deletePayment',
                  text: 'defer.message.deletePayment',
                  onSubmit: async () => {
                    const [ok] = await deleteReceipt({
                      session,
                      app,
                      state,
                      id,
                    })
                    if (ok) {
                      const data = await getData({
                        app,
                        session,
                        id: state.id,
                        profile,
                      })
                      setState(initialState(data, message))
                    }
                    return ok
                  },
                }
                session.dispatch({ type: CONFIRM_SET, item })
              }}
            />
          ),
        },
      ],
    }),
    reverts: tableInput({
      id: 'reverts',
      columns: [
        {
          id: 'transDate',
          label: 'field.date',
          noWrap: true,
        },
        {
          id: 'productVariantName',
          label: 'field.productName',
        },
        {
          id: 'isGift',
          label: 'defer.field.isGift',
          renderHtml: ({ row }) => (
            <Icon show={!!row.isGift} color="success.1" value={<MdCheck />} />
          ),
          render: ({ row }) => (row.isGift ? '&check;' : ''),
        },
        {
          id: 'memo',
          label: 'field.memo',
        },
        {
          id: 'quantity',
          label: 'field.quantity',
          align: 'right',
        },
        {
          id: 'status',
          label: 'field.status',
          renderHtml: ({ row }) => (
            <StatusChip
              label={`status.${row.status}`}
              color={getStatusColor(row.status)}
            />
          ),
          render: ({ row }) => message({ id: `status.${row.status}` }),
        },
        {
          id: 'actions',
          align: 'right',
          noWrap: true,
          format: ['html'],
          render: ({ row: { id, status } }) => (
            <Button
              disabled={status === 'INACTIVE'}
              icon={<MdDelete />}
              variant="icon"
              onClick={() => {
                const item = {
                  open: true,
                  title: 'defer.title.deleteRevert',
                  text: 'defer.message.deleteRevert',
                  onSubmit: async () => {
                    const [ok] = await deleteRevert({ session, app, state, id })
                    if (ok) {
                      const data = await getData({
                        app,
                        session,
                        id: state.id,
                        profile,
                      })
                      setState(initialState(data, message))
                    }
                    return ok
                  },
                }
                session.dispatch({ type: CONFIRM_SET, item })
              }}
            />
          ),
        },
      ],
    }),
    dispatchTickets: tableInput({
      id: 'dispatchTickets',
      columns: [
        {
          id: 'transDate',
          label: 'field.date',
          render: ({ row }) => showDate(row.transDate),
        },
        {
          id: 'id',
          label: 'field.ticketId',
          renderHtml: ({ row }) => (
            <Link
              variant="primaryLink"
              href={`${process.env.REACT_APP_STOCK_URL}/dispatch/${row.id}/view`}
              target="_blank"
            >
              {row.id}
            </Link>
          ),
          render: ({ row }) => row.id,
        },
        {
          id: 'status',
          label: 'field.status',
          renderHtml: ({ row }) => (
            <StatusChip
              label={`defer.status.${row.status}`}
              color={getStatusColor(row.status)}
            />
          ),
          render: ({ row }) => message({ id: `defer.status.${row.status}` }),
        },
      ],
    }),
    refundTickets: tableInput({
      id: 'refundTickets',
      columns: [
        {
          id: 'transDate',
          label: 'field.date',
          render: ({ row }) => showDate(row.transDate),
        },
        {
          id: 'id',
          label: 'field.ticketId',
          renderHtml: ({ row }) => (
            <Link
              variant="primaryLink"
              href={`${process.env.REACT_APP_STOCK_URL}/refund/${row.id}/view`}
              target="_blank"
            >
              {row.id}
            </Link>
          ),
          render: ({ row }) => row.id,
        },
        {
          id: 'status',
          label: 'field.status',
          renderHtml: ({ row }) => (
            <StatusChip
              label={`refund.status.${row.status}`}
              color={getStatusColor(row.status)}
            />
          ),
          render: ({ row }) => message({ id: `status.${row.status}` }),
        },
      ],
    }),
  }
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  message,
  id,
  profile,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, id, profile })
    setState(initialState(data, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    const isValid = validateForm({
      session,
      state,
      setState,
      validation: validation(state),
      crossValidation,
    })
    if (!isValid) return

    const [ok, data] = id
      ? await editDefer(state, app, session, history)
      : await addDefer(state, app, session, history)
    if (!ok) return

    if (!id) id = data.addDeferTicket

    const imageOk = await updateImages(app, session, state, id)
    if (!imageOk) {
      if (!id) history.push(`/defer/${id}/edit`)
      return
    }

    history.push(`/defer/${id}/view`)
  },
  addItem: (value) => {
    const ticketItems = cloneDeep(state.ticketItems)
    const { index } = value
    if (index === -1) {
      ticketItems.push(value)
    } else {
      ticketItems.splice(index, 1, value)
    }
    setState({ ...state, ticketItems })
    return true
  },
  handleItemLockToggle: async () => {
    const itemLocked = !state.itemLocked
    const [ok] = await toggleItemLock(state, app, session)
    if (!ok) return

    setState({ ...state, itemLocked })
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'defer.success.itemLocked' },
    })
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('defer', { session, app, id, hash })
    if (!ok) return false

    history.push('/defer')
    return true
  },
  handlePrint: () => {
    const { title, header, content } = getPrintData({ app, state, message })
    printHtml({ title, header, content, message })
  },
  handleEmailSend: async (event) => {
    event.preventDefault()

    const [ok] = await sendDeferEmail(state, app, session)
    if (!ok) return false

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'defer.sendEmail.success' },
    })
    return true
  },
})

export function getPrintData({ app, state, message }) {
  const title = { id: 'defer.title.print', values: { ticketId: state.id } }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const form = fields({ format: 'print', app, state, message })
  const hidden = [
    'ticketItems',
    'payments',
    'reverts',
    'dispatchTickets',
    'refundTickets',
    'images',
  ]
  const field = Object.values(form).filter(({ id }) => !hidden.includes(id))
  const rows = form.product.rows.filter(({ status }) => status === 'ACTIVE')
  const ticketItems = { columns: form.product.columns, rows }
  const invoicedAmount = getPaymentInvoiced(state)
  const revertedAmount = getPaymentReverted(state)
  const totalAmount = getTotal(state)
  const content = [
    { type: 'field', display: 'inline', value: field },
    { type: 'subTitle', value: 'defer.section.product' },
    { type: 'list', value: ticketItems },
    {
      type: 'text',
      value: `${message({
        id: 'defer.field.orderAmount',
      })}: ${invoicedAmount}`,
      className: 'right',
    },
    {
      type: 'text',
      value: `${message({
        id: 'defer.field.revertAmount',
      })}: ${revertedAmount}`,
      className: 'right',
    },
    {
      type: 'text',
      value: `${message({ id: 'field.total' })}: ${totalAmount}`,
      className: 'right',
    },
    { type: 'subTitle', value: 'defer.section.payment' },
    { type: 'list', value: form.payments },
    { type: 'subTitle', value: 'defer.section.revert' },
    { type: 'list', value: form.reverts },
    { type: 'subTitle', value: 'defer.section.dispatch' },
    { type: 'list', value: form.dispatchTickets },
    { type: 'subTitle', value: 'defer.section.refund' },
    { type: 'list', value: form.refundTickets },
  ]
  return { title, header, content }
}

async function getData({ app, session, id, profile }) {
  const staffInput = { type: ['SALES'] }
  const locationInput = { type: ['DEALER', 'COMPANY'] }
  const dispatchInput = { parentId: id }
  const refundInput = { parentId: id }
  const variables = {
    id,
    locationInput,
    staffInput,
    dispatchInput,
    refundInput,
  }
  const dispatchQuery = `
    dispatchTickets(input: $dispatchInput) {
      id
      transDate
      status
    }
  `
  const refundQuery = `
    refundTickets(input: $refundInput) {
      id
      transDate
      status
    }
  `
  const query = `
    query($id: ID, $locationInput: LocationQueryInput, $dispatchInput: TicketQueryInput!, $refundInput: TicketQueryInput!, $staffInput: StaffQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
        extra
      }
      staffs(input: $staffInput) {
        id
        name
      }
      productVariants {
        id
        name
        sku
        barcode
      }
      deferTicket(id: $id) {
        ticketNo
        toLocationId
        toLocationName
        transDate
        extra
        status
        hash
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      deferTicketItems(ticketId: $id) {
        id
        transDate
        productVariantId
        productVariantName
        quantity
        balance
        price
        isGift
        extra
        status
      }
      ${id ? dispatchQuery : ''}
      ${id ? refundQuery : ''}
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { staffs, productVariants } = data
  const locations = filterLocations(app.state.staff, data.locations)
  const ticket = data.deferTicket || {}
  const extra = ticket.extra || {}
  const dealers = []
  const billings = []
  let recipients = []

  locations.forEach((item) => {
    const { type, extra } = item
    const result = { label: item.name, value: item.id }
    switch (type) {
      case 'DEALER':
        dealers.push(item)
        break
      case 'COMPANY':
        if (extra.type === 'BILLING') billings.push(result)
        break
      default:
        break
    }
  })

  if (profile === 'edit' && ticket.toLocationId) {
    const locationInput = { parentId: ticket.toLocationId, type: ['STORAGE'] }
    recipients = await fetchLocations(app, session, locationInput)
  }

  let pdfUrl = null

  if (extra.source === 'erp-invoice') {
    pdfUrl = `${url}/${app.state.staff.merchantId}/${ticket.ticketNo}.pdf`
  }

  return {
    isReady: profile === 'edit',
    id,
    extra,
    createdBy: ticket.createdBy,
    createdAt: ticket.createdAt,
    updatedBy: ticket.updatedBy,
    updatedAt: ticket.updatedAt,
    locked: ticket.extra?.locked,
    itemLocked: ticket.extra?.itemLocked,
    transDate: ticket.transDate,
    ticketNo: ticket.ticketNo,
    toLocationId: ticket.toLocationId,
    toLocationName: ticket.toLocationName,
    // dealerCode: extra.dealerCode,
    // recipientId: extra.recipientId,
    // recipientName: extra.recipientName,
    // recipientContact: extra.recipientContact,
    // recipientAddress: extra.recipientAddress,
    // recipientPhone: extra.recipientPhone,
    // recipientCellphone: extra.recipientCellphone,
    // billingId: extra.billingId,
    // billingName: extra.billingName,
    // salesId: extra.salesId,
    // salesName: extra.salesName,
    // backupId: extra.backupId,
    // backupName: extra.backupName,
    // memo: extra.memo,
    // contractFromDate: extra.contractFromDate,
    // contractToDate: extra.contractToDate,
    // contractSignDate: extra.contractSignDate,
    // customerId: extra.customerId,
    // images: extra.images,
    status: ticket.status,
    hash: ticket.hash,
    ticketItems: data.deferTicketItems,
    dispatchTickets: data.dispatchTickets,
    refundTickets: data.refundTickets,
    dealers,
    recipients,
    billings,
    sales: staffs,
    products: productVariants,
    pdfUrl,
  }
}

function groupTicketItems(deferTicketItems, profile) {
  const ticketItems = []
  const payments = []
  const reverts = []

  if (!deferTicketItems) return { ticketItems, payments, reverts }

  deferTicketItems.forEach((item) => {
    if (item.extra?.isPayment) {
      payments.push({
        id: item.id,
        parentItemId: item.extra.parentItemId,
        transDate: showDate(item.transDate),
        paymentType: item.extra.paymentType,
        productVariantId: item.productVariantId,
        productVariantName: item.productVariantName,
        receiptType: item.extra.receiptType,
        receiptNo: item.extra.receiptNo,
        memo: item.extra.memo,
        price: item.price,
        status: item.status,
      })
    } else if (item.extra?.isRevert) {
      reverts.push({
        id: item.id,
        parentItemId: item.extra.parentItemId,
        transDate: showDate(item.transDate),
        productVariantId: item.productVariantId,
        productVariantName: item.productVariantName,
        memo: item.extra.memo,
        isGift: item.isGift,
        quantity: item.quantity,
        status: item.status,
      })
    } else if (item.status !== 'INACTIVE' || profile !== 'edit') {
      ticketItems.push(item)
    }
  })

  return { ticketItems, payments, reverts }
}

async function onDealerChange(state, app, session, message, dealerId) {
  const { dealers, billings } = state
  const ticketItems = [...state.ticketItems]
  const dealer = getLocation(dealers, dealerId)
  const extra = dealer.extra || {}
  const locationInput = { parentId: dealerId, type: ['STORAGE'] }
  const recipients = await fetchLocations(app, session, locationInput)

  const oldBackupId = state.backupId || []
  const backupId = extra.salesId?.map((id, index) => ({
    value: id,
    label: extra.salesName[index],
  }))

  return {
    dealerCode: dealer?.extra.code || '',
    backupId: backupId || oldBackupId,
    billingId: getSelectOption(billings, dealer?.extra.billingId),
    recipients: getRecipients(recipients, message),
    ticketItems,
    contractFromDate: extra.contractFromDate || '',
    contractToDate: extra.contractToDate || '',
    contractSignDate: extra.contractSignDate || '',
    contractAmount: extra.contractAmount || 0,
    issueReceipt: extra.issueReceipt || false,
  }
}

async function addDefer(state, app, session, history) {
  const isIndividual = state.recipientId?.value === '__INDIVIDUAL__'
  const customers = await getCustomers(state, app, session)
  const dups = customers.filter(
    (item) => item && item.name !== state.recipientContact,
  )

  if (isIndividual && !state.customerId && dups && dups.length > 0) {
    const dup = dups[0]
    session.dispatch({
      type: CONFIRM_SET,
      item: {
        open: true,
        title: 'customer.title.addDuplicate',
        text: {
          id: 'customer.message.addDuplicate',
          values: { name: dup.name, newName: state.recipientContact },
        },
        onSubmit: async () => {
          const [ok, data] = await addDeferTicket(state, app, session)
          if (!ok) return false

          const id = data.addDeferTicket
          const imageOk = await updateImages(app, session, state, id)
          if (!imageOk) return false

          history.push(`/defer/${id}/view`)
          return true
        },
      },
    })
    return [false]
  }

  return addDeferTicket(state, app, session)
}

async function addDeferTicket(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addDeferTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editDefer(state, app, session, history) {
  const isIndividual = state.recipientId?.value === '__INDIVIDUAL__'
  const { recipientPhone, recipientCellphone, recipientAddress } = state
  const [customer = {}] = await getCustomers(state, app, session)
  const hasPhone = checkPhoneExists(customer.phones, recipientPhone)
  const hasCellphone = checkPhoneExists(customer.cellphones, recipientCellphone)
  const hasAddress = checkAddressExists(customer.addresses, recipientAddress)

  if (isIndividual && (!hasPhone || !hasCellphone || !hasAddress)) {
    session.dispatch({
      type: CONFIRM_SET,
      item: {
        open: true,
        title: 'customer.title.metaNotFound',
        text: 'customer.message.metaNotFound',
        onSubmit: async () => {
          const { id } = state
          const [ok] = await editDeferTicket(state, app, session)
          if (!ok) return false

          const imageOk = await updateImages(app, session, state, id)
          if (!imageOk) {
            if (!id) history.push(`/defer/${id}/edit`)
            return false
          }

          history.push(`/defer/${id}/view`)
          return true
        },
      },
    })
    return [false]
  }

  return editDeferTicket(state, app, session)
}

async function editDeferTicket(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editDeferTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function sendDeferEmail(state, app, session) {
  const variables = { id: state.id }
  const query = `
    mutation($id: ID!) {
      sendDeferTicketEmail(id: $id)
    }
  `
  return request({ query, variables }, { session, app })
}

async function toggleItemLock(state, app, session) {
  const variables = { id: state.id }
  const query = `
    mutation($id: ID!) {
      lockDeferTicketItem(id: $id)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { id, hash, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    transDate: item.transDate,
    productVariantId: item.productVariantId,
    quantity: parseInt(item.quantity),
    price: parseFloat(item.price),
    isGift: item.isGift,
    extra: item.extra,
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.quantity !== newItem.quantity) return false
    if (item.price !== newItem.price) return false
    if (item.isGift !== newItem.isGift) return false
    if (item.transDate !== newItem.transDate) return false
    if (!isEqual(item.extra, newItem.extra)) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)
  const backupId = state.backupId || []
  const images = state.images.map((item) => item.name || item)

  return {
    id,
    hash,
    ticketNo: state.ticketNo,
    transDate: state.transDate,
    toLocationId: state.dealerId.value,
    extra: {
      customerId: state.customerId,
      recipientId: state.recipientId.value,
      recipientName: state.recipientId.label,
      recipientContact: state.recipientContact,
      recipientAddress: state.recipientAddress,
      recipientPhone: state.recipientPhone,
      recipientCellphone: state.recipientCellphone,
      recipientEmail: state.recipientEmail,
      dealerCode: state.dealerCode,
      billingId: state.billingId.value,
      billingName: state.billingId.label,
      salesId: state.salesId.map((item) => item.value),
      salesName: state.salesId.map((item) => item.label),
      backupId: backupId.map((item) => item.value),
      backupName: backupId.map((item) => item.label),
      memo: state.memo,
      contractFromDate: state.contractFromDate,
      contractToDate: state.contractToDate,
      contractSignDate: state.contractSignDate,
      contractAmount: state.contractAmount,
      issueReceipt: state.issueReceipt,
      images,
      // payments: state.payments,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

async function deleteReceipt({ session, app, state, id }) {
  const payments = { itemsToDel: [id] }
  const input = { hash: state.hash, extra: { payments } }
  const variables = { id: state.id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editDeferTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function deleteRevert({ session, app, state, id }) {
  const reverts = { itemsToDel: [id] }
  const input = { hash: state.hash, extra: { reverts } }
  const variables = { id: state.id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editDeferTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

export function getPaymentPaid(state) {
  const { payments = [] } = state
  const total = payments.reduce((result, item) => {
    if (item.status === 'INACTIVE') return result
    result += parseFloat(item.price || 0)
    return result
  }, 0)
  return Math.round(total * 100) / 100
}

export function getPaymentInvoiced(state) {
  const { ticketItems = [] } = state
  const total = ticketItems.reduce((result, item) => {
    if (item.status === 'INACTIVE') return result
    result += parseFloat(item.price || 0)
    return result
  }, 0)
  return Math.round(total * 100) / 100
}

export function getPaymentReverted(state) {
  const { payments = [] } = state
  const total = payments.reduce((result, item) => {
    if (item.status === 'INACTIVE') return result
    if (item.paymentType === 'REVERT') {
      result += parseFloat(item.price || 0)
    }
    return result
  }, 0)
  return Math.round(total * 100) / 100
}

export function getPaymentBalance(state) {
  return getTotal(state) - getPaymentPaid(state)
}

export function getTotal(state) {
  return getPaymentInvoiced(state) + getPaymentReverted(state)
}

export const renderSendLink = ({
  readonly = false,
  status = 'PENDING',
  email,
  session,
  onSubmit,
}) => {
  return (
    <Link
      ml={3}
      show={readonly}
      disabled={status === 'INACTIVE' || !email}
      variant="link"
      icon={<MdEmail />}
      tooltip="defer.btn.sendEmail"
      onClick={(event) => {
        const item = {
          open: true,
          title: 'defer.title.sendEmail',
          text: { id: 'defer.message.sendEmail', values: { email } },
          onSubmit: () => onSubmit(event),
        }
        session.dispatch({ type: CONFIRM_SET, item })
      }}
    />
  )
}
