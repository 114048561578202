import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { ALERT_ADD } from 'constants/actionType'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  getSelectOption,
  validateForm,
  showDate,
  renderCustomerLookup,
  showAddress,
  renderAddressInput,
} from 'utilities/form'
import { request } from 'utilities/request'
import { getDiff } from 'utilities/list'
import { printHtml } from 'utilities/print'
import { getLocation } from 'actions/location'
import { fetchBalances } from 'actions/inventory'
import { handleDelete, setBalances } from 'actions/ticket'
import { Definition, Button, Table, Text, Center } from 'components/core'
import {
  PhoneInput,
  TextArea,
  Select,
  DateInput,
} from 'components/form'
import { MdDelete, MdEdit } from 'react-icons/md'
import { filterLocations } from 'utilities/permission'
import { Flex } from 'reflexbox'

const moduleName = 'move'

export const initialState = (value = {}, staff, message) => {
  const isConfirmed = value.extra?.isConfirmed || false
  let status = value.status || 'PENDING'
  if (status === 'PENDING' && isConfirmed) status = 'ACTION_PENDING'

  return {
    isReady: value.isReady || false,
    id: value.id || '',
    sync: value.sync || false,
    locked: value.locked || false,
    fromLocationName: value.fromLocationName || '',
    toLocationName: value.toLocationName || '',
    status,
    hash: value.hash,
    createdBy: value.createdBy,
    createdAt: value.createdAt,
    updatedBy: value.updatedBy,
    updatedAt: value.updatedAt,
    oldTicketItems: value.oldTicketItems || [],
    ticketItems: value.ticketItems || [],
    locations: value.locations || [],
    products: value.products || [],
    ...initializeState({
      type: getMoveType(staff, value.extra?.type, message),
      transDate: showDate(value.transDate),
      arriveDate: showDate(value.extra?.arriveDate),
      fromLocationId: getSelectOption(
        value.locations,
        value.fromLocationId,
        'id',
        'name',
      ),
      toLocationId: getSelectOption(
        value.locations,
        value.toLocationId,
        'id',
        'name',
      ),
      address: value.extra?.address || '',
      phone: value.extra?.phone || '',
      contact: value.extra?.contact || '',
      memo: value.extra?.memo || '',
    }),
  }
}

function getFromLocationOptions(staff, type, locations) {
  if (!locations || !type) return []

  const moveType = type === 'org' ? 'DEALER_ORG' : type.toUpperCase()
  const allowedLocations = filterLocations(staff, locations)
  const result = []
  allowedLocations.forEach(({ type, id, name }) => {
    if (type === moveType) result.push({ label: name, value: id })
  })
  return result
}

function getToLocationOptions(type, locationId, locations) {
  if (!locationId || !locations) return []

  const moveType = type === 'org' ? 'DEALER_ORG' : type.toUpperCase()
  const toLocations = locations.filter(({ type }) => type === moveType)
  const fromLocation = getLocation(locations, locationId)
  if (['WAREHOUSE', 'DEPT'].includes(fromLocation.type)) {
    const result = []
    toLocations.forEach(({ type, id, name }) => {
      if (['WAREHOUSE', 'DEPT'].includes(type) && id !== locationId) {
        result.push({ label: name, value: id })
      }
    })
    return result
  }

  const result = []
  toLocations.forEach(({ parentId, id, name }) => {
    if (parentId === fromLocation.parentId && id !== locationId) {
      result.push({ label: name, value: id })
    }
  })
  return result
}

const validation = {
  transDate: [{ type: 'required', message: 'error.required' }],
  fromLocationId: [{ type: 'required', message: 'error.required' }],
  toLocationId: [{ type: 'required', message: 'error.required' }],
  ticketItems: [{ type: 'required', message: 'error.required' }],
}

const defs = (state, message) => ({
  id: state.id,
  transDate: state.transDate,
  arriveDate: state.arriveDate,
  type: message({ id: `move.type.${state.type.value}` }),
  fromLocation: state.fromLocationName,
  toLocation: state.toLocationName,
  address: showAddress(state.address),
  phone: state.phone,
  contact: state.contact,
  memo: state.memo,
})

const columns = [
  {
    id: 'productVariantName',
    label: 'move.field.spu',
  },
  {
    id: 'batchNo',
    label: 'purchase.field.batchNo',
    renderHtml: ({ row }) => (
      <Flex flexDirection="column">
        {row.extra?.batchItems?.map(({ batchNo }, index) =>
          renderBatchItem(batchNo, index),
        )}
      </Flex>
    ),
    render: ({ row }) =>
      row.extra?.batchItems
        ?.map(({ batchNo }) => batchNo || '\u2014')
        .join('<br/>'),
  },
  {
    id: 'expireAt',
    label: 'purchase.field.expireAt',
    noWrap: true,
    renderHtml: ({ row }) => (
      <Flex flexDirection="column">
        {row.extra?.batchItems?.map(({ expireAt }, index) =>
          renderBatchItem(showDate(expireAt), index),
        )}
      </Flex>
    ),
    render: ({ row }) =>
      row.extra?.batchItems
        ?.map(({ expireAt }) => showDate(expireAt) || '\u2014')
        .join('<br/>'),
  },
  {
    id: 'lockQuantity',
    label: 'purchase.field.lockQuantity',
    align: 'right',
    renderHtml: ({ row }) => (
      <Flex flexDirection="column" justifyContent="flex-end">
        {row.extra?.batchItems?.map(({ quantity }, index) =>
          renderBatchItem(quantity, index),
        )}
      </Flex>
    ),
    render: ({ row }) =>
      row.extra?.batchItems?.map(({ quantity }) => quantity).join('<br/>'),
  },
  {
    id: 'quantity',
    label: 'move.field.quantity',
    align: 'right',
  },
]

export const labels = ({ state, message }) => {
  const fields = Object.entries(defs(state, message))
  const content = fields.reduce((result, [id, value]) => {
    const label = `move.field.${id}`
    result[id] = <Definition label={label} value={value} />
    return result
  }, {})

  content.product = <Table showSeq columns={columns} rows={state.ticketItems} />
  return content
}

function getMoveType(staff, value, message) {
  if (!value) {
    const types = getMoveTypes(staff, message)
    return types[0] || {}
  }
  return { value, label: message({ id: `move.type.${value}` }) }
}

export function getMoveTypes(staff, message) {
  if (!staff) return []

  const { staffType, permissions } = staff
  const isOwner = staffType === 'OWNER'
  const types = []
  if (isOwner || permissions.includes('moveWarehouse')) {
    types.push({
      value: 'warehouse',
      label: message({ id: 'move.type.warehouse' }),
    })
  }
  if (isOwner || permissions.includes('moveDept')) {
    types.push({ value: 'dept', label: message({ id: 'move.type.dept' }) })
  }
  if (isOwner || permissions.includes('moveOrg')) {
    types.push({ value: 'org', label: message({ id: 'move.type.org' }) })
  }
  if (isOwner || permissions.includes('moveDealer')) {
    types.push({ value: 'dealer', label: message({ id: 'move.type.dealer' }) })
  }
  return types
}

export const fields = ({
  profile,
  format,
  app,
  session,
  state,
  setState,
  message,
  action,
}) => {
  const commonProps = {
    profile,
    format,
    state,
    setState,
    validation,
    moduleName,
  }
  const customerLookup = (inputProps) =>
    renderCustomerLookup({ ...commonProps, ...inputProps })
  const addressInput = (inputProps) =>
    renderAddressInput({ ...commonProps, ...inputProps, message })

  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    id: (
      <Definition show={!!state.id} label="field.ticketId" value={state.id} />
    ),
    type: (
      <Select
        label="move.field.type"
        isSearchable={false}
        isClearable={false}
        options={getMoveTypes(app.state.staff, message)}
        value={state.type}
        onChange={onSelectChange('type', () => {
          return { fromLocationId: [], toLocationId: [] }
        })}
      />
    ),
    transDate: (
      <DateInput
        id="transDate"
        label="move.field.transDate"
        placeholder="move.field.transDate"
        value={state.transDate}
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    arriveDate: (
      <DateInput
        id="arriveDate"
        label="move.field.arriveDate"
        placeholder="move.field.arriveDate"
        value={state.arriveDate}
        onChange={onTextChange('arriveDate')}
        errMsg={state.__error__.arriveDate}
      />
    ),
    fromLocation: (
      <Select
        label="move.field.fromLocation"
        isClearable={false}
        options={getFromLocationOptions(
          app.state.staff,
          state.type?.value,
          state.locations,
        )}
        value={state.fromLocationId}
        onChange={onSelectChange('fromLocationId', ({ value }) =>
          onFromLocationChange(state, app, session, value),
        )}
        errMsg={state.__error__.fromLocationId}
      />
    ),
    toLocation: (
      <Select
        label="move.field.toLocation"
        isClearable={false}
        options={getToLocationOptions(
          state.type?.value,
          state.fromLocationId.value,
          state.locations,
        )}
        value={state.toLocationId}
        onChange={onSelectChange('toLocationId')}
        errMsg={state.__error__.toLocationId}
      />
    ),
    contact: customerLookup({
      id: 'contact',
      callback: (value) => {
        setState({
          ...state,
          contact: value.name || '',
          phone: value.phone || '',
          address: value.address || {},
        })
        return true
      },
    }),
    address: addressInput({ id: 'address' }),
    phone: (
      <PhoneInput
        id="phone"
        label="move.field.phone"
        value={state.phone}
        onChange={onTextChange('phone')}
        errMsg={state.__error__.phone}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="move.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    product: (
      <Table
        showSeq
        columns={[
          {
            id: 'productVariantName',
            label: 'move.field.spu',
          },
          {
            id: 'batchNo',
            label: 'purchase.field.batchNo',
            render: ({ row }) => (
              <Flex flexDirection="column">
                {row.extra?.batchItems?.map(({ batchNo }, index) =>
                  renderBatchItem(batchNo, index),
                )}
              </Flex>
            ),
          },
          {
            id: 'expireAt',
            label: 'purchase.field.expireAt',
            noWrap: true,
            render: ({ row }) => (
              <Flex flexDirection="column">
                {row.extra?.batchItems?.map(({ expireAt }, index) =>
                  renderBatchItem(showDate(expireAt), index),
                )}
              </Flex>
            ),
          },
          {
            id: 'lockQuantity',
            label: 'purchase.field.lockQuantity',
            align: 'right',
            render: ({ row }) => (
              <Flex flexDirection="column" justifyContent="flex-end">
                {row.extra?.batchItems?.map(({ quantity }, index) =>
                  renderBatchItem(quantity, index),
                )}
              </Flex>
            ),
          },
          {
            id: 'balance',
            label: 'move.field.balance',
            align: 'right',
          },
          {
            id: 'quantity',
            label: 'move.field.quantity',
            align: 'right',
          },
          {
            id: 'actions',
            align: 'right',
            noWrap: true,
            render: ({ row, index }) => (
              <Center justifyContent="flex-end">
                <Button
                  mr={1}
                  variant="icon"
                  icon={<MdEdit />}
                  onClick={() => action.handleOpen({ ...row, index })}
                />
                <Button
                  icon={<MdDelete />}
                  variant="icon"
                  onClick={() => {
                    const ticketItems = [...state.ticketItems]
                    ticketItems.splice(index, 1)
                    setState({ ...state, ticketItems })
                  }}
                />
              </Center>
            ),
          },
        ]}
        rows={state.ticketItems}
      />
    ),
  }
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  message,
  history,
  id,
  profile,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, id, profile })
    setState(initialState(data, app.state.staff, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editMove(state, app, session)
      : await addMove(state, app, session)
    if (!ok) return

    if (!id) {
      id = data.addMoveTicket
    }

    history.push(`/move/${id}/view`)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  addItem: (value) => {
    const ticketItems = [...state.ticketItems]
    const { index } = value
    if (index === -1) {
      ticketItems.push(value)
    } else {
      ticketItems.splice(index, 1, value)
    }
    setState({ ...state, ticketItems })
  },
  handleConfirm: (status) => async () => {
    const { hash } = state
    const [ok] = await confirmMove({ session, app, id, hash, status })
    if (!ok) return false

    const resp = await getData({ app, session, id })
    setState(initialState(resp, app.state.staff, message))
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'submit.success' },
    })
    return true
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('move', { session, app, id, hash })
    if (!ok) return false

    history.push('/move')
    return true
  },
  handlePrint: () => {
    const { title, header, content } = getPrintData({ state, message })
    printHtml({ title, header, content, message })
  },
})

export function getPrintData({ state, message }) {
  const title = { id: 'move.title.print', values: { ticketId: state.id } }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const field = Object.entries(defs(state, message)).map(([id, value]) => ({
    label: `move.field.${id}`,
    value,
  }))
  const list = { columns, rows: state.ticketItems }
  const content = [
    { type: 'field', value: field },
    { type: 'list', value: list },
  ]
  return { title, header, content }
}

async function getData({ app, session, id, profile }) {
  const { merchantId } = app.state.staff
  const locationInput = { type: ['WAREHOUSE', 'DEPT', 'DEALER_ORG', 'DEALER'] }
  const variables = { id, locationInput, merchantId, vendor: 'sdj' }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput, $merchantId: ID!, $vendor: String!) {
      plugin(merchantId: $merchantId, vendor: $vendor) {
        isActive
      }
      locations(input: $locationInput) {
        id
        parentId
        name
        type
      }
      productVariants {
        id
        name
        sku
      }
      moveTicket(id: $id) {
        fromLocationId
        fromLocationName
        toLocationId
        toLocationName
        transDate
        extra
        status
        hash
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      moveTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        quantity
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { plugin, productVariants } = data
  const ticket = data.moveTicket || {}
  const ticketItems = data.moveTicketItems || []
  const oldTicketItems = cloneDeep(ticketItems)
  const extra = ticket.extra || {}

  if (profile === 'edit' && ticketItems.length > 0) {
    const { fromLocationId, fromLocationType } = ticket
    const balances = await fetchBalances({
      app,
      session,
      locationType: fromLocationType,
      locationId: fromLocationId,
      productVariantId: ticketItems.map((item) => item.productVariantId),
      oldTicketItems,
    })
    setBalances(ticketItems, balances)
  }

  return {
    isReady: profile === 'edit',
    id,
    extra,
    createdBy: ticket.createdBy,
    createdAt: ticket.createdAt,
    updatedBy: ticket.updatedBy,
    updatedAt: ticket.updatedAt,
    sync: extra.sync === undefined ? plugin.isActive : extra.sync,
    locked: ticket.extra?.locked,
    // type: extra.type,
    fromLocationId: ticket.fromLocationId,
    fromLocationName: ticket.fromLocationName,
    toLocationId: ticket.toLocationId,
    toLocationName: ticket.toLocationName,
    transDate: ticket.transDate,
    // arriveDate: extra.arriveDate,
    // address: extra.address,
    // phone: extra.phone,
    // contact: extra.contact,
    // memo: extra.memo,
    status: ticket.status,
    hash: ticket.hash,
    ticketItems,
    oldTicketItems,
    locations: data.locations,
    products: productVariants,
  }
}

async function addMove(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addMoveTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editMove(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editMoveTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { hash, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    quantity: parseInt(item.quantity),
    extra: item.extra,
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.productVariantId === newItem.productVariantId &&
      item.id === newItem.id
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.quantity !== newItem.quantity) return false
    if (!isEqual(item.extra, newItem.extra)) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    hash,
    transDate: state.transDate,
    fromLocationId: state.fromLocationId.value,
    toLocationId: state.toLocationId.value,
    extra: {
      type: state.type.value,
      arriveDate: state.arriveDate,
      address: state.address,
      phone: state.phone,
      contact: state.contact,
      memo: state.memo,
      sync: state.sync,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

async function onFromLocationChange(state, app, session, locationId) {
  const { locations, oldTicketItems } = state
  const ticketItems = [...state.ticketItems]
  if (ticketItems.length === 0) return []

  const location = getLocation(locations, locationId)
  const productVariantId = ticketItems.map((item) => item.productVariantId)
  const balances = await fetchBalances({
    app,
    session,
    locationType: location.type,
    locationId: location.id,
    productVariantId,
    oldTicketItems,
  })
  setBalances(ticketItems, balances)

  return { ticketItems }
}

async function confirmMove({ session, app, id, hash, status }) {
  const input = { hash, status }
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      confirmMoveTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function renderBatchItem(value, index) {
  return (
    <Text
      key={index}
      mt={index === 0 ? 0 : 2}
      color={value ? 'dark.1' : 'light.3'}
    >
      {value ? value : '\u2014'}
    </Text>
  )
}
