import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import {
  initializeState,
  handleTextChange,
  validateForm,
  getSelectOptions,
  getSelectOption,
  handleSelectChange,
} from 'utilities/form'
import { request } from 'utilities/request'
import { getProductOptions } from 'actions/product'
import { Table, Definition } from 'components/core'
import {
  TextInput,
  Select,
  NumberInput,
  Switch,
  TextArea,
} from 'components/form'

export const initialState = (value = {}, data, message) => {
  const categories = getSelectOptions(data.categories)
  return {
    id: value.id || '',
    categories,
    products: data.products || [],
    productOptions: getProductOptions(data.products || []),
    variants: value.variants || [],
    inputValues: getInitialInput(),
    editValues: {},
    ...initializeState({
      categoryId: getSelectOption(categories, value.categoryId),
      spu: value.spu || '',
      sku: value.sku || '',
      barcode: value.barcode || '',
      code: value.extra?.code || '',
      memo: value.memo || '',
      vendor: getVendor(value.extra?.vendor, message),
      isActive: value?.status !== 'INACTIVE',
    }),
  }
}

function getVendor(value, message) {
  if (!value) return null
  return { value, label: message({ id: `product.vendor.${value}` }) }
}

function getInitialInput() {
  return {
    productVariantId: '',
    productVariantName: '',
    quantity: 0,
  }
}

const validation = {
  categoryId: [{ type: 'required', message: 'error.required' }],
  spu: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 128, message: ['error.maxLength', { val: 128 }] },
  ],
  sku: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 32, message: ['error.maxLength', { val: 32 }] },
  ],
  variants: [{ type: 'required', message: 'error.required' }],
}

const defs = [
  {
    id: 'categoryId',
    label: 'product.field.category',
    render: (state) => state.categoryId.label,
  },
  { id: 'spu', label: 'product.field.spu' },
  { id: 'sku', label: 'product.field.sku' },
  { id: 'barcode', label: 'product.field.barcode' },
  { id: 'code', label: 'product.field.code' },
  { id: 'memo', label: 'field.memo' },
  {
    id: 'vendor',
    label: 'product.field.vendor',
    render: (state) => state.vendor?.label,
  },
  // {
  //   id: 'isActive',
  //   label: 'product.field.isActive',
  //   render: (state, message) => {
  //     const status = state.isActive ? 'ACTIVE' : 'INACTIVE'
  //     return message({ id: `product.status.${status}` })
  //   },
  // },
]

const columns = [
  {
    id: 'productVariantName',
    label: 'purchase.field.spu',
  },
  {
    id: 'quantity',
    label: 'field.quantity',
  },
]

export const labels = ({ state, message }) => {
  const content = defs.reduce((result, item) => {
    const { id, label, render } = item
    const value = render ? render(state, message) : state[id]
    result[id] = <Definition label={label} value={value} />
    return result
  }, {})

  content.variants = <Table columns={columns} rows={state.variants} />
  return content
}

export const fields = ({ state, setState, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id) =>
    handleSelectChange(id, state, setState, validation)

  return {
    categoryId: (
      <Select
        id="categoryId"
        label="product.field.category"
        placeholder="product.field.category"
        options={state.categories}
        value={state.categoryId}
        onChange={onSelectChange('categoryId')}
        errMsg={state.__error__.categoryId}
      />
    ),
    spu: (
      <TextInput
        id="spu"
        label="product.field.spu"
        placeholder="product.field.spu"
        value={state.spu}
        onChange={onTextChange('spu')}
        errMsg={state.__error__.spu}
      />
    ),
    sku: (
      <TextInput
        id="sku"
        label="product.field.sku"
        placeholder="product.field.sku"
        value={state.sku}
        onChange={onTextChange('sku')}
        errMsg={state.__error__.sku}
      />
    ),
    barcode: (
      <TextInput
        id="barcode"
        label="product.field.barcode"
        placeholder="product.field.barcode"
        value={state.barcode}
        onChange={onTextChange('barcode')}
        errMsg={state.__error__.barcode}
      />
    ),
    code: (
      <TextInput
        id="code"
        label="product.field.code"
        placeholder="product.field.code"
        value={state.code}
        onChange={onTextChange('code')}
        errMsg={state.__error__.code}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    vendor: (
      <Select
        label="product.field.vendor"
        isSearchable={false}
        options={[
          { value: 'sdj', label: message({ id: `product.vendor.sdj` }) },
        ]}
        value={state.vendor}
        onChange={onSelectChange('vendor')}
      />
    ),
    isActive: (
      <Definition
        direction="row"
        label="product.field.isActive"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isActive}
          onClick={() => {
            setState({ ...state, isActive: !state.isActive })
          }}
        />
      </Definition>
    ),
    variants: (
      <Table
        columns={[
          {
            id: 'productVariantName',
            label: 'combo.field.spu',
            renderInput: ({ row }) => {
              if (row.id) return row.productVariantName
              return (
                <Select
                  isClearable={false}
                  options={state.productOptions}
                  onChange={() => ({ quantity: 1 })}
                />
              )
            },
            getValue: (row) => {
              return getSelectOption(state.productOptions, row.productVariantId)
            },
          },
          {
            id: 'quantity',
            label: 'combo.field.quantity',
            width: '128px',
            renderInput: () => <NumberInput />,
          },
        ]}
        rows={state.variants}
        showAddInput
        showDeleteIcon
        inputValues={state.inputValues}
        editValues={state.editValues}
        onInputChange={(value) => setState({ ...state, inputValues: value })}
        onEditChange={(value) => setState({ ...state, editValues: value })}
        onAdd={({ row }) => {
          const { productVariantName, quantity } = row
          if (!productVariantName.value) return

          const variants = cloneDeep(state.variants)
          variants.push({
            productVariantId: productVariantName.value,
            productVariantName: productVariantName.label,
            quantity: parseInt(quantity),
          })
          const inputValues = getInitialInput()
          setState({ ...state, variants, inputValues })
        }}
        onEdit={({ row, index }) => {
          const variants = cloneDeep(state.variants)
          variants[index].quantity = parseInt(row.quantity)
          setState({ ...state, variants })
        }}
        onDelete={({ index }) => {
          const variants = cloneDeep(state.variants)
          variants.splice(index, 1)
          setState({ ...state, variants })
        }}
      />
    ),
  }
}

export const handlers = ({
  app,
  session,
  setData,
  setState,
  message,
  state,
  id,
  data,
  action,
}) => ({
  handleInit: async (categoryId) => {
    const value = await getConfigs({ app, session })
    setData(value)
    setState(initialState({ categoryId }, value, message))
  },
  handleLoad: async () => {
    const value = await getData({ app, session, id })
    setState(initialState(value, data, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = id
      ? await editCombo({ state, app, session })
      : await addCombo({ state, app, session })
    if (!ok) return

    action.handleLoad()
    action.handleClose()
    setState(initialState({}, data, message))
  },
})

async function getConfigs({ app, session }) {
  const query = `
    query {
      categories {
        id
        name
      }
      productVariants {
        id
        name
      }
    }
  `
  const [ok, data] = await request({ query }, { app, session })
  if (!ok) return null

  return { categories: data.categories, products: data.productVariants }
}

async function getData({ app, session, id }) {
  const variables = { id }
  const query = `
    query($id: ID) {
      productCombo(id: $id) {
        id
        categoryId
        categoryName
        spu
        sku
        barcode
        childVariants {
          id
          parentId
          name
          quantity
        }
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return null

  const combo = data.productCombo || {}
  const childVariants = combo.childVariants || []

  return {
    ...combo,
    variants: childVariants
      .filter(({ parentId }) => parentId === combo.id)
      .map((item) => ({
        ...item,
        productVariantId: item.id,
        productVariantName: item.name,
      })),
  }
}

async function addCombo({ state, app, session }) {
  const input = getSubmitInput(state)
  const variables = { input }
  const query = `
    mutation($input: ProductComboInput!) {
      addProductCombo(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editCombo({ state, app, session }) {
  const input = getSubmitInput(state)
  const variables = { id: state.id, input }
  const query = `
    mutation($id: ID!, $input: ProductComboInput!) {
      editProductCombo(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  return {
    categoryId: state.categoryId.value,
    categoryName: state.categoryId.label,
    spu: state.spu,
    sku: state.sku,
    barcode: state.barcode,
    extra: {
      vendor: state.vendor?.value || null,
      memo: state.memo,
      code: state.code,
    },
    status: state.isActive ? 'ACTIVE' : 'INACTIVE',
    childVariants: state.variants.map((item) => ({
      id: item.productVariantId,
      quantity: parseFloat(item.quantity),
    })),
  }
}
