import React from 'react'
import {
  LinkButton,
  Button,
  Definition,
  Link,
  StatusChip,
  Table,
  Center,
} from 'components/core'
import { Flex } from 'reflexbox'
import { CONFIRM_SET } from 'constants/actionType'
import { request } from 'utilities/request'
import { MdEdit, MdDelete } from 'react-icons/md'
import { FaExchangeAlt } from 'react-icons/fa'
import { getSelectOptions, showAddress, showDate } from 'utilities/form'
import { getStatusColor } from 'constants/status'

export const initialState = (value = {}) => ({
  id: value.id,
  name: value.name,
  extra: value.extra,
  phones: value.phones || [],
  cellphones: value.cellphones || [],
  addresses: value.addresses || [],
  memos: value.memos || [],
  staffs: getSelectOptions(value.staffs),
  products: value.products || [],
  refunds: value.refunds || [],
  repairs: value.repairs || [],
  dealerOptions: value.dealerOptions,
  productOptions: value.productOptions,
})

const repairColumns = (message) => [
  {
    id: 'id',
    label: 'field.ticketId',
    renderHtml: ({ row }) => {
      if (!row.id) return null

      const rootUrl = process.env.REACT_APP_STOCK_URL
      const type = row.ticketType.toLowerCase()
      return (
        <Link
          variant="primaryLink"
          href={`${rootUrl}/${type}/${row.id}/view`}
          target="_blank"
        >
          {row.id}
        </Link>
      )
    },
  },
  {
    id: 'type',
    label: 'repair.field.type',
    render: ({ row }) => row.extra?.typeName,
  },
  {
    id: 'repairDate',
    label: 'repair.field.repairDate',
    noWrap: true,
    render: ({ row }) => showDate(row.extra?.repairDate),
  },
  {
    id: 'tech',
    label: 'repair.field.techId',
    render: ({ row }) => row.extra?.techName?.join(', '),
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    render: ({ row }) => row.productVariantName.join(', '),
  },
  {
    id: 'model',
    label: 'repair.field.model',
    format: ['excel'],
    render: ({ row }) => row.models.join(', '),
  },
  {
    id: 'serialNo',
    label: 'repair.field.serialNo',
    format: ['excel'],
    render: ({ row }) => row.serialNos.join(', '),
  },
  {
    id: 'installDate',
    label: 'repair.field.installDate',
    format: ['excel'],
    render: ({ row }) => row.repairDates.join(', '),
  },
  {
    id: 'productMemo',
    label: 'repair.field.productMemo',
    format: ['excel'],
    render: ({ row }) => row.productMemos.join(', '),
  },
  {
    id: 'symptom',
    label: 'repair.field.symptom',
    render: ({ row }) => row.extra?.symptom,
  },
  {
    id: 'solution',
    label: 'repair.field.solution',
    format: ['excel'],
    render: ({ row }) => row.extra?.solution,
  },
  {
    id: 'memo',
    label: 'repair.field.memo',
    format: ['excel'],
    render: ({ row }) => row.extra?.memo,
  },
  {
    id: 'parts',
    label: 'repair.section.part',
    render: ({ row }) => {
      const { parts } = row
      if (!parts) return ''

      const partList = parts.reduce((result, receipt, index) => {
        const data = []
        Object.entries(receipt).forEach(([key, val]) => {
          switch (key) {
            case 'transDate':
              data.push(`${message({ id: 'field.date' })}: ${showDate(val)}`)
              break
            case 'id':
              data.push(`${message({ id: 'repair.field.receiptNo' })}: ${val}`)
              break
            case 'productVariantName':
              data.push(`${message({ id: 'repair.field.partName' })}: ${val}`)
              break
            case 'sku':
              data.push(`${message({ id: 'repair.field.partSku' })}: ${val}`)
              break
            case 'price':
              data.push(`${message({ id: 'field.price' })}: ${val}`)
              break
            case 'quantity':
              data.push(`${message({ id: 'field.quantity' })}: ${val}`)
              break
            default:
          }
        })
        result.push(`${index + 1}) ${data.join(', ')}`)
        return result
      }, [])
      return partList.join('; \n')
    },
  },
  {
    id: 'recipientContact',
    label: 'repair.field.recipientContact',
    format: ['excel'],
    render: ({ row }) => row.extra?.recipientContact,
  },
  {
    id: 'recipientPhone',
    label: 'repair.field.recipientPhone',
    format: ['excel'],
    render: ({ row }) => row.extra?.recipientPhone,
  },
  {
    id: 'recipientCellphone',
    label: 'repair.field.recipientCellphone',
    format: ['excel'],
    render: ({ row }) => row.extra?.recipientCellphone,
  },
  {
    id: 'recipientAddress',
    label: 'repair.field.recipientAddress',
    format: ['excel'],
    render: ({ row }) => showAddress(row.extra?.recipientAddress, message),
  },
]

export const fields = ({ app, session, state, message, id, action }) => ({
  id: <Definition direction="row" label="customer.field.id" value={state.id} />,
  name: (
    <Definition
      direction="row"
      label="customer.field.name"
      value={state.name}
    />
  ),
  subscription: (
    <Definition
      direction="row"
      label="customer.field.subscription"
      value={message({ id: `type.${!!state.extra?.subscription}` })}
    />
  ),
  bip: (
    <Definition
      direction="row"
      label="customer.field.bip"
      value={message({ id: `type.${!!state.extra?.bip}` })}
    />
  ),
  isNotIndividual: (
    <Definition
      direction="row"
      label="customer.field.isNotIndividual"
      value={message({ id: `type.${!!state.extra?.isNotIndividual}` })}
    />
  ),
  memo: (
    <Definition direction="row" label="field.memo" value={state.extra?.memo} />
  ),
  phones: (
    <Flex flexDirection="column">
      {state.phones
        .filter((item) => !!item)
        .map((phone, index) => {
          const contactMap = state.extra?.contact || {}
          const contact = contactMap[phone]
          return (
            <Flex
              key={phone}
              px={2}
              pt={2}
              justifyContent="space-between"
              alignItems="center"
            >
              {phone}
              {contact ? ` [${contact}]` : ''}
              <Center>
                <Button
                  variant="icon"
                  icon={<MdEdit />}
                  onClick={() =>
                    action.handlePhoneOpen('PHONE', { index, phone, contact })
                  }
                />
                <Button
                  variant="icon"
                  icon={<MdDelete />}
                  onClick={() => {
                    const item = {
                      open: true,
                      title: 'customer.title.deletePhone',
                      text: 'customer.message.deletePhone',
                      onSubmit: async () => {
                        const ok = await deletePhone({
                          app,
                          session,
                          id,
                          index,
                          type: 'PHONE',
                        })
                        if (ok) action.handleLoad()
                        return ok
                      },
                    }
                    session.dispatch({ type: CONFIRM_SET, item })
                  }}
                />
              </Center>
            </Flex>
          )
        })}
    </Flex>
  ),
  cellphones: (
    <Flex flexDirection="column">
      {state.cellphones
        .filter((item) => !!item)
        .map((cellphone, index) => {
          const contactMap = state.extra?.contact || {}
          const contact = contactMap[cellphone]
          return (
            <Flex
              key={cellphone}
              px={2}
              pt={2}
              justifyContent="space-between"
              alignItems="center"
            >
              {cellphone}
              {contact ? ` [${contact}]` : ''}
              <Center>
                <Button
                  variant="icon"
                  icon={<MdEdit />}
                  onClick={() =>
                    action.handlePhoneOpen('CELLPHONE', {
                      index,
                      cellphone,
                      contact,
                    })
                  }
                />
                <Button
                  variant="icon"
                  icon={<MdDelete />}
                  onClick={() => {
                    const item = {
                      open: true,
                      title: 'customer.title.deletePhone',
                      text: 'customer.message.deletePhone',
                      onSubmit: async () => {
                        const ok = await deletePhone({
                          app,
                          session,
                          id,
                          index,
                          type: 'CELLPHONE',
                        })
                        if (ok) action.handleLoad()
                        return ok
                      },
                    }
                    session.dispatch({ type: CONFIRM_SET, item })
                  }}
                />
              </Center>
            </Flex>
          )
        })}
    </Flex>
  ),
  addresses: (
    <Flex flexDirection="column">
      {state.addresses
        .filter((item) => !!item)
        .map((address, index) => (
          <Flex
            key={index}
            px={2}
            pt={2}
            justifyContent="space-between"
            alignItems="center"
          >
            {showAddress(address, message)}
            <Center>
              <Button
                variant="icon"
                icon={<MdEdit />}
                onClick={() => action.handleAddressOpen({ address, index })}
              />
              <Button
                variant="icon"
                icon={<MdDelete />}
                onClick={() => {
                  const item = {
                    open: true,
                    title: 'customer.title.deleteAddress',
                    text: 'customer.message.deleteAddress',
                    onSubmit: async () => {
                      const ok = await deleteAddress({
                        app,
                        session,
                        id,
                        index,
                      })
                      if (ok) action.handleLoad()
                      return ok
                    },
                  }
                  session.dispatch({ type: CONFIRM_SET, item })
                }}
              />
            </Center>
          </Flex>
        ))}
    </Flex>
  ),
  memos: (
    <Table
      columns={[
        {
          id: 'createdAt',
          label: 'customer.field.date',
          noWrap: true,
          render: ({ row }) => showDate(row.createdAt),
        },
        {
          id: 'staffName',
          label: 'customer.field.staffId',
        },
        {
          id: 'content',
          label: 'customer.field.content',
        },
        {
          id: 'actions',
          align: 'right',
          noWrap: true,
          render: ({ row }) => (
            <>
              <LinkButton
                mr={1}
                variant="icon"
                icon={<MdEdit />}
                onClick={() => action.handleMemoOpen(row)}
              />
              <Button
                disabled={row.hasTicket}
                variant="icon"
                icon={<MdDelete />}
                onClick={() => {
                  const onSubmit = async () => {
                    const ok = await deleteMemo({
                      session,
                      app,
                      id: row.id,
                      action,
                    })
                    if (ok) action.handleLoad()
                    return ok
                  }
                  const name = 'module.customerMemo'
                  session.dispatch({
                    type: CONFIRM_SET,
                    item: {
                      open: true,
                      title: { id: 'title.delete', texts: { name } },
                      text: { id: 'message.delete', texts: { name } },
                      onSubmit,
                    },
                  })
                }}
              />
            </>
          ),
        },
      ]}
      rows={state.memos}
    />
  ),
  products: (
    <Table
      columns={[
        {
          id: 'ticketId',
          label: 'field.ticketId',
          noWrap: true,
          render: ({ row }) => {
            const ticketId = row.extra?.ticketId
            if (!row.hasTicket && ticketId) return ticketId
            if (!row.hasTicket) return '\u2014'

            const rootUrl = process.env.REACT_APP_STOCK_URL
            const type = row.ticketType.toLowerCase()
            return (
              <Link
                variant="primaryLink"
                href={`${rootUrl}/${type}/${row.id}/view`}
                target="_blank"
              >
                {row.id}
              </Link>
            )
          },
        },
        {
          id: 'transDate',
          label: 'customer.field.transDate',
          noWrap: true,
          render: ({ row }) => showDate(row.transDate),
        },
        {
          id: 'dealerName',
          label: 'field.dealer',
        },
        {
          id: 'productVariantName',
          label: 'field.productName',
          renderHtml: ({ row }) => (
            <Link
              variant="primaryLink"
              onClick={() => action.handleProductOpen(row, true)}
            >
              {row.productVariantName}
            </Link>
          ),
        },
        {
          id: 'quantity',
          label: 'field.quantity',
          align: 'right',
        },
        {
          id: 'price',
          label: 'field.price',
          align: 'right',
        },
        {
          id: 'status',
          label: 'field.status',
          renderHtml: ({ row }) => {
            if (!row.hasTicket)
              return (
                <StatusChip
                  label="customer.status.DISPATCH_ACTIVE"
                  color="success.1"
                />
              )
            return (
              <StatusChip
                label={getProductStatusLabel(row)}
                color={getProductStatusColor(row)}
              />
            )
          },
        },
        {
          id: 'actions',
          align: 'right',
          noWrap: true,
          render: ({ row }) => (
            <>
              <LinkButton
                disabled={!row.hasTicket}
                mr={1}
                variant="icon"
                icon={<FaExchangeAlt />}
                iconProps={{ tooltip: 'customer.tooltip.switchUser' }}
                onClick={() => action.handleSwitchOpen(row)}
              />
              <LinkButton
                mr={1}
                variant="icon"
                icon={<MdEdit />}
                onClick={() => action.handleProductOpen(row, false)}
              />
              <Button
                disabled={row.hasTicket}
                variant="icon"
                icon={<MdDelete />}
                onClick={() => {
                  const onSubmit = async () => {
                    const ok = await deleteProduct({
                      session,
                      app,
                      id: row.id,
                      action,
                    })
                    if (ok) action.handleLoad()
                    return ok
                  }
                  session.dispatch({
                    type: CONFIRM_SET,
                    item: {
                      open: true,
                      title: 'customer.title.deleteProduct',
                      text: 'customer.message.deleteProduct',
                      onSubmit,
                    },
                  })
                }}
              />
            </>
          ),
        },
      ]}
      rows={state.products}
    />
  ),
  repairs: <Table columns={repairColumns(message)} rows={state.repairs} />,
  refunds: (
    <Table
      columns={[
        {
          id: 'ticketId',
          label: 'field.ticketId',
          noWrap: true,
          render: ({ row }) => {
            const rootUrl = process.env.REACT_APP_STOCK_URL
            return (
              <Link
                variant="primaryLink"
                href={`${rootUrl}/refund/${row.id}/view`}
                target="_blank"
              >
                {row.id}
              </Link>
            )
          },
        },
        {
          id: 'transDate',
          label: 'customer.field.transDate',
          noWrap: true,
          render: ({ row }) => showDate(row.transDate),
        },
        {
          id: 'fromLocationName',
          label: 'field.dealer',
        },
        {
          id: 'productVariantName',
          label: 'field.productName',
        },
        {
          id: 'quantity',
          label: 'field.quantity',
          align: 'right',
        },
        {
          id: 'status',
          label: 'field.status',
          renderHtml: ({ row }) => (
            <StatusChip
              label={`refund.status.${row.status}`}
              color={getProductStatusColor(row)}
            />
          ),
        },
      ]}
      rows={state.refunds}
    />
  ),
})

function getProductStatusLabel(row) {
  if (row.ticketType === 'DEFER' && row.status !== 'INACTIVE') {
    return 'customer.status.DEFER'
  }

  if (['PENDING', 'CALLBACK_PENDING'].includes(row.status)) {
    return `customer.status.DISPATCH_PENDING`
  }
  return `customer.status.DISPATCH_${row.status}`
}

function getProductStatusColor(row) {
  if (row.ticketType === 'DEFER' && row.status !== 'INACTIVE') {
    return 'warning.1'
  }
  return getStatusColor(row.status)
}

export const handlers = ({ session, app, state, setState, history, id }) => ({
  handleLoad: async () => {
    const data = await getData({ session, app, id })
    setState(initialState(data))
  },
  handleExport: async ({ createExcel, message }) => {
    const title = 'customer.section.repair'
    const cols = repairColumns(message)
    let parts = await Promise.all(
      state.repairs.map((item) => getPartData({ app, session, id: item.id })),
    )
    parts = parts.reduce((result, item) => {
      result = result.concat(item)
      return result
    }, [])
    const partMap = parts.reduce((result, item) => {
      if (!item) return result

      const partList = result[item.parentId] || []
      partList.push(item)
      result[item.parentId] = partList
      return result
    }, {})
    const rows = state.repairs.map((item) => {
      item.parts = partMap[item.id]
      return item
    })
    createExcel({ message, title, cols, rows })
  },
  handleDelete: async () => {
    const ok = await handleDelete({ session, app, id })
    if (!ok) return false

    history.push('/customer')
    return true
  },
})

async function getData({ app, session, id }) {
  const staffInput = { type: ['CUSTOMER_SUPPORT'] }
  const locationInput = { type: ['DEALER'] }
  const variables = { id, locationInput, staffInput }
  const query = `
    query($id: ID!, $locationInput: LocationQueryInput, $staffInput: StaffQueryInput) {
      staffs(input: $staffInput) {
        id
        name
      }
      locations(input: $locationInput) {
        id
        name
      }
      productVariants {
        id
        name
        sku
      }
      customer(id: $id) {
        id
        name
        extra
        phones
        cellphones
        addresses {
          zipcode
          city
          district
          street
          hasLift
        }
      }
      customerTickets(customerId: $id) {
        id
        ticketType
        transDate
        fromLocationName
        toLocationName
        productVariantId
        productVariantName
        itemProductVariantName
        quantity
        price
        extra
        itemId
        itemExtra
        status
        hash
      }
      customerProducts(id: $id) {
        id
        ticketType
        transDate
        productVariantId
        productVariantName
        quantity
        price
        extra
      }
      customerMemos(id: $id) {
        id
        content
        staffId
        staffName
        createdAt
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return null

  const products = data.customerProducts.map((item) => ({
    ...item,
    dealerId: item.extra?.dealerId,
    dealerName: item.extra?.dealerName,
  }))
  const repairs = []
  const refunds = []

  data.customerTickets.forEach((item) => {
    if (item.ticketType === 'REPAIR') {
      if (item.status === 'INACTIVE') return

      const repair = repairs.find((i) => i.id === item.id)
      const model = item.itemExtra?.model || ''
      const serialNo = item.itemExtra?.serialNo || ''
      const repairDate = item.itemExtra?.repairDate || ''
      const productMemo = item.itemExtra?.memo || ''

      if (repair) {
        repair.productVariantName.push(item.itemProductVariantName)
        repair.models.push(model)
        repair.serialNos.push(serialNo)
        repair.repairDates.push(repairDate)
        repair.productMemos.push(productMemo)
      } else {
        item.productVariantName = [item.itemProductVariantName]
        item.models = [model]
        item.serialNos = [serialNo]
        item.repairDates = [repairDate]
        item.productMemos = [productMemo]
        repairs.push(item)
      }
    } else if (item.ticketType === 'REFUND') {
      const itemExtra = item.itemExtra || {}
      if (itemExtra.isReceived) refunds.push(item)
    } else {
      item.hasTicket = true
      item.extra = item.itemExtra
      if (item.ticketType === 'SELL') {
        item.dealerName = item.fromLocationName
      } else {
        item.dealerName = item.toLocationName
      }
      products.push(item)
    }
  })
  sortProducts(products)
  sortRepairs(repairs)

  return {
    ...data.customer,
    memos: data.customerMemos,
    products,
    repairs,
    refunds,
    dealerOptions: getSelectOptions(data.locations),
    productOptions: getSelectOptions(data.productVariants),
    staffs: data.staffs,
  }
}

async function getPartData({ app, session, id }) {
  const partInput = { parentId: id, joinItem: true }
  const variables = { partInput }
  const query = `
    query($partInput: TicketQueryInput) {
      partSellTickets(input: $partInput) {
        id
        parentId
        transDate
        productVariantName
        sku
        price
        quantity
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  return data.partSellTickets
}

function sortProducts(products) {
  products.sort((a, b) => {
    if (a.transDate < b.transDate) return -1
    if (a.transDate > b.transDate) return 1
    return 0
  })
}

function sortRepairs(repairs) {
  repairs.sort((a, b) => {
    const aExtra = a.extra || { repairDate: 0 }
    const bExtra = b.extra || { repairDate: 0 }
    if (aExtra.repairDate < bExtra.repairDate) return 1
    if (aExtra.repairDate > bExtra.repairDate) return -1
    return 0
  })
}

export async function handleDelete({ session, app, id, action }) {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      deleteCustomer(id: $id)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (ok && action) action.handleLoad()
  return ok
}

async function deletePhone({ app, session, id, index, type }) {
  const variables = { id, index, type }
  const query = `
    mutation($id: ID!, $index: Int!, $type: PhoneType!) {
      deleteCustomerPhone(id: $id, index: $index, type: $type) 
    }
  `
  const [ok] = await request({ query, variables }, { app, session })
  return ok
}

async function deleteAddress({ app, session, id, index }) {
  const variables = { id, index }
  const query = `
    mutation($id: ID!, $index: Int!) {
      deleteCustomerAddress(id: $id, index: $index) 
    }
  `
  const [ok] = await request({ query, variables }, { app, session })
  return ok
}

async function deleteProduct({ app, session, id }) {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      deleteCustomerProduct(id: $id) 
    }
  `
  const [ok] = await request({ query, variables }, { app, session })
  return ok
}

async function deleteMemo({ app, session, id }) {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      deleteCustomerMemo(id: $id) 
    }
  `
  const [ok] = await request({ query, variables }, { app, session })
  return ok
}
