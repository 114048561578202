import React from 'react'
import {
  Address,
  DateInput,
  NumberInput,
  PhoneInput,
  Select,
  Switch,
  TextArea,
  TextInput,
} from 'components/form'
import {
  initializeState,
  handleTextChange,
  validateForm,
  handleSelectChange,
} from 'utilities/form'
import { getLocation } from 'actions/location'
import { Definition } from 'components/core'

export const initialState = (value = {}) => ({
  index: value.index,
  ...initializeState({
    transDate: value.transDate || '',
    ticketNo: value.ticketNo || '',
    billingId: value.billingId || '',
    dealerId: value.dealerId || {},
    dealerCode: value.dealerCode || '',
    recipientId: value.recipientId || {},
    recipientContact: value.recipientContact || '',
    recipientAddress: value.recipientAddress || {},
    recipientPhone: value.recipientPhone || '',
    recipientCellphone: value.recipientCellphone || '',
    recipientEmail: value.recipientEmail || '',
    salesId: value.salesId || {},
    backupId: value.backupId || {},
    memo: value.memo || '',
    contractFromDate: value.contractFromDate || '',
    contractToDate: value.contractToDate || '',
    contractSignDate: value.contractSignDate || '',
    contractAmount: value.contractAmount || 0,
    issueReceipt: value.issueReceipt || '',
    productVariantId: value.productVariantId || {},
    isDemo: value.isDemo || false,
    isGift: value.isGift || false,
    isContract: value.isContract || false,
    quantity: value.quantity || 0,
    price: value.price || 0,
    itemMemo: value.itemMemo || '',
  }),
})

const validation = {
  ticketNo: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    transDate: (
      <DateInput
        id="transDate"
        label="defer.field.transDate"
        placeholder="defer.field.transDate"
        value={state.transDate}
        role="lockDispatchTicket"
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    ticketNo: (
      <TextInput
        id="ticketNo"
        label="defer.field.ticketNo"
        placeholder="defer.field.ticketNo"
        value={state.ticketNo}
        onChange={onTextChange('ticketNo')}
        errMsg={state.__error__.ticketNo}
      />
    ),
    billingId: (
      <Select
        label="defer.field.billingId"
        isClearable={false}
        options={data.billings}
        value={state.billingId}
        onChange={onSelectChange('billingId')}
        errMsg={state.__error__.billingId}
      />
    ),
    dealerId: (
      <Select
        label="defer.field.dealerId"
        isClearable={false}
        options={data.dealers}
        value={state.dealerId}
        onChange={onSelectChange('dealerId', ({ value }) =>
          onDealerChange(data.dealerLocations, value),
        )}
        errMsg={state.__error__.dealerId}
      />
    ),
    dealerCode: (
      <TextInput
        id="dealerCode"
        label="defer.field.dealerCode"
        placeholder="defer.field.dealerCode"
        value={state.dealerCode}
        onChange={onTextChange('dealerCode')}
        errMsg={state.__error__.dealerCode}
      />
    ),
    recipientId: (
      <Select
        isClearable={false}
        label="defer.field.recipientId"
        options={data.recipients}
        value={state.recipientId}
        onChange={onSelectChange('recipientId', ({ value }) =>
          onRecipientChange(data.recipientLocations, value),
        )}
        errMsg={state.__error__.recipientId}
      />
    ),
    recipientContact: (
      <TextInput
        id="recipientContact"
        label="defer.field.recipientContact"
        value={state.recipientContact}
        onChange={onTextChange('recipientContact')}
        errMsg={state.__error__.recipientContact}
      />
    ),
    recipientAddress: (
      <Address
        id="recipientAddress"
        label="defer.field.recipientAddress"
        placeholder="defer.field.recipientAddress"
        value={state.recipientAddress}
        onChange={onTextChange('recipientAddress')}
        errMsg={state.__error__.recipientAddress}
      />
    ),
    recipientPhone: (
      <PhoneInput
        id="recipientPhone"
        label="defer.field.recipientPhone"
        value={state.recipientPhone}
        onChange={onTextChange('recipientPhone')}
        errMsg={state.__error__.recipientPhone}
      />
    ),
    recipientCellphone: (
      <PhoneInput
        id="recipientCellphone"
        label="defer.field.recipientCellphone"
        type="cellphone"
        value={state.recipientCellphone}
        onChange={onTextChange('recipientCellphone')}
        errMsg={state.__error__.recipientCellphone}
      />
    ),
    recipientEmail: (
      <TextInput
        id="recipientEmail"
        label="defer.field.recipientEmail"
        value={state.recipientEmail}
        onChange={onTextChange('recipientEmail')}
        errMsg={state.__error__.recipientEmail}
      />
    ),
    salesId: (
      <Select
        label="defer.field.salesId"
        isClearable={false}
        isMulti
        options={data.sales}
        value={state.salesId}
        onChange={onSelectChange('salesId')}
        errMsg={state.__error__.salesId}
      />
    ),
    backupId: (
      <Select
        label="defer.field.backupId"
        isClearable={false}
        isMulti
        options={data.sales}
        value={state.backupId}
        onChange={onSelectChange('backupId')}
        errMsg={state.__error__.backupId}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="defer.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    contractFromDate: (
      <DateInput
        id="contractFromDate"
        label="dealer.field.contractFromDate"
        placeholder="dealer.field.contractFromDate"
        value={state.contractFromDate}
        onChange={onTextChange('contractFromDate')}
        errMsg={state.__error__.contractFromDate}
      />
    ),
    contractToDate: (
      <DateInput
        id="contractToDate"
        label="dealer.field.contractToDate"
        placeholder="dealer.field.contractToDate"
        value={state.contractToDate}
        onChange={onTextChange('contractToDate')}
        errMsg={state.__error__.contractToDate}
      />
    ),
    contractSignDate: (
      <DateInput
        id="contractSignDate"
        label="dealer.field.contractSignDate"
        placeholder="dealer.field.contractSignDate"
        value={state.contractSignDate}
        onChange={onTextChange('contractSignDate')}
        errMsg={state.__error__.contractSignDate}
      />
    ),
    contractAmount: (
      <NumberInput
        id="contractAmount"
        min="0"
        label="dealer.field.contractAmount"
        placeholder="dealer.field.contractAmount"
        value={state.contractAmount}
        onChange={onTextChange('contractAmount')}
        errMsg={state.__error__.contractAmount}
      />
    ),
    issueReceipt: (
      <Definition
        direction="row"
        label="dealer.field.issueReceipt"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.issueReceipt}
          onClick={() => {
            setState({ ...state, issueReceipt: !state.issueReceipt })
          }}
        />
      </Definition>
    ),
    productVariantId: (
      <Select
        id="productVariantId"
        label="field.productName"
        placeholder="field.productName"
        isClearable={false}
        options={data.products}
        value={state.productVariantId}
        onChange={onSelectChange('productVariantId')}
        errMsg={state.__error__.productVariantId}
      />
    ),
    isDemo: (
      <Definition
        direction="row"
        label="defer.field.isDemo"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isDemo}
          onClick={() => {
            setState({ ...state, isDemo: !state.isDemo })
          }}
        />
      </Definition>
    ),
    isGift: (
      <Definition
        direction="row"
        label="defer.field.isGift"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isGift}
          onClick={() => {
            setState({ ...state, isGift: !state.isGift })
          }}
        />
      </Definition>
    ),
    isContract: (
      <Definition
        direction="row"
        label="defer.field.isContract"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isContract}
          onClick={() => {
            setState({ ...state, isContract: !state.isContract })
          }}
        />
      </Definition>
    ),
    quantity: (
      <NumberInput
        id="quantity"
        min="0"
        label="field.quantity"
        placeholder="field.quantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    ),
    price: (
      <NumberInput
        type="decimal"
        id="price"
        label="field.price"
        placeholder="field.price"
        autoComplete="off"
        value={state.price}
        onChange={onTextChange('price')}
        errMsg={state.__error__.price}
      />
    ),
    itemMemo: (
      <TextArea
        id="itemMemo"
        label="defer.field.itemMemo"
        value={state.itemMemo}
        onChange={onTextChange('itemMemo')}
      />
    ),
  }
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return
    action.editTicketItem(state)
    action.handleClose()
  },
})

function onDealerChange(dealers, locationId) {
  const location = getLocation(dealers, locationId)
  const extra = location.extra || {}
  const backupId = extra.salesId?.map((id, index) => ({
    value: id,
    label: extra.salesName[index],
  }))

  return { backupId, recipientId: {} }
}

function onRecipientChange(recipients, locationId) {
  const location = getLocation(recipients, locationId)
  if (!location) return {}

  const extra = location.extra || {}
  const backupId = extra.salesId?.map((id, index) => ({
    value: id,
    label: extra.salesName[index],
  }))

  return { backupId }
}
