import React from 'react'
import { request } from 'utilities/request'
import { getLimit, getPagination } from 'utilities/pagination'
import { selectFilter, textFilter } from 'utilities/filter'
import { Link } from 'components/core'
import { printHtml } from 'utilities/print'

export const filters = [
  {
    id: 'name',
    label: 'product.field.spu',
    input: textFilter,
  },
  {
    id: 'sku',
    label: 'product.field.sku',
    input: textFilter,
  },
  {
    id: 'code',
    label: 'product.field.code',
    input: textFilter,
  },
  {
    id: 'option',
    label: 'inventory.filter.option',
    input: (props) =>
      selectFilter({
        ...props,
        options: [
          {
            value: 'OVER_DEFERRED',
            label: props.message({ id: 'inventory.option.OVER_DEFERRED' }),
          },
          {
            value: 'SHOW_ZERO',
            label: props.message({ id: 'inventory.option.SHOW_ZERO' }),
          },
        ],
      }),
  },
]

export const columns = ({ action }) => [
  {
    id: 'productVariantName',
    label: 'inventory.field.spu',
    format: ['html', 'excel', 'print'],
  },
  {
    id: 'sku',
    label: 'inventory.field.sku',
    format: ['html', 'excel', 'print'],
  },
  {
    id: 'quantity',
    label: 'inventory.field.totalQuantity',
    align: 'right',
    format: ['html', 'excel', 'print'],
    render: ({ row }) =>
      row.quantity +
      row.pendingProcessQuantity +
      row.lentDispatchLockQuantity +
      row.lentDispatchUnlockQuantity,
  },
  {
    id: 'pendingProcessQuantity',
    label: 'inventory.field.pendingProcessQuantity',
    align: 'right',
    format: ['html', 'excel', 'print'],
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() =>
          action.handlePendingOpen({
            productVariantId: row.productVariantId,
          })
        }
      >
        {row.pendingProcessQuantity + row.pendingProcessUnlockQuantity}
      </Link>
    ),
    render: ({ row }) =>
      row.pendingProcessQuantity + row.pendingProcessUnlockQuantity,
  },
  {
    id: 'lockQuantity',
    label: 'inventory.field.lockQuantity',
    align: 'right',
    format: ['html', 'excel', 'print'],
  },
  {
    id: 'lockableQuantity',
    label: 'inventory.field.lockableQuantity',
    align: 'right',
    format: ['html', 'excel', 'print'],
    render: ({ row }) =>
      row.quantity +
      row.pendingProcessQuantity +
      row.lentDispatchLockQuantity -
      row.lockQuantity -
      row.unlockQuantity,
  },
  {
    id: 'unlockableQuantity',
    label: 'inventory.field.unlockableQuantity',
    align: 'right',
    format: ['html', 'excel', 'print'],
    render: ({ row }) => row.unlockQuantity + row.lentDispatchUnlockQuantity,
  },
  {
    id: 'availableQuantity',
    label: 'inventory.field.availableQuantity',
    align: 'right',
    format: ['html', 'excel', 'print'],
    render: ({ row }) =>
      row.quantity +
      row.lentDispatchLockQuantity +
      row.lentDispatchUnlockQuantity -
      row.lockQuantity -
      row.unavailableQuantity,
  },
  {
    id: 'unavailableQuantity',
    label: 'inventory.field.unavailableQuantity',
    align: 'right',
    format: ['html', 'excel', 'print'],
    render: ({ row }) => row.unavailableQuantity,
  },
  // {
  //   id: 'lentQuantity',
  //   label: 'inventory.field.lentQuantity',
  //   align: 'right',
  //   format: ['html', 'excel', 'print'],
  //   render: ({ row }) => row.lentQuantity,
  // },
  {
    id: 'deferQuantity',
    label: 'inventory.field.deferQuantity',
    align: 'right',
    format: ['html', 'excel', 'print'],
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() =>
          action.handleDeferOpen({
            productVariantId: row.productVariantId,
          })
        }
      >
        {row.deferQuantity}
      </Link>
    ),
  },
]

export const handlers = ({
  session,
  app,
  message,
  setState,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ pagination = {}, filterValues = [] } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handlePrint: async ({ filterValues }) => {
    const rows = await getExportData({ session, app, filterValues })
    const title = 'inventory.title.allList'
    const list = { columns: columns({}), rows }
    const content = [{ type: 'list', value: list }]
    printHtml({ title, content, message })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'inventory.title.allList'
    const cols = columns({}).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, filterValues = [], pagination = {} }) {
  const variables = { input: getDataInput({ pagination, filterValues }) }
  const query = `
    query($input: InventoryQueryInput) {
      inventoryAllCount(input: $input)
      inventoryAllList(input: $input) {
        locationId
        productVariantId
        quantity
        pendingProcessQuantity
        pendingProcessUnlockQuantity
        lockQuantity
        unlockQuantity
        deferQuantity
        unavailableQuantity
        lentQuantity
        lentDispatchLockQuantity
        lentDispatchUnlockQuantity
        productVariantName
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.inventoryAllList,
    pagination: getPagination(page, countPerPage, data.inventoryAllCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues }) }
  const query = `
    query($input: InventoryQueryInput) {
      inventoryAllList(input: $input) {
        locationId
        productVariantId
        quantity
        pendingProcessQuantity
        pendingProcessUnlockQuantity
        lockQuantity
        unlockQuantity
        deferQuantity
        unavailableQuantity
        lentQuantity
        lentDispatchLockQuantity
        lentDispatchUnlockQuantity
        productVariantName
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.inventoryAllList
}

function getDataInput({ filterValues, pagination }) {
  const name = filterValues.find(({ id }) => id === 'name')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const code = filterValues.find(({ id }) => id === 'code')
  const option = filterValues.find(({ id }) => id === 'option')
  const input = {
    productVariantName: name?.value,
    sku: sku?.value,
    code: code?.value,
    option: { stockStatus: option?.value.value },
    orderBy: [{ key: 'productVariantName' }],
  }

  if (pagination) input.limit = getLimit(pagination)
  return input
}
