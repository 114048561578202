export default {
  'transferOut.title.list': '跨系統調出目錄',
  'transferOut.title.add': '新增跨系統調出單',
  'transferOut.title.edit': '修改跨系統調出單',
  'transferOut.title.view': '跨系統調出單詳情',
  'transferOut.title.addItem': '新增調撥商品',
  'transferOut.title.editItem': '修改調撥商品',
  'transferOut.title.viewItem': '調撥商品詳情',
  'transferOut.title.print': '跨系統調出單 - {ticketId}',
  'transferOut.btn.add': '新增跨系統調出',
  'transferOut.btn.addBatch': '新增批號',
  'transferOut.section.product': '調撥商品',
  'transferOut.section.basic': '基礎信息',
  'transferOut.field.id': '單號',
  'transferOut.field.warehouseId': '入庫倉別',
  'transferOut.field.ticketNo': '廠商單號',
  'transferOut.field.srcCompany': '廠商名稱',
  'transferOut.field.billCompany': '歸帳',
  'transferOut.field.dispatchDate': '出貨日',
  'transferOut.field.transDate': '預計到貨日',
  'transferOut.field.arriveDate': '實際到貨日',
  'transferOut.field.fromLocation': '取倉',
  'transferOut.field.createdAt': '下單日期',
  'transferOut.field.rackNo': '櫃號',
  'transferOut.field.sealNo': '封條號碼',
  'transferOut.field.contact': '倉庫聯絡人',
  'transferOut.field.address': '倉庫地址',
  'transferOut.field.phone': '倉庫電話',
  'transferOut.field.memo': '備註',
  'transferOut.field.productVariantId': '商品',
  'transferOut.field.batchNo': '批號',
  'transferOut.field.expireAt': '效期',
  'transferOut.field.spu': '商品名稱',
  'transferOut.field.sku': '品號',
  'transferOut.field.purchasedQuantity': '出貨數量',
  'transferOut.field.quantity': '到貨數量',
  'transferOut.field.lockQuantity': '鎖定數量',
  'transferOut.field.status': '狀態',
}
