import React from 'react'
import { TextInput, Select, Switch } from 'components/form'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm,
} from 'utilities/form'
import { request } from 'utilities/request'
import { Definition } from 'components/core'

export const initialState = (value = {}, message) =>
  initializeState({
    id: value.id || '',
    name: value.name || '',
    ein: value.extra ? value.extra.ein : '',
    type: getType(value.extra, message),
    isActive: value?.status !== 'INACTIVE',
  })

function getTypes(message) {
  return [
    {
      value: 'BILLING',
      label: message({ id: 'company.type.BILLING' }),
    },
    {
      value: 'PURCHASE',
      label: message({ id: 'company.type.PURCHASE' }),
    },
    {
      value: 'SHIPPING',
      label: message({ id: 'company.type.SHIPPING' }),
    },
  ]
}

function getType(extra, message) {
  if (!extra) return getTypes(message)[0]
  return {
    value: extra.type,
    label: message({ id: `company.type.${extra.type}` }),
  }
}

const validation = {
  name: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] },
  ],
}

export const fields = ({ state, setState, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    name: (
      <TextInput
        id="name"
        label="company.field.name"
        placeholder="company.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    ein: (
      <TextInput
        id="ein"
        label="company.field.ein"
        placeholder="company.field.ein"
        value={state.ein}
        onChange={onTextChange('ein')}
        errMsg={state.__error__.ein}
      />
    ),
    type: (
      <Select
        label="company.field.type"
        containerProps={{ mb: [2, 0], mr: 3, flex: 1, width: 1 }}
        isSearchable={false}
        isClearable={false}
        options={getTypes(message)}
        value={state.type}
        onChange={handleSelectChange('type', state, setState, validation)}
      />
    ),
    isActive: (
      <Definition
        direction="row"
        label="dealer.field.isActive"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isActive}
          onClick={() => {
            setState({ ...state, isActive: !state.isActive })
          }}
        />
      </Definition>
    ),
  }
}

export const handlers = ({ state, setState, session, app, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = state.id
      ? await editCompany(state, app, session)
      : await addCompany(state, app, session)

    if (ok) {
      action.handleLoad()
      action.handleClose()
    }

    return ok
  },
})

async function addCompany(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: LocationInput!) {
      addCompany(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

async function editCompany(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: LocationInput!) {
      editCompany(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

function getSubmitInput(state) {
  const { name, ein, type, isActive } = state
  return {
    name,
    type: 'COMPANY',
    extra: { ein, type: type.value },
    status: isActive ? 'ACTIVE' : 'INACTIVE',
  }
}
