import React, { Suspense, lazy } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { convertDate, createExcel, readExcel } from 'utilities/excel'
import {
  getSelectOptions,
  handleTextChange,
  initializeState,
  parseAddress,
  validateForm,
} from 'utilities/form'
import { request } from 'utilities/request'
import { Center, LoadingIcon } from 'components/core'
import { ALERT_ADD } from 'constants/actionType'
import { getLocation } from 'actions/location'
import { filterLocations } from 'utilities/permission'

const FileInput = lazy(() => import('components/file/FileInput'))

export const initialState = (value = {}) => {
  const dealerMap = value.dealers?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const recipientMap = value.recipients?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const billingMap = value.billings?.reduce((result, item) => {
    result[item.label] = item.value
    return result
  }, {})

  const productMap = value.products?.reduce((result, item) => {
    result[item.sku] = { value: item.id, label: item.name }
    return result
  }, {})

  const salesMap = value.sales?.reduce((result, item) => {
    result[item.name] = item.id
    return result
  }, {})

  return {
    dealers: value.dealers || [],
    dealerLocations: value.dealerLocations || [],
    dealerMap,
    recipients: value.recipients || [],
    recipientLocations: value.recipientLocations || [],
    recipientMap,
    dealerRecipients: [],
    billings: value.billings || [],
    billingMap,
    products: getSelectOptions(value.products),
    productMap,
    sales: getSelectOptions(value.sales),
    salesMap,
    backups: getSelectOptions(value.backups),
    ticketItems: value.ticketItems || [],
    ...initializeState({
      dealerId: '',
      recipientId: '',
      salesId: [],
      backupId: [],
      file: '',
    }),
  }
}

const columns = [
  { id: 'transDate', label: 'defer.field.transDate' },
  { id: 'ticketNo', label: 'defer.field.ticketNo' },
  { id: 'billingId', label: 'defer.field.billingId' },
  { id: 'dealerId', label: 'defer.field.dealerId' },
  { id: 'dealerCode', label: 'defer.field.dealerCode' },
  { id: 'recipientId', label: 'defer.field.recipientId' },
  { id: 'recipientContact', label: 'defer.field.recipientContact' },
  { id: 'recipientAddress', label: 'defer.field.recipientAddress' },
  { id: 'elevator', label: 'field.elevator' },
  { id: 'recipientPhone', label: 'defer.field.recipientPhone' },
  { id: 'recipientCellphone', label: 'defer.field.recipientCellphone' },
  { id: 'recipientEmail', label: 'defer.field.recipientEmail' },
  { id: 'salesId', label: 'defer.field.salesId' },
  { id: 'backupId', label: 'defer.field.backupId' },
  { id: 'memo', label: 'defer.field.memo' },
  { id: 'contractFromDate', label: 'dealer.field.contractFromDate' },
  { id: 'contractToDate', label: 'dealer.field.contractToDate' },
  { id: 'contractSignDate', label: 'dealer.field.contractSignDate' },
  { id: 'contractAmount', label: 'dealer.field.contractAmount' },
  { id: 'issueReceipt', label: 'dealer.field.issueReceipt' },
  { id: 'productVariantId', label: 'field.sku' },
  { id: 'isDemo', label: 'defer.field.isDemo' },
  { id: 'isGift', label: 'defer.field.isGift' },
  { id: 'isContract', label: 'defer.field.isContract' },
  { id: 'quantity', label: 'field.quantity' },
  { id: 'price', label: 'field.price' },
  { id: 'itemMemo', label: 'defer.field.itemMemo' },
]

const validation = {
  file: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    file: (
      <Suspense
        fallback={
          <Center>
            <LoadingIcon />
          </Center>
        }
      >
        <FileInput
          id="file"
          label="transport.field.importFile"
          accept=".xlsx"
          value={state.file}
          onUpload={onTextChange('file')}
          onDelete={onTextChange('file')}
          onError={(errorMessages) =>
            errorMessages.forEach((item) => {
              session.dispatch({
                type: ALERT_ADD,
                item: { type: 'error', message: item },
              })
            })
          }
          errMsg={state.__error__.file}
        />
      </Suspense>
    ),
  }
}

export const handlers = ({
  app,
  session,
  state,
  setState,
  message,
  data: { cityMap, districtMap },
  action,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, message })
    setState(initialState(data))
  },
  downloadTemplate: () => {
    const title = 'defer.message.template'
    createExcel({ message, title, timestamp: false, cols: columns, rows: [] })
  },
  loadExcel: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const comboCodeMap = await getComboCodeMap({ session, app, state })
    const cities = Object.keys(cityMap)
    const districts = Object.keys(districtMap)
    const excelFile = await readFile(state.file)
    const data = await readExcel(excelFile)
    const ticketItems = data.reduce((result, item) => {
      const row = parseRow({
        state,
        item,
        message,
        cityMap,
        cities,
        districtMap,
        districts,
      })

      const comboItems = getComboItems(row, comboCodeMap)
      if (comboItems) {
        comboItems.forEach((item) => {
          result.push(item)
        })
      } else {
        result.push(row)
      }

      return result
    }, [])

    setState({ ...state, ticketItems })
    action.setOpen(true)
  },
})

async function getData({ app, session, message }) {
  const staffInput = { type: ['SALES'] }
  const locationInput = { type: ['DEALER', 'STORAGE', 'COMPANY'] }
  const productInput = { status: ['ACTIVE', 'INACTIVE'] }
  const variables = { productInput, staffInput, locationInput }
  const query = `
    query($productInput: ProductQueryInput, $locationInput: LocationQueryInput, $staffInput: StaffQueryInput) {
      locations(input: $locationInput) {
        id
        parentId
        name
        type
        extra
      }
      staffs(input: $staffInput) {
        id
        name
      }
      productVariants(input: $productInput) {
        id
        name
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { staffs, productVariants } = data
  const dealers = []
  const dealerLocations = []
  const recipients = [
    {
      value: '__INDIVIDUAL__',
      label: message({ id: 'dispatch.recipient.individual' }),
      parentId: 'all',
    },
  ]
  const recipientLocations = []
  const billings = []
  const locations = filterLocations(app.state.staff, data.locations)

  locations.forEach((item) => {
    const { type, extra } = item
    const result = { label: item.name, value: item.id }
    switch (type) {
      case 'DEALER':
        dealers.push(result)
        dealerLocations.push(item)
        break
      case 'STORAGE':
        result.parentId = item.parentId
        recipients.push(result)
        recipientLocations.push(item)
        break
      case 'COMPANY':
        if (extra.type === 'BILLING') billings.push(result)
        break
      default:
        break
    }
  })

  return {
    dealers,
    dealerLocations,
    recipients,
    recipientLocations,
    billings,
    sales: staffs,
    products: productVariants,
  }
}

async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}

function parseRow({
  state,
  item,
  message,
  cityMap,
  cities,
  districtMap,
  districts,
}) {
  const row = {}

  columns.forEach(({ id, label }) => {
    let value = item[message({ id: label })]
    if (typeof value === 'string') value = value.trim()

    const dealerId = state.dealerMap[value]
    const recipientId = state.recipientMap[value]
    const billingId = state.billingMap[value]
    const productOption = state.productMap[value]
    let backupId = null

    switch (id) {
      case 'ticketId':
        value = `${value}`
        break
      case 'dealerId':
        value = dealerId ? { value: dealerId, label: value } : state.dealerId
        backupId = getBackupId(state.dealerLocations, value?.value)
        if (backupId) row.backupId = backupId
        break
      case 'recipientId':
        value = recipientId
          ? { value: recipientId, label: value }
          : state.recipientId
        backupId = getBackupId(state.recipientLocations, value?.value)
        if (backupId) row.backupId = backupId
        break
      case 'recipientAddress':
        value = parseAddress(cityMap, cities, districtMap, districts, value)
        break
      case 'elevator':
        const address = row.recipientAddress
        if (address) address.hasLift = getElevator(value)
        break
      case 'billingId':
        value = billingId ? { value: billingId, label: value } : null
        break
      case 'salesId':
      case 'backupId':
        value = value?.split(',').reduce((result, item) => {
          const salesId = state.salesMap[item]
          if (!salesId) return result
          result.push({ value: salesId, label: item })
          return result
        }, [])
        if (!value || value.length === 0) value = state.salesId
        break
      case 'productVariantId':
        row.sku = value
        value = productOption || null
        break
      case 'transDate':
      case 'contractFromDate':
      case 'contractToDate':
      case 'contractSignDate':
        value = convertDate(value)
        break
      case 'issueReceipt':
      case 'isDemo':
      case 'isGift':
      case 'isContract':
        value = value === 'Y'
        break
      default:
    }
    row[id] = value
  })

  return row
}

async function getComboCodeMap({ app, session }) {
  const variables = { input: {} }
  const query = `
    query($input: ProductComboCodeQueryInput) {
      productComboCodes(input: $input) {
        locationId
        code
        products
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  return data.productComboCodes.reduce((result, item) => {
    const key = `${item.locationId}::${item.code}`
    result[key] = item.products
    return result
  }, {})
}

function getComboItems(row, comboCodeMap) {
  if (!comboCodeMap) return null
  if (!row.dealerId?.value) return null

  const products = comboCodeMap[`${row.dealerId.value}::${row.sku}`]
  if (!products) return null

  return products.reduce((result, item) => {
    const newRow = cloneDeep(row)
    const quantity = parseInt(row.quantity)
    newRow.productVariantId = {
      value: item.productVariantId,
      label: item.productVariantName,
    }
    newRow.quantity = quantity * parseInt(item.quantity)
    newRow.price = quantity * (item.price || row.price || 0)
    result.push(newRow)
    return result
  }, [])
}

function getBackupId(locations, locationId) {
  if (!locationId || locationId === '__INDIVIDUAL__') return null
  const location = getLocation(locations, locationId)
  const extra = location?.extra || {}
  return extra.salesId?.map((id, index) => ({
    value: id,
    label: extra.salesName[index],
  }))
}

function getElevator(value) {
  if (!value) return null

  const val = value.toLowerCase()

  if (val.includes('y') || value.includes('有')) return true
  if (val.includes('n') || value.includes('無')) return false

  return null
}
