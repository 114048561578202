import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import MainLayout from 'layouts/MainLayout'
import Login from 'views/Login'
import AccountPage from 'views/AccountPage'
import Dashboard from 'views/Dashboard'
import RoleList from 'views/RoleList'
import RolePage from 'views/RolePage'
import StaffList from 'views/StaffList'
import StaffRolePage from 'views/StaffRolePage'
import WarehouseGroupList from 'views/WarehouseGroupList'
import WarehouseList from 'views/WarehouseList'
import SalesDeptList from 'views/SalesDeptList'
import DealerList from 'views/DealerList'
import DealerPage from 'views/DealerPage'
import DealerOrgList from 'views/DealerOrgList'
import ReportRestockList from 'views/ReportRestockList'
import ProcessConfigList from 'views/ProcessConfigList'
import ProcessConfigPage from 'views/ProcessConfigPage'
import CompanyList from 'views/CompanyList'
import CategoryList from 'views/CategoryList'
import ProductList from 'views/ProductList'
import ProductComboCodeList from 'views/ProductComboCodeList'
import PurchaseList from 'views/PurchaseList'
import PurchasePage from 'views/PurchasePage'
import PurchaseImportPage from 'views/PurchaseImportPage'
import AdjustList from 'views/AdjustList'
import AdjustPage from 'views/AdjustPage'
import AdjustImportPage from 'views/AdjustImportPage'
import MoveList from 'views/MoveList'
import MovePage from 'views/MovePage'
import TransferOutPage from 'views/TransferOutPage'
import TransferInPage from 'views/TransferInPage'
import LockList from 'views/LockList'
import LockPage from 'views/LockPage'
import ReverseList from 'views/ReverseList'
import ReversePage from 'views/ReversePage'
import DispatchList from 'views/DispatchList'
import DispatchPage from 'views/DispatchPage'
import DispatchImportPage from 'views/DispatchImportPage'
import DispatchImportSerialPage from 'views/DispatchImportSerialPage'
import SellList from 'views/SellList'
import SellPage from 'views/SellPage'
import TransportList from 'views/TransportList'
import TransportPage from 'views/TransportPage'
import TransportImportFeePage from 'views/TransportImportFeePage'
import RepairList from 'views/RepairList'
import RepairPage from 'views/RepairPage'
import RepairTypeList from 'views/RepairTypeList'
import RepairTagList from 'views/RepairTagList'
import RepairRemarkList from 'views/RepairRemarkList'
import RepairFeedbackList from 'views/RepairFeedbackList'
import RepairFeedbackPage from 'views/RepairFeedbackPage'
import RepairReportList from 'views/RepairReportList'
import RepairReportPage from 'views/RepairReportPage'
import RefundList from 'views/RefundList'
import RefundPage from 'views/RefundPage'
import RefundImportPage from 'views/RefundImportPage'
import ProcessList from 'views/ProcessList'
import ProcessPage from 'views/ProcessPage'
import DeferList from 'views/DeferList'
import DeferPage from 'views/DeferPage'
import DeferImportPage from 'views/DeferImportPage'
import LendList from 'views/LendList'
import LendPage from 'views/LendPage'
import InventoryAllList from 'views/InventoryAllList'
import InventoryDeptList from 'views/InventoryDeptList'
import InventoryList from 'views/InventoryList'
import CustomerList from 'views/CustomerList'
import CustomerPage from 'views/CustomerPage'
import PluginList from 'views/PluginList'
import SyPage from 'views/SyPage'
import SdjPage from 'views/SdjPage'
import PxmartPage from 'views/PxmartPage'
import CarrefourPage from 'views/CarrefourPage'
import MrWarehousePage from 'views/MrWarehousePage'
import BestPage from 'views/BestPage'
import VicInvoicePage from 'views/VicInvoicePage'
import ReportStaffSalesList from 'views/ReportStaffSalesList'
import ReportStaffSalesList2 from 'views/ReportStaffSalesList2'
import ReportDealerStockList from 'views/ReportDealerStockList'
import ReportDealerStockList2 from 'views/ReportDealerStockList2'
import ReportDealerSalesList from 'views/ReportDealerSalesList'
import ReportDealerSalesList2 from 'views/ReportDealerSalesList2'
import ReportDailyList from 'views/ReportDailyList'
import ReportWarehouseList from 'views/ReportWarehouseList'
import ReportRepairList from 'views/ReportRepairList'
import ReportRefundList from 'views/ReportRefundList'
import ReportRefundList2 from 'views/ReportRefundList2'
import SdjStockDiffList from 'views/SdjStockDiffList'
import SdjPurchaseDiffList from 'views/SdjPurchaseDiffList'
import SdjDispatchPendingList from 'views/SdjDispatchPendingList'
import PxmartErrorList from 'views/PxmartErrorList'
import CarrefourErrorList from 'views/CarrefourErrorList'
import ReportCollectList from 'views/ReportCollectList'

const component = (Component, profile) => (props) =>
  <Component {...props} profile={profile} />

export const RootRoute = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route path="/" component={MainLayout} />
  </Switch>
)

export const AppRoute = () => (
  <Switch>
    <Route exact path="/dashboard" component={Dashboard} />
    <Route exact path="/account" component={AccountPage} />
    <Route exact path="/role" component={RoleList} />
    <Route path="/role/add" render={component(RolePage, 'add')} />
    <Route path="/role/:id/edit" render={component(RolePage, 'edit')} />
    <Route exact path="/staff" component={StaffList} />
    <Route path="/staff/:id/role/edit" component={StaffRolePage} />
    <Route exact path="/warehouseGroup" component={WarehouseGroupList} />
    <Route exact path="/warehouse" component={WarehouseList} />
    <Route exact path="/dealer" component={DealerList} />
    <Route path="/dealer/add" render={component(DealerPage, 'add')} />
    <Route path="/dealer/:id/edit" render={component(DealerPage, 'edit')} />
    <Route exact path="/processConfig" component={ProcessConfigList} />
    <Route
      path="/processConfig/add"
      render={component(ProcessConfigPage, 'add')}
    />
    <Route
      path="/processConfig/:id/edit"
      render={component(ProcessConfigPage, 'edit')}
    />
    <Route exact path="/dealer/org" component={DealerOrgList} />
    <Route exact path="/sales-dept" component={SalesDeptList} />
    <Route exact path="/company" component={CompanyList} />
    <Route exact path="/category" component={CategoryList} />
    <Route exact path="/category/:id" component={CategoryList} />
    <Route path="/category/:id/product" component={ProductList} />
    <Route path="/product/code" component={ProductComboCodeList} />
    <Route exact path="/purchase" component={PurchaseList} />
    <Route path="/purchase/add" render={component(PurchasePage, 'add')} />
    <Route path="/purchase/:id/edit" render={component(PurchasePage, 'edit')} />
    <Route path="/purchase/:id/view" render={component(PurchasePage, 'view')} />
    <Route path="/purchase/import" component={PurchaseImportPage} />
    <Route exact path="/adjust" component={AdjustList} />
    <Route path="/adjust/add" render={component(AdjustPage, 'add')} />
    <Route path="/adjust/:id/edit" render={component(AdjustPage, 'edit')} />
    <Route path="/adjust/:id/view" render={component(AdjustPage, 'view')} />
    <Route path="/adjust/import" component={AdjustImportPage} />
    <Route exact path="/move" component={MoveList} />
    <Route path="/move/add" render={component(MovePage, 'add')} />
    <Route path="/move/:id/edit" render={component(MovePage, 'edit')} />
    <Route path="/move/:id/view" render={component(MovePage, 'view')} />
    <Route
      path="/transfer-out/add"
      render={component(TransferOutPage, 'add')}
    />
    <Route
      path="/transfer-out/:id/edit"
      render={component(TransferOutPage, 'edit')}
    />
    <Route
      path="/transfer-out/:id/view"
      render={component(TransferOutPage, 'view')}
    />
    <Route path="/transfer-in/add" render={component(TransferInPage, 'add')} />
    <Route
      path="/transfer-in/:id/edit"
      render={component(TransferInPage, 'edit')}
    />
    <Route
      path="/transfer-in/:id/view"
      render={component(TransferInPage, 'view')}
    />
    <Route exact path="/lock" component={LockList} />
    <Route path="/lock/add" render={component(LockPage, 'add')} />
    <Route path="/lock/:id/edit" render={component(LockPage, 'edit')} />
    <Route path="/lock/:id/view" render={component(LockPage, 'view')} />
    <Route exact path="/reverse" component={ReverseList} />
    <Route path="/reverse/add" render={component(ReversePage, 'add')} />
    <Route path="/reverse/:id/edit" render={component(ReversePage, 'edit')} />
    <Route path="/reverse/:id/view" render={component(ReversePage, 'view')} />
    <Route exact path="/dispatch" component={DispatchList} />
    <Route path="/dispatch/add" render={component(DispatchPage, 'add')} />
    <Route path="/dispatch/:id/edit" render={component(DispatchPage, 'edit')} />
    <Route path="/dispatch/:id/view" render={component(DispatchPage, 'view')} />
    <Route path="/dispatch/import" component={DispatchImportPage} />
    <Route path="/dispatch/importSerial" component={DispatchImportSerialPage} />
    <Route exact path="/sell" component={SellList} />
    <Route path="/sell/add" render={component(SellPage, 'add')} />
    <Route path="/sell/:id/edit" render={component(SellPage, 'edit')} />
    <Route path="/sell/:id/view" render={component(SellPage, 'view')} />
    <Route exact path="/refund" component={RefundList} />
    <Route path="/refund/add" render={component(RefundPage, 'add')} />
    <Route path="/refund/:id/edit" render={component(RefundPage, 'edit')} />
    <Route path="/refund/:id/view" render={component(RefundPage, 'view')} />
    <Route path="/refund/import" component={RefundImportPage} />
    <Route exact path="/process" component={ProcessList} />
    <Route path="/process/add" render={component(ProcessPage, 'add')} />
    <Route path="/process/:id/edit" render={component(ProcessPage, 'edit')} />
    <Route path="/process/:id/view" render={component(ProcessPage, 'view')} />
    <Route exact path="/defer" component={DeferList} />
    <Route path="/defer/import" component={DeferImportPage} />
    <Route path="/defer/add" render={component(DeferPage, 'add')} />
    <Route path="/defer/:id/edit" render={component(DeferPage, 'edit')} />
    <Route path="/defer/:id/view" render={component(DeferPage, 'view')} />
    <Route exact path="/lend" component={LendList} />
    <Route path="/lend/add" render={component(LendPage, 'add')} />
    <Route path="/lend/:id/edit" render={component(LendPage, 'edit')} />
    <Route path="/lend/:id/view" render={component(LendPage, 'view')} />
    <Route exact path="/inventory/all" component={InventoryAllList} />
    <Route
      exact
      path="/inventory/warehouse"
      render={component(InventoryList, 'warehouse')}
    />
    <Route exact path="/inventory/dept" component={InventoryDeptList} />
    <Route
      exact
      path="/inventory/org"
      render={component(InventoryList, 'org')}
    />
    <Route
      exact
      path="/inventory/dealer"
      render={component(InventoryList, 'dealer')}
    />
    <Route exact path="/customer" component={CustomerList} />
    <Route path="/customer/add" render={component(CustomerPage, 'add')} />
    <Route path="/customer/:id/view" render={component(CustomerPage, 'view')} />
    <Route exact path="/plugin" component={PluginList} />
    <Route exact path="/plugin/sy/edit" component={SyPage} />
    <Route exact path="/plugin/sdj/edit" component={SdjPage} />
    <Route exact path="/plugin/sdj/stock-diff" component={SdjStockDiffList} />
    <Route
      exact
      path="/plugin/sdj/purchase-diff"
      component={SdjPurchaseDiffList}
    />
    <Route
      exact
      path="/plugin/sdj/dispatch-pending"
      component={SdjDispatchPendingList}
    />
    <Route exact path="/plugin/pxmart/error" component={PxmartErrorList} />
    <Route path="/plugin/pxmart/edit" component={PxmartPage} />
    <Route
      exact
      path="/plugin/carrefour/error"
      component={CarrefourErrorList}
    />
    <Route path="/plugin/carrefour/edit" component={CarrefourPage} />
    <Route path="/plugin/mrWarehouse/edit" component={MrWarehousePage} />
    <Route path="/plugin/best/edit" component={BestPage} />
    <Route path="/plugin/vicInvoice/edit" component={VicInvoicePage} />
    <Route exact path="/transport" component={TransportList} />
    <Route path="/transport/importFee" component={TransportImportFeePage} />
    <Route path="/transport/add" render={component(TransportPage, 'add')} />
    <Route
      path="/transport/:id/edit"
      render={component(TransportPage, 'edit')}
    />
    <Route
      path="/transport/:id/view"
      render={component(TransportPage, 'view')}
    />
    <Route exact path="/repair" component={RepairList} />
    <Route path="/repair/add" render={component(RepairPage, 'add')} />
    <Route path="/repair/:id/edit" render={component(RepairPage, 'edit')} />
    <Route path="/repair/:id/view" render={component(RepairPage, 'view')} />
    <Route exact path="/repair/type" component={RepairTypeList} />
    <Route exact path="/repair/tag" component={RepairTagList} />
    <Route exact path="/repair/remark" component={RepairRemarkList} />
    <Route exact path="/repair/feedback" component={RepairFeedbackList} />
    <Route
      path="/repair/feedback/:id/edit"
      render={component(RepairFeedbackPage, 'edit')}
    />
    <Route
      path="/repair/feedback/:id/view"
      render={component(RepairFeedbackPage, 'view')}
    />
    <Route exact path="/repair/report" component={RepairReportList} />
    <Route path="/repair/report/:id" component={RepairReportPage} />
    <Route exact path="/report/staff/sales" component={ReportStaffSalesList} />
    <Route
      exact
      path="/report/staff/sales2"
      component={ReportStaffSalesList2}
    />
    <Route
      exact
      path="/report/dealer/dispatch"
      component={ReportDealerStockList}
    />
    <Route
      exact
      path="/report/dealer/dispatch2"
      component={ReportDealerStockList2}
    />
    <Route
      exact
      path="/report/dealer/sales"
      component={ReportDealerSalesList}
    />
    <Route
      exact
      path="/report/dealer/sales2"
      component={ReportDealerSalesList2}
    />
    <Route exact path="/report/daily" component={ReportDailyList} />
    <Route exact path="/report/warehouse" component={ReportWarehouseList} />
    <Route exact path="/report/repair" component={ReportRepairList} />
    <Route exact path="/report/refund" component={ReportRefundList} />
    <Route exact path="/report/refund2" component={ReportRefundList2} />
    <Route path="/report/restock" render={component(ReportRestockList)} />
    <Route path="/report/collect" render={component(ReportCollectList)} />
    <Redirect from="/" to="/dashboard" />
  </Switch>
)
