export default {
  'error.defer.insufficientQuantity': '延出數量不足',
  'error.defer.hasDispatchItems': '延出商品已有出貨紀錄',
  'defer.title.list': '延出目錄',
  'defer.title.add': '新增延出單',
  'defer.title.addItem': '新增延出商品',
  'defer.title.addPayment': '新增付款紀錄',
  'defer.title.addRevert': '新增銷退紀錄',
  'defer.title.edit': '修改延出單',
  'defer.title.view': '延出單詳情',
  'defer.title.deletePayment': '刪除付款紀錄',
  'defer.title.deleteRevert': '刪除銷退紀錄',
  'defer.title.print': '延出單 - {ticketId}',
  'defer.title.import': '匯入',
  'defer.message.template': '匯入範本',
  'defer.message.deletePayment': '確認刪除付款紀錄?',
  'defer.message.deleteRevert': '確認刪除銷退紀錄?',
  'defer.success.itemLocked': '成功更新項目鎖定狀態',
  'defer.section.product': '延出項目',
  'defer.section.image': '相關圖示',
  'defer.section.payment': '付款紀錄',
  'defer.section.revert': '銷退紀錄',
  'defer.section.dispatch': '出貨紀錄',
  'defer.section.refund': '退貨紀錄',
  'defer.btn.lockItem': '鎖定項目',
  'defer.btn.unlockItem': '解鎖項目',
  'defer.field.importFile': '匯入檔案',
  'defer.title.deleteImportItem': '刪除匯入品項',
  'defer.message.deleteImportItem': '確認刪除匯入品項？',
  'defer.field.source': '來源',
  'defer.field.id': '單號',
  'defer.field.parentId': '關聯單號',
  'defer.field.warehouse': '倉庫',
  'defer.field.ticketNo': '廠商單號',
  'defer.field.transDate': '銷貨日期',
  'defer.field.revertDate': '銷退日期',
  'defer.field.paymentDate': '付款日期',
  'defer.field.salesId': '業務人員',
  'defer.field.backupId': '帳務人員',
  'defer.field.memo': '業務備註',
  'defer.field.itemMemo': '商品備註',
  'defer.field.dealerId': '經銷商',
  'defer.field.dealerCode': '廠商代碼',
  'defer.field.dealerAddress': '公司地址',
  'defer.field.billingId': '歸帳',
  'defer.field.recipientId': '收貨處',
  'defer.field.recipientContact': '聯絡人',
  'defer.field.recipientAddress': '收件地址',
  'defer.field.recipientEmail': 'Email',
  'defer.field.recipientPhone': '電話',
  'defer.field.recipientCellphone': '手機',
  'defer.field.contractProduct': '切口商品',
  'defer.field.isContract': '切口',
  'defer.field.isGift': '贈品',
  'defer.field.isDemo': '展示/福利機',
  'defer.field.balance': '未出數量',
  'defer.field.receiptType': '發票類型',
  'defer.field.receiptNo': '發票號碼',
  'defer.field.revertQuantity': '銷退量',
  'defer.field.orderAmount': '訂單金額',
  'defer.field.revertAmount': '銷退金額',
  'defer.field.paymentPaid': '已付金額',
  'defer.field.paymentBalance': '剩餘金額',
  'defer.field.itemTransDate': '商品日期',
  'defer.field.lastDispatchDate': '最後出貨日',
  'defer.field.lastRefundDate': '最後退貨日',
  'defer.field.lockItem': '鎖定項目',
  'defer.paymentType.DEPOSIT': '訂金',
  'defer.paymentType.PARTIAL': '部份款',
  'defer.paymentType.FINAL': '尾款',
  'defer.paymentType.REFUND': '退款',
  'defer.paymentType.REVERT': '銷退',
  'defer.receiptType.DOUBLE': '二聯',
  'defer.receiptType.TRIPLE': '三聯',
  'defer.status.ACTIVE': '已完成',
  'defer.status.INACTIVE': '已刪除',
  'defer.status.PENDING': '待出貨',
  'defer.status.REVERTED': '全銷退',
  'defer.itemStatus.ACTIVE': '正常',
  'defer.itemStatus.INACTIVE': '刪除',
  'defer.btn.sendEmail': '發送Email通知收貨處',
  'defer.title.sendEmail': '延出單通知',
  'defer.message.sendEmail': '確認發送延出單通知至{email}',
  'defer.sendEmail.success': '成功發送延出單通知',
  'defer.source.vicInvoice': '慎康訂購單系統',
}
