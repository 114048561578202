import React from 'react'
import { CONFIRM_SET } from 'constants/actionType'
import { Button, StatusChip } from 'components/core'
import { request } from 'utilities/request'
import { MdDelete, MdEdit } from 'react-icons/md'
import { renderMessage } from 'utilities/app'
import { getStatusColor } from 'constants/status'

export const columns = ({ session, app, setState, action }) => [
  {
    id: 'name',
    label: 'company.field.name',
  },
  {
    id: 'ein',
    label: 'company.field.ein',
    render: ({ row }) => row.extra.ein,
  },
  {
    id: 'type',
    label: 'company.field.type',
    render: ({ row }) => renderMessage(`company.type.${row.extra.type}`),
  },
  {
    id: 'status',
    label: 'field.status',
    renderHtml: ({ row }) => (
      <StatusChip
        label={`dealer.status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        <Button
          mr={2}
          variant="icon"
          icon={<MdEdit />}
          onClick={() => action.handleOpen(row)}
        />
        <Button
          variant="icon"
          icon={<MdDelete />}
          onClick={() => {
            session.dispatch({
              type: CONFIRM_SET,
              item: {
                open: true,
                title: 'company.title.delete',
                text: 'company.message.delete',
                onSubmit: handleDelete({
                  setState,
                  session,
                  app,
                  id: row.id,
                  action,
                }),
              },
            })
          }}
        />
      </>
    ),
  },
]

export const handlers = ({ setState, session, app }) => ({
  handleLoad: async () => {
    const data = await getData({ session, app })
    setState(data)
  },
})

async function getData({ session, app }) {
  const variables = {
    input: {
      type: ['COMPANY'],
      status: ['ACTIVE', 'INACTIVE'],
      orderBy: [{ key: 'name' }],
    },
  }
  const query = `
    query($input: LocationQueryInput) {
      locations(input: $input) {
        id
        name
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const locations = data.locations
  locations.sort((a, b) => {
    const aType = a.extra.type
    const bType = b.extra.type
    if (aType !== bType) return aType > bType ? 1 : -1

    const aName = a.extra.name
    const bName = b.extra.name
    return aName >= bName ? 1 : -1
  })
  return locations
}

const handleDelete =
  ({ session, app, id, action }) =>
  async () => {
    const variables = { id }
    const query = `
    mutation($id: ID!) {
      deleteCompany(id: $id)
    }
  `
    const [ok] = await request({ query, variables }, { session, app })
    if (ok) {
      action.handleLoad()
      action.handleClose()
    }

    return ok
  }
