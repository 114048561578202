import React from 'react'
import { TextInput } from 'components/form'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'

export const initialState = (value = {}) => ({
  index: value.index,
  ...initializeState({
    ticketId: value.ticketId || '',
    fee: value.fee || '',
  }),
})

const validation = {
  ticketId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState, data, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    ticketId: (
      <TextInput
        id="ticketId"
        label="transport.field.id"
        placeholder="transport.field.id"
        value={state.ticketId}
        onChange={onTextChange('ticketId')}
        errMsg={state.__error__.ticketId}
      />
    ),
    fee: (
      <TextInput
        id="fee"
        label="transport.field.fee"
        placeholder="transport.field.fee"
        value={state.fee}
        onChange={onTextChange('fee')}
        errMsg={state.__error__.fee}
      />
    ),
  }
}

export const handlers = ({ state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return
    action.editTicketItem(state)
    action.handleClose()
  },
})
