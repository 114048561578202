export default {
  'transport.title.list': '派工目錄',
  'transport.title.add': '新增派工單',
  'transport.title.edit': '修改派工單',
  'transport.title.view': '派工單詳情',
  'transport.title.print': '派工單 - {ticketId}',
  'transport.title.addItem': '新增派工商品',
  'transport.title.editItem': '修改派工商品',
  'transport.title.listItems': '派工商品詳情',
  'transport.title.importFee': '匯入費用',
  'transport.message.feeTemplate': '派工費用匯入範本',
  'transport.section.product': '商品',
  'transport.message.template': '調整單匯入範本',
  'transport.field.importFile': '匯入檔案',
  'transport.field.id': '單號',
  'transport.field.parentId': '關聯單號',
  'transport.field.shippingId': '物流',
  'transport.field.quote': '報價（未稅）',
  'transport.field.salesId': '業務人員',
  'transport.field.ticketDate': '下單日期',
  'transport.field.transDate': '取貨日期',
  'transport.field.pickupDealerId': '取貨經銷商',
  'transport.field.pickupRecipientId': '取貨處',
  'transport.field.pickupContact': '取貨聯絡人',
  'transport.field.pickupCellphone': '取貨手機',
  'transport.field.pickupPhone': '取貨電話',
  'transport.field.pickupAddress': '取貨地址',
  'transport.field.pickupMemo': '取貨備註',
  'transport.field.deliverDealerId': '送貨經銷商',
  'transport.field.deliverRecipientId': '送貨處',
  'transport.field.deliverDate': '送貨日期',
  'transport.field.deliverContact': '送貨聯絡人',
  'transport.field.deliverPhone': '送貨電話',
  'transport.field.deliverCellphone': '送貨手機',
  'transport.field.deliverAddress': '送貨地址',
  'transport.field.deliverMemo': '送貨備註',
  'transport.field.fee': '費用',
  'transport.field.itemMemo': '商品備註',
}
