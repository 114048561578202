import React from 'react'
import { request } from 'utilities/request'
import { textFilter, dateFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { Link } from 'components/core'

export const filters = [
  {
    id: 'transDate',
    label: 'field.date',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'locationName',
    label: 'report.field.dealer',
    input: textFilter,
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  // {
  //   id: 'type',
  //   label: 'refund.field.type',
  //   input: (props) =>
  //     selectFilter({
  //       ...props,
  //       options: getTypes(props.message),
  //     }),
  // },
]

// function getTypes(message) {
//   return [
//     {
//       value: 'RETURN_WAREHOUSE',
//       label: message({ id: `refund.type.RETURN_WAREHOUSE` }),
//     },
//     { value: 'DISCARD', label: message({ id: `refund.type.DISCARD` }) },
//     {
//       value: 'RECEIVE_LESS',
//       label: message({ id: `refund.type.RECEIVE_LESS` }),
//     },
//   ]
// }

export const columns = ({ filterValues, handleOpen }) => [
  {
    id: 'fromLocationName',
    label: 'report.field.dealer',
    format: ['html', 'excel'],
  },
  {
    id: 'productVariantName',
    label: 'field.productName',
    format: ['html', 'excel'],
  },
  {
    id: 'sku',
    label: 'field.sku',
    format: ['excel'],
  },
  {
    id: 'receiveTotal',
    label: 'report.field.recieveTotal',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            transDate: transDate?.value,
            locationId: row.fromLocationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.receiveTotal}
      </Link>
    ),
  },
  {
    id: 'returnTotal',
    label: 'report.field.returnTotal',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            refundType: 'RETURN_WAREHOUSE',
            transDate: transDate?.value,
            locationId: row.fromLocationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.returnTotal}
      </Link>
    ),
  },
  {
    id: 'discardNoRecycleTotal',
    label: 'report.field.discardNoRecycleTotal',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            refundType: 'DISCARD_NO_RECYCLE',
            transDate: transDate?.value,
            locationId: row.fromLocationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.discardNoRecycleTotal}
      </Link>
    ),
  },
  {
    id: 'discardTotal',
    label: 'report.field.discardTotal',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            refundType: 'DISCARD',
            transDate: transDate?.value,
            locationId: row.fromLocationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.discardTotal}
      </Link>
    ),
  },
  {
    id: 'discardExpiredTotal',
    label: 'report.field.discardExpiredTotal',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            refundType: 'DISCARD_EXPIRED',
            transDate: transDate?.value,
            locationId: row.fromLocationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.discardExpiredTotal}
      </Link>
    ),
  },
  {
    id: 'discardOpenedTotal',
    label: 'report.field.discardOpenedTotal',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            refundType: 'DISCARD_OPENED',
            transDate: transDate?.value,
            locationId: row.fromLocationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.discardOpenedTotal}
      </Link>
    ),
  },
  {
    id: 'receiveLessTotal',
    label: 'report.field.receiveLessTotal',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            refundType: 'RECEIVE_LESS',
            transDate: transDate?.value,
            locationId: row.fromLocationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.receiveLessTotal}
      </Link>
    ),
  },
  {
    id: 'adjustTotal',
    label: 'report.field.refundAdjustQuantity',
    format: ['html', 'excel'],
    align: 'right',
    renderHtml: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() => {
          const transDate = filterValues.find(({ id }) => id === 'transDate')
          handleOpen({
            refundType: 'ADJUST',
            transDate: transDate?.value,
            locationId: row.fromLocationId,
            productVariantId: row.productVariantId,
          })
        }}
      >
        {row.adjustTotal}
      </Link>
    ),
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'report.title.refund'
    const cols = columns({ filterValues }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  if (filterValues.length === 0) return {}

  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: RefundReportInput) {
      refundReportCount(input: $input)
      refundReports(input: $input) {
        fromLocationName
        fromLocationId
        productVariantName
        productVariantId
        receiveTotal
        returnTotal
        discardNoRecycleTotal
        discardTotal
        discardExpiredTotal
        discardOpenedTotal
        receiveLessTotal
        adjustTotal
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.refundReports,
    pagination: getPagination(page, countPerPage, data.refundReportCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: RefundReportInput) {
      refundReports(input: $input) {
        fromLocationName
        productVariantName
        sku
        receiveTotal
        returnTotal
        discardNoRecycleTotal
        discardTotal
        discardExpiredTotal
        discardOpenedTotal
        receiveLessTotal
        adjustTotal
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.refundReports
}

function getDataInput({ filterValues, pagination }) {
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const locationName = filterValues.find(({ id }) => id === 'locationName')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const input = {
    transDate: transDate?.value,
    locationName: locationName?.value,
    productVariantName: productName?.value,
    orderBy: [{ key: 'fromLocationName' }, { key: 'productVariantName' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}
