import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { useIntl } from 'react-intl'
import { Center, Drawer, Button, Modal, Text } from 'components/core'
import { initialState, fields, labels, handlers } from 'models/productDrawer'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import { Box } from 'reflexbox'

export default ({ value, open, action }) => {
  const readonly = value.readonly
  const profile = readonly ? 'view' : value?.id ? 'edit' : 'add'
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [data, setData] = useState([])
  const [state, setState] = useState(initialState(value, data, message))
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { handleLoad, handleSubmit } = handlers({
    app,
    session,
    state,
    setState,
    setData,
    setConfirmOpen,
    action,
  })
  const form = readonly
    ? labels({ state, message })
    : fields({
        app,
        session,
        state,
        setState,
        profile,
        message,
        action: { setConfirmOpen },
      })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setState(initialState(value, data, message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.id, data])

  return (
    <>
      <Drawer
        onClose={action.handleClose}
        title={`product.title.${profile}`}
        open={open}
        as="form"
        onSubmit={handleSubmit}
      >
        {form.categoryId}
        {form.name}
        {form.sku}
        {!state.id && (
          <Text color="error.1" fontSize={1}>
            *為配合會計系統請設定在20字元內
          </Text>
        )}
        {form.barcode}
        {form.code}
        {form.postedPrice}
        {form.price}
        {form.boxUnit}
        {form.boxLength}
        {form.boxWidth}
        {form.boxHeight}
        {form.boxWeight}
        {form.expireDate}
        {form.memo}
        {form.vendor}
        {form.image}
        {form.isActive}
        <Center mt={3}>
          <Button
            mr={2}
            variant="secondary"
            text="btn.close"
            onClick={action.handleClose}
          />
          {state.isActive || !state.hasBalance ? (
            <Button
              show={!readonly}
              type="submit"
              variant="primary"
              text="btn.submit"
            />
          ) : (
            <Button
              show={!readonly}
              variant="primary"
              text="btn.submit"
              onClick={() => {
                setConfirmOpen(true)
              }}
            />
          )}
        </Center>
      </Drawer>
      <Modal
        headerProps={{ display: 'flex', variant: 'warning' }}
        title="product.title.inactivate"
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        width={300}
      >
        <Box mt={4}>{message({ id: 'product.message.inactivate' })}</Box>
        <Center mt={4}>
          <Button
            mr={2}
            variant="secondary"
            onClick={() => setConfirmOpen(false)}
            text="btn.cancel"
          />
          <Button
            variant="primary"
            onClick={useCallbackOnce(handleSubmit)}
            text="btn.confirm"
          />
        </Center>
      </Modal>
    </>
  )
}
