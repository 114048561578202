import React from 'react'
import { ALERT_ADD, CONFIRM_SET } from 'constants/actionType'
import { Button, Center, Text, Icon } from 'components/core'
import { MdDelete, MdEdit, MdErrorOutline } from 'react-icons/md'
import { request } from 'utilities/request'
import { showAddress, showDate, validateRows } from 'utilities/form'
import { getTag } from 'actions/token'

export const initialState = (value) => ({
  deferId: value.deferId,
  dealers: value.dealers,
  recipients: value.recipients,
  warehouseGroupId: value.warehouseGroupId,
  warehouseId: value.warehouseId,
  ticketItems: value.ticketItems || [],
  errorItems: [],
})

const validation = {
  ticketNo: [
    {
      type: 'required',
      message: {
        id: 'error.requiredField',
        texts: { name: 'field.ticketNo' },
      },
    },
  ],
  dealerId: [
    {
      type: 'required',
      message: { id: 'error.requiredField', texts: { name: 'field.dealer' } },
    },
  ],
  recipientId: [
    {
      type: 'required',
      message: { id: 'error.requiredField', texts: { name: 'field.storage' } },
    },
  ],
  recipientContact: [
    {
      type: 'required',
      message: { id: 'error.requiredField', texts: { name: 'field.contact' } },
    },
  ],
  recipientAddress: [
    {
      type: 'required',
      message: { id: 'error.requiredField', texts: { name: 'shipAddress' } },
    },
  ],
  salesId: [
    {
      type: 'required',
      message: { id: 'error.requiredField', texts: { name: 'field.sales' } },
    },
  ],
  warehouseId: [
    {
      type: 'required',
      message: {
        id: 'error.requiredField',
        texts: { name: 'field.warehouse' },
      },
    },
  ],
  productVariantId: [
    {
      type: 'required',
      message: {
        id: 'error.requiredField',
        texts: { name: 'field.productName' },
      },
    },
  ],
  quantity: [
    {
      type: 'required',
      message: {
        id: 'error.requiredField',
        texts: { name: 'field.quantity' },
      },
    },
  ],
}

export const columns = ({ session, state, setState, message, action }) => {
  const renderText = (id, label, align = 'left') => ({
    id,
    label,
    align,
    cellProps: { py: 2 },
    render: ({ row }) => <Text sx={{ whiteSpace: 'nowrap' }}>{row[id]}</Text>,
  })

  const renderSelect = (id, label) => ({
    id,
    label,
    cellProps: { py: 2 },
    render: ({ row }) => (
      <Text sx={{ whiteSpace: 'nowrap' }}>{row[id]?.label}</Text>
    ),
  })

  const renderMultiSelect = (id, label) => ({
    id,
    label,
    cellProps: { py: 2 },
    render: ({ row }) => (
      <Text sx={{ whiteSpace: 'nowrap' }}>
        {row[id]?.map(({ label }) => label).join(', ')}
      </Text>
    ),
  })

  const renderBoolean = (id, label) => ({
    id,
    label,
    cellProps: { py: 2 },
    render: ({ row }) => (
      <Text sx={{ whiteSpace: 'nowrap' }}>{row[id] ? 'Y' : 'N'}</Text>
    ),
  })

  const renderAddress = (id, label) => ({
    id,
    label,
    cellProps: { py: 2 },
    render: ({ row }) => (
      <Text sx={{ whiteSpace: 'nowrap' }}>{showAddress(row[id], message)}</Text>
    ),
  })

  return [
    renderText('ticketNo', 'dispatch.field.ticketNo'),
    renderBoolean('isUrgent', 'dispatch.field.isUrgent'),
    renderBoolean('isDirect', 'dispatch.field.isDirect'),
    renderText('transDate', 'dispatch.field.transDate'),
    renderText('arriveDate', 'dispatch.field.arriveDate'),
    renderSelect('arrivePeriod', 'dispatch.field.arrivePeriod'),
    renderSelect('billingId', 'dispatch.field.billingId'),
    renderSelect('dealerId', 'dispatch.field.dealerId'),
    renderText('dealerEIN', 'dispatch.field.dealerEIN'),
    renderText('dealerAddress', 'dispatch.field.dealerAddress'),
    renderSelect('recipientId', 'dispatch.field.recipientId'),
    renderText('recipientContact', 'dispatch.field.recipientContact'),
    renderAddress('recipientAddress', 'dispatch.field.recipientAddress'),
    renderText('recipientPhone', 'dispatch.field.recipientPhone'),
    renderText('recipientCellphone', 'dispatch.field.recipientCellphone'),
    renderText('recipientEmail', 'dispatch.field.recipientEmail'),
    renderText('receiptNo', 'dispatch.field.receiptNo'),
    renderSelect('shippingId', 'dispatch.field.shippingId'),
    renderSelect('warehouseGroupId', 'dispatch.field.warehouseGroupId'),
    renderSelect('warehouseId', 'dispatch.field.warehouseId'),
    renderMultiSelect('salesId', 'dispatch.field.salesId'),
    renderText('memo', 'dispatch.field.memo'),
    renderSelect('handleType', 'dispatch.field.handleType'),
    renderText('handleNo', 'dispatch.field.handleNo'),
    renderSelect('productVariantId', 'field.productName'),
    renderBoolean('isGift', 'dispatch.field.isGift'),
    renderText('quantity', 'dispatch.field.quantity', 'right'),
    renderText('price', 'dispatch.field.price', 'right'),
    renderText('expireAt', 'dispatch.field.expireAt'),
    renderText('batchNo', 'dispatch.field.batchNo'),
    renderText('itemMemo', 'dispatch.field.itemMemo'),
    {
      id: 'actions',
      align: 'right',
      headProps: {
        width: '100px',
        height: '56px',
        bg: 'light.0',
        sx: {
          position: 'sticky',
          top: 0,
          right: 0,
          zIndex: 1,
          borderLeftWidth: '1px',
          borderLeftStyle: 'solid',
          borderLeftColor: 'light.3',
        },
      },
      cellProps: {
        py: 2,
        width: '100px',
        bg: 'light.0',
        sx: {
          position: 'sticky',
          right: 0,
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'light.3',
          borderLeftWidth: '1px',
          borderLeftStyle: 'solid',
          borderLeftColor: 'light.3',
        },
      },
      render: ({ row, index }) => {
        const err = state.errorItems.find(
          ({ ticketNo }) => ticketNo === row.ticketNo,
        )
        return (
          <Center>
            <Icon
              color={err ? 'error.1' : 'light.3'}
              px={1}
              tooltip={err?.message}
              tooltipProps={{ place: 'bottom', type: 'error' }}
              value={<MdErrorOutline />}
            />
            <Button
              px={1}
              variant="icon"
              icon={<MdEdit />}
              onClick={() => action.handleOpen({ ...row, index })}
            />
            <Button
              variant="icon"
              icon={<MdDelete />}
              onClick={() => {
                const item = {
                  open: true,
                  title: { id: 'dispatch.title.deleteImportItem' },
                  text: { id: 'dispatch.message.deleteImportItem' },
                  onSubmit: () => {
                    const ticketItems = [...state.ticketItems]
                    ticketItems.splice(index, 1)
                    setState({ ...state, ticketItems })
                    return true
                  },
                }
                session.dispatch({ type: CONFIRM_SET, item })
              }}
            />
          </Center>
        )
      },
    },
  ]
}

export const handlers = ({
  app,
  session,
  state,
  setState,
  history,
  setDisabled,
  setProgress,
}) => ({
  handleLoad: async () => {
    // const tag = await getTag({ session, app })
    // setTag(tag)
  },
  handleSubmit: async (event) => {
    event.preventDefault()

    setDisabled(true)

    const { ticketItems } = state
    const inputErrors = validateRows(ticketItems, validation)
    if (inputErrors.length > 0) {
      setState({ ...state, errorItems: inputErrors })
      setDisabled(false)
      return
    }

    const tickets = getSubmitInput(state)
    const ticketCount = tickets.length
    const errorItems = []
    let successCount = 0

    try {
      for (let i = 0; i < ticketCount; i++) {
        const ticket = tickets[i]
        const { ticketNo } = ticket
        const tag = await getTag({ session, app })
        const [ok, err] = await addDispatchTicket(app, ticket, tag)

        if (ok) {
          successCount++
        } else {
          errorItems.push({ ticketNo, message: err[0].message })
        }

        setProgress(Math.floor((i / ticketCount) * 100))
      }

      if (successCount > 0) {
        session.dispatch({
          type: ALERT_ADD,
          item: {
            type: 'success',
            message: {
              id: 'submit.batch.success',
              values: { count: successCount },
              texts: { name: 'module.dispatch' },
            },
          },
        })
      }

      if (errorItems.length === 0) {
        history.push('/dispatch')
        return
      }

      const idSet = new Set(errorItems.map(({ ticketNo }) => ticketNo))
      setState({
        ...state,
        ticketItems: ticketItems.filter(({ ticketNo }) => idSet.has(ticketNo)),
        errorItems,
      })
    } finally {
      setDisabled(false)
      setProgress(null)
    }
  },
  // handleSubmit: async (event) => {
  //   event.preventDefault()

  //   try {
  //     setDisabled(true)

  //     const { ticketItems } = state
  //     const inputErrors = validateRows(ticketItems, validation)
  //     if (inputErrors.length > 0) {
  //       setState({ ...state, errorItems: inputErrors })
  //       return
  //     }

  //     const result = await addDispatchTickets(app, session, state, tag)
  //     if (!result) return

  //     const errorItems = result.filter(({ ok }) => !ok)
  //     const successCount = result.reduce((count, { ok }) => {
  //       if (ok) count++
  //       return count
  //     }, 0)

  //     if (successCount > 0) {
  //       session.dispatch({
  //         type: ALERT_ADD,
  //         item: {
  //           type: 'success',
  //           message: {
  //             id: 'submit.batch.success',
  //             values: { count: successCount },
  //             texts: { name: 'module.dispatch' },
  //           },
  //         },
  //       })
  //     }

  //     if (errorItems.length === 0) {
  //       history.push('/dispatch')
  //       return
  //     }

  //     const idSet = new Set(errorItems.map(({ ticketNo }) => ticketNo))
  //     setState({
  //       ...state,
  //       warehouseGroupId: state.warehouseGroupId,
  //       warehouseId: state.warehouseId,
  //       ticketItems: ticketItems.filter(({ ticketNo }) => idSet.has(ticketNo)),
  //       errorItems,
  //     })
  //   } finally {
  //     setDisabled(false)

  //     const tag = await getTag({ session, app })
  //     setTag(tag)
  //   }
  // },
  editTicketItem: ({ index, ...value }) => {
    const ticketItems = [...state.ticketItems]
    ticketItems.splice(index, 1, value)

    const errorItems = state.errorItems.filter(
      ({ ticketNo }) => ticketNo !== value.ticketNo,
    )

    setState({ ...state, ticketItems, errorItems })
  },
})

// async function addDispatchTickets(app, session, state, tag) {
//   const variables = { input: getSubmitInput(state) }
//   const query = `
//     mutation($input: [TicketInput]!) {
//       addDispatchTickets(input: $input) {
//         ok
//         message
//         ticketNo
//       }
//     }
//   `
//   const [ok, resp] = await request({ query, variables }, { app, session, tag })
//   if (!ok) return null

//   return resp.addDispatchTickets
// }

async function addDispatchTicket(app, input, tag) {
  const variables = { input }
  const query = `
    mutation($input: TicketInput!) {
      addDispatchTicket(input: $input)
    }
  `
  return request({ query, variables }, { app, tag })
}

function getSubmitInput(state) {
  const { dealers, recipients, warehouseId, ticketItems } = state
  const { warehouseGroupId } = state
  return ticketItems.reduce((result, item) => {
    let ticket = result.find(({ ticketNo }) => ticketNo === item.ticketNo)
    const dealerId = item.dealerId?.value
    const recipientId = item.recipientId?.value
    if (!ticket) {
      ticket = {
        parentId: state.deferId,
        ticketNo: `${item.ticketNo || ''}`,
        transDate: item.transDate,
        srcLocationId: warehouseId,
        toLocationId: dealerId,
        extra: {
          warehouseGroupId:
            item.warehouseGroupId.value || warehouseGroupId.value,
          warehouseGroupName:
            item.warehouseGroupId.label || warehouseGroupId.label,
          isUrgent: item.isUrgent,
          isDirect: item.isDirect,
          breakCombo: item.breakCombo,
          arriveDate: showDate(item.arriveDate),
          arrivePeriod: item.arrivePeriod?.value,
          dealerCode: getDealerCode(dealerId, dealers, recipientId, recipients),
          dealerEIN: item.dealerEIN,
          dealerAddress: item.dealerAddress,
          recipientId,
          recipientName: item.recipientId?.label,
          recipientContact: item.recipientContact,
          recipientAddress: item.recipientAddress,
          recipientPhone: item.recipientPhone,
          recipientCellphone: item.recipientCellphone,
          recipientEmail: item.recipientEmail,
          receiptNo: item.receiptNo,
          billingId: item.billingId?.value,
          billingName: item.billingId?.label,
          shippingId: item.shippingId?.value,
          shippingName: item.shippingId?.label,
          salesId: item.salesId?.map((item) => item.value),
          salesName: item.salesId?.map((item) => item.label),
          backupId: item.backupId?.map((item) => item.value),
          backupName: item.backupId?.map((item) => item.label),
          memo: item.memo,
          handleType: item.handleType?.value,
          handleNo: item.handleNo,
          warehouseMemo: item.warehouseMemo,
        },
        itemsToAdd: [],
      }
      result.push(ticket)
    }
    addTicketItem(ticket, item)
    return result
  }, [])
}

function getDealerCode(dealerId, dealers, recipientId, recipients) {
  const recipient = recipients.find((item) => item.id === recipientId)
  if (recipient?.extra.code) return recipient?.extra.code

  const dealer = dealers.find((item) => item.id === dealerId)
  return dealer?.extra.code ? dealer.extra.code : ''
}

function addTicketItem(ticket, item) {
  // const ticketItem = ticket.itemsToAdd.find(
  //   (i) => i.productVariantId === item.productVariantId?.value,
  // )

  // if (!ticketItem) {
  //   ticket.itemsToAdd.push(getTicketItem(item))
  //   return
  // }

  // const quantity = parseInt(item.quantity || 0)
  // ticketItem.quantity += quantity

  // if (item.expireAt || item.batchNo) {
  //   ticketItem.extra.batchItems.push({
  //     expireAt: item.expireAt,
  //     batchNo: item.batchNo,
  //     quantity,
  //   })
  // }
  ticket.itemsToAdd.push(getTicketItem(item, ticket.warehouseId))
}

function getTicketItem(item, warehouseId) {
  const quantity = parseInt(item.quantity || 0)
  let batchItems = null

  if (item.expireAt || item.batchNo) {
    batchItems = [{ expireAt: item.expireAt, batchNo: item.batchNo, quantity }]
  }

  return {
    productVariantId: item.productVariantId?.value,
    srcLocationId: item.warehouseId?.value || warehouseId,
    isGift: item.isGift,
    quantity,
    price: parseFloat(item.price || 0),
    extra: { batchItems, memo: item.itemMemo, parentItemId: item.parentItemId },
  }
}
