import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { ALERT_ADD } from 'constants/actionType'
import {
  initializeState,
  validateForm,
  getSelectOption,
  getDate,
  showDate,
  renderDateInput,
  renderDefinition,
  renderTextInput,
  renderSelectInput,
  renderTextAreaInput,
  renderTable,
} from 'utilities/form'
import { getDiff } from 'utilities/list'
import { request } from 'utilities/request'
import { filterLocations } from 'utilities/permission'
import { Flex } from 'reflexbox'
import { Text, Button, Center } from 'components/core'
import { MdEdit, MdDelete } from 'react-icons/md'
import { printHtml } from 'utilities/print'
import { getDisplayQuantity, handleDelete } from 'actions/ticket'

const moduleName = 'purchase'

export const initialState = (value = {}) => {
  let transDate = showDate(value.transDate)
  if (transDate === '3000-01-01') transDate = ''
  return {
    isReady: value.isReady || false,
    id: value.id,
    sync: value.sync || false,
    locked: value.extra?.locked || false,
    apiTransDate: value.extra?.apiTransDate || false,
    createdAt: showDate(value.createdAt),
    createdBy: value.createdBy,
    updatedAt: value.updatedAt,
    updatedBy: value.updatedBy,
    status: value.status || 'PENDING',
    hash: value.hash,
    oldTicketItems: value.oldTicketItems || [],
    ticketItems: value.ticketItems || [],
    srcCompanies: value.srcCompanies,
    billCompanies: value.billCompanies,
    warehouses: value.warehouses,
    products: value.products || [],
    ...initializeState({
      ticketNo: value.ticketNo || '',
      transDate,
      estimateDate: getDate(value.extra?.estimateDate),
      srcCompanyId: getSelectOption(
        value.srcCompanies,
        value.extra?.srcCompanyId,
      ),
      srcCompanyName: value.extra?.srcCompanyName || '',
      billCompanyId: getSelectOption(
        value.billCompanies,
        value.extra?.billCompanyId,
      ),
      billCompanyName: value.extra?.billCompanyName || '',
      rackNo: value.extra?.rackNo || '',
      sealNo: value.extra?.sealNo || '',
      memo: value.extra?.memo || '',
    }),
  }
}

const validation = {
  ticketItems: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ profile, format, state, setState, action }) => {
  const commonProps = {
    profile,
    format,
    state,
    setState,
    validation,
    moduleName,
  }
  const definition = (inputProps) =>
    renderDefinition({ ...commonProps, ...inputProps })
  const dateInput = (inputProps) =>
    renderDateInput({ ...commonProps, ...inputProps })
  const textInput = (inputProps) =>
    renderTextInput({ ...commonProps, ...inputProps })
  const textAreaInput = (inputProps) =>
    renderTextAreaInput({ ...commonProps, ...inputProps })
  const selectInput = (inputProps) =>
    renderSelectInput({ ...commonProps, ...inputProps })
  const tableInput = (inputProps) =>
    renderTable({ ...commonProps, ...inputProps })

  return {
    id: definition({ id: 'id', label: 'field.ticketId' }),
    createdAt: definition({ id: 'createdAt' }),
    transDate: state.apiTransDate
      ? definition({ id: 'transDate' })
      : dateInput({ id: 'transDate', role: 'lockPurchaseTicket' }),
    estimateDate: dateInput({ id: 'estimateDate', role: 'lockPurchaseTicket' }),
    ticketNo: textInput({ id: 'ticketNo' }),
    srcCompany: selectInput({
      id: 'srcCompanyId',
      valKey: 'srcCompanyName',
      options: state.srcCompanies,
      isSearchable: false,
      isClearable: false,
    }),
    billCompany: selectInput({
      id: 'billCompanyId',
      valKey: 'billCompanyName',
      options: state.billCompanies,
      isSearchable: false,
      isClearable: false,
    }),
    rackNo: textInput({ id: 'rackNo' }),
    sealNo: textInput({ id: 'sealNo' }),
    memo: textAreaInput({ id: 'memo' }),
    product: tableInput({
      id: 'ticketItems',
      columns: [
        {
          id: 'productVariantName',
          label: 'purchase.field.spu',
        },
        {
          id: 'toLocationName',
          label: 'purchase.field.warehouseId',
        },
        {
          id: 'invoicedQuantity',
          label: 'purchase.field.invoicedQuantity',
          align: 'right',
          render: ({ row }) => row.extra?.invoicedQuantity,
        },
        {
          id: 'purchasedQuantity',
          label: 'purchase.field.purchasedQuantity',
          align: 'right',
          render: ({ row }) => row.extra?.purchasedQuantity,
        },
        {
          id: 'quantity',
          label: 'purchase.field.quantity',
          align: 'right',
          renderHtml: ({ row }) => {
            const purchasedQuantity = row.extra?.purchasedQuantity
            return getDisplayQuantity(
              row.quantity,
              purchasedQuantity,
              purchasedQuantity,
            )
          },
        },
        {
          id: 'batchNo',
          label: 'purchase.field.batchNo',
          renderHtml: ({ row }) => (
            <Flex flexDirection="column" sx={{ whiteSpace: 'nowrap' }}>
              {row.extra.batchItems?.map(({ batchNo }, index) =>
                renderBatchItem(batchNo, index),
              )}
            </Flex>
          ),
          render: ({ row }) =>
            row.extra.batchItems
              ?.map(({ batchNo }) => batchNo || '\u2014')
              .join('<br/>'),
        },
        {
          id: 'expireAt',
          label: 'purchase.field.expireAt',
          renderHtml: ({ row }) => (
            <Flex flexDirection="column" sx={{ whiteSpace: 'nowrap' }}>
              {row.extra.batchItems?.map(({ expireAt }, index) =>
                renderBatchItem(showDate(expireAt), index),
              )}
            </Flex>
          ),
          render: ({ row }) =>
            row.extra.batchItems
              ?.map(({ expireAt }) => showDate(expireAt) || '\u2014')
              .join('<br/>'),
        },
        {
          id: 'lockQuantity',
          label: 'purchase.field.lockQuantity',
          align: 'right',
          renderHtml: ({ row }) => (
            <Flex flexDirection="column" justifyContent="flex-end">
              {row.extra.batchItems?.map(({ quantity }, index) =>
                renderBatchItem(quantity, index),
              )}
            </Flex>
          ),
          render: ({ row }) =>
            row.extra.batchItems?.map(({ quantity }) => quantity).join('<br/>'),
        },
        {
          id: 'actions',
          align: 'right',
          noWrap: true,
          show: ['add', 'edit'].includes(profile),
          renderHtml: ({ row, index }) => (
            <Center justifyContent="flex-end">
              <Button
                mr={2}
                variant="icon"
                icon={<MdEdit />}
                onClick={() => action.handleOpen({ ...row, index })}
              />
              <Button
                variant="icon"
                icon={<MdDelete />}
                onClick={() => {
                  const ticketItems = [...state.ticketItems]
                  ticketItems.splice(index, 1)
                  setState({ ...state, ticketItems })
                }}
              />
            </Center>
          ),
        },
      ],
    }),
  }
}

function renderBatchItem(value, index) {
  return (
    <Text
      key={index}
      mt={index === 0 ? 0 : 2}
      color={value ? 'dark.1' : 'light.3'}
    >
      {value ? value : '\u2014'}
    </Text>
  )
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  message,
  history,
  id,
  profile,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, id, profile })
    setState(initialState(data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editPurchase(state, app, session)
      : await addPurchase(state, app, session)
    if (!ok) return
    if (!id) id = data.addPurchaseTicket

    history.push(`/purchase/${id}/view`)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'save.success' },
    })
  },
  handleConfirm: async () => {
    const { hash } = state
    const [ok] = await confirmPurchase({ session, app, id, hash })
    if (!ok) return false

    const resp = await getData({ app, session, id })
    setState(initialState(resp))
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'submit.success' },
    })

    return true
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('purchase', { session, app, id, hash })
    if (!ok) return false

    history.push('/purchase')
    return true
  },
  addItem: (value) => {
    const ticketItems = cloneDeep(state.ticketItems)
    const { index } = value
    if (index === -1) {
      ticketItems.push(value)
    } else {
      ticketItems.splice(index, 1, value)
    }
    setState({ ...state, ticketItems })
  },
  handlePrint: () => {
    const { title, header, footer, content } = getPrintData({ state })
    printHtml({ title, header, footer, content, message })
  },
})

export function getPrintData({ state }) {
  const title = { id: 'purchase.title.print', values: { ticketId: state.id } }
  const header = {
    title,
    address: 'ticket.address',
    phone: 'ticket.phone',
  }
  const footer = [
    { label: 'ticket.manager', value: '__INPUT__' },
    { label: 'ticket.accounting', value: '__INPUT__' },
    { label: 'ticket.warehouse', value: '__INPUT__' },
    { label: 'ticket.technician', value: '__INPUT__' },
    { label: 'ticket.customer', value: '__INPUT__' },
  ]
  const form = fields({ profile: 'view', format: 'print', state })
  const field = Object.values(form).filter(({ id }) => id !== 'ticketItems')
  const content = [
    { type: 'field', value: field },
    { type: 'list', value: form.product },
  ]
  return { title, header, footer, content }
}

async function getData({ app, session, id, profile }) {
  const { merchantId } = app.state.staff
  const locationInput = { type: ['WAREHOUSE', 'COMPANY'] }
  const variables = { id, locationInput, merchantId, vendor: 'sdj' }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput, $merchantId: ID!, $vendor: String!) {
      plugin(merchantId: $merchantId, vendor: $vendor) {
        isActive
      }
      locations(input: $locationInput) {
        id
        name
        type
        extra {
          type
        }
      }
      productVariants {
        id
        name
        sku
      }
      purchaseTicket(id: $id) {
        ticketNo
        transDate
        extra
        status
        hash
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      purchaseTicketItems(ticketId: $id) {
        id
        toLocationId
        toLocationName
        productVariantId
        productVariantName
        sku
        quantity
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const { plugin, productVariants } = data
  const locations = filterLocations(app.state.staff, data.locations)
  const ticket = data.purchaseTicket || {}
  const ticketItems = data.purchaseTicketItems || []
  const { extra = {} } = ticket
  const srcCompanies = []
  const billCompanies = []
  const warehouses = []

  locations.forEach(({ id, name, type, extra }) => {
    if (type === 'COMPANY' && extra.type === 'PURCHASE') {
      srcCompanies.push({ value: id, label: name })
    }
    if (type === 'COMPANY' && extra.type === 'BILLING') {
      billCompanies.push({ value: id, label: name })
    }
    if (type === 'WAREHOUSE') {
      warehouses.push({ value: id, label: name })
    }
  })

  return {
    isReady: profile === 'edit',
    id,
    sync: extra.sync === undefined ? plugin.isActive : extra.sync,
    extra,
    // locked: extra.locked,
    ticketNo: ticket.ticketNo,
    // srcCompanyId: extra.srcCompanyId,
    // srcCompanyName: extra.srcCompanyName,
    // billCompanyId: extra.billCompanyId,
    // billCompanyName: extra.billCompanyName,
    transDate: ticket.transDate,
    // estimateDate: extra.estimateDate,
    // apiTransDate: extra.apiTransDate,
    // rackNo: extra.rackNo,
    // sealNo: extra.sealNo,
    // memo: extra.memo,
    status: ticket.status,
    hash: ticket.hash,
    createdAt: ticket.createdAt,
    createdBy: ticket.createdBy,
    updatedAt: ticket.updatedAt,
    updatedBy: ticket.updatedBy,
    ticketItems,
    oldTicketItems: cloneDeep(ticketItems),
    srcCompanies,
    billCompanies,
    warehouses,
    products: productVariants,
  }
}

async function addPurchase(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addPurchaseTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editPurchase(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editPurchaseTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function confirmPurchase({ session, app, id, hash }) {
  const input = { hash }
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      confirmPurchaseTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { hash, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    toLocationId: item.toLocationId,
    productVariantId: item.productVariantId,
    quantity: item.quantity || 0,
    extra: item.extra,
  }))
  const isKeyEqual = (item, newItem) => {
    return (
      item.id === newItem.id &&
      item.productVariantId === newItem.productVariantId
    )
  }
  const isValEqual = (item, newItem) => {
    if (item.toLocationId !== newItem.toLocationId) return false
    if (!isEqual(item.extra, newItem.extra)) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    hash,
    transDate: state.transDate || '3000-01-01',
    ticketNo: state.ticketNo,
    extra: {
      estimateDate: state.estimateDate,
      srcCompanyId: state.srcCompanyId.value,
      srcCompanyName: state.srcCompanyId.label,
      billCompanyId: state.billCompanyId.value,
      billCompanyName: state.billCompanyId.label,
      rackNo: state.rackNo,
      sealNo: state.sealNo,
      memo: state.memo,
      sync: state.sync,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}
