import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { getStatusColor } from 'constants/status'
import { initialState, fields, handlers } from 'models/refundPage'
import {
  Grid,
  Section,
  Button,
  Link,
  Center,
  StatusChip,
  Toolbar,
  Modal,
  Divider,
  Image,
} from 'components/core'
import Page from 'components/Page'
import RefundDrawer from 'views/RefundDrawer'
import { MdAdd, MdArrowBack, MdEdit, MdPrint } from 'react-icons/md'
import { renderDeleteLink, renderLockLink } from 'utilities/app'
import { handleLock } from 'actions/ticket'
import { useCallbackOnce } from 'hooks/useCallbackOnce'
import { Flex } from 'reflexbox'
import RefundAdjustDrawer from './RefundAdjustDrawer'
import RefundReceivedDrawer from './RefundReceivedDrawer'
// import RefundReceivedModal from './RefundReceivedModal'

export default ({ match, profile }) => {
  const { id } = match.params
  const url = new URL(window.location.href)
  const parentId = url.searchParams.get('parentId')
  const dispatchId = url.searchParams.get('dispatchId')
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [data, setData] = useState({})
  const [state, setState] = useState(initialState({ parentId }, data, message))
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const [receivedOpen, setReceivedOpen] = useState(false)
  const [receivedValue, setReceivedValue] = useState({})
  const [adjustOpen, setAdjustOpen] = useState(false)
  const [adjustValue, setAdjustValue] = useState({})
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const handleReceivedOpen = (item) => {
    setReceivedValue(item)
    setReceivedOpen(true)
  }

  const handleReceivedClose = () => {
    setReceivedOpen(false)
    setReceivedValue({})
  }

  const handleAdjustOpen = (item) => {
    setAdjustValue(item)
    setAdjustOpen(true)
  }

  const handleAdjustClose = () => {
    setAdjustOpen(false)
    setAdjustValue({})
  }

  const readonly = profile === 'view'
  const form = fields({
    profile,
    app,
    session,
    data,
    state,
    setState,
    message,
    action: {
      handleOpen,
      handleAdjustOpen,
      handleReceivedOpen,
      setPreviewOpen,
      setPreviewImage,
    },
  })
  const handler = handlers({
    state,
    setState,
    data,
    setData,
    app,
    session,
    message,
    history,
    id,
    parentId,
    dispatchId,
    profile,
  })

  useEffect(() => {
    handler.handleInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, profile, data])

  return (
    <>
      <Page
        profile={profile}
        navId="refundList"
        title={`refund.title.${profile}`}
        titleExtra={
          <StatusChip
            show={readonly}
            ml={2}
            label={`refund.status.${state.status}`}
            color={getStatusColor(state.status)}
            bg="light.1"
          />
        }
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href={profile === 'edit' ? `/refund/${id}/view` : '/refund'}
            />
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<MdPrint />}
              tooltip="btn.print"
              onClick={handler.handlePrint}
            />
            {renderDeleteLink({
              module: 'refund',
              session,
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handler.handleDelete,
            })}
            <Link
              ml={3}
              show={readonly}
              disabled={state.locked || state.status === 'INACTIVE'}
              variant="link"
              icon={<MdEdit />}
              tooltip="btn.edit"
              href={`/refund/${id}/edit`}
            />
            {renderLockLink({
              readonly,
              locked: state.locked,
              status: state.status,
              onSubmit: handleLock({
                module: 'refund',
                session,
                app,
                id,
                handleLoad: handler.handleLoad,
              }),
            })}
            <Button
              ml={3}
              show={!readonly}
              disabled={profile === 'edit' && !state.isReady}
              variant="primary"
              text="btn.save"
              type="submit"
            />
            {/* {renderConfirmButton({
            module: 'refund',
            session,
            profile,
            status: state.status,
            onSubmit: handler.handleConfirm,
          })} */}
            {/* {renderCompleteButton({
              module: 'refund',
              session,
              profile,
              status: state.status,
              onSubmit: handler.handleComplete,
            })} */}
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={useCallbackOnce(handler.handleSubmit)}
        audit={{
          createdBy: state.createdBy,
          createdAt: state.createdAt,
          updatedBy: state.updatedBy,
          updatedAt: state.updatedAt,
        }}
      >
        <Grid cols={['1fr', '1fr 1fr 1fr']} gap={3}>
          <Section>
            {form.id}
            {form.parentId}
            {form.ticketNo}
            {form.transDate}
            {form.dealer}
            {form.recipient}
            {form.warehouseGroup}
          </Section>
          <Section>
            {form.recipientContact}
            {form.recipientAddress}
            {form.recipientPhone}
            {form.recipientCellphone}
          </Section>
          <Section>
            {form.billing}
            {form.sales}
            {form.memo}
            {form.staff}
            {form.trackingNo}
          </Section>
        </Grid>
        <Section mt={5} title="refund.section.product">
          {form.product}
        </Section>
        <Section mt={5} title="refund.section.image">
          {form.images}
        </Section>
        <Section show={readonly} mt={5} title="refund.section.resultProduct">
          {/* <Toolbar>
            <Button
              disabled={
                state.ticketItems.length > 0 ||
                !hasAccess(app.state.staff, 'addRefundResult')
              }
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() => setReceivedOpen(true)}
            />
            <Button
              disabled={
                state.ticketItems.length === 0 ||
                !hasAccess(app.state.staff, 'addRefundResult')
              }
              variant="default"
              icon={<MdEdit />}
              text="btn.edit"
              onClick={() => setReceivedOpen(true)}
            />
          </Toolbar> */}
          {form.resultProduct}
        </Section>
        <Section show={readonly} mt={5} title="refund.section.adjustProduct">
          <Toolbar>
            <Button
              variant="default"
              icon={<MdAdd />}
              text="btn.add"
              onClick={() =>
                handleAdjustOpen({
                  index: -1,
                  ticketId: state.id,
                  hash: state.hash,
                })
              }
            />
          </Toolbar>
          {form.adjustProduct}
        </Section>
      </Page>
      {/* <RefundReceivedModal
        open={receivedOpen}
        data={{
          id: state.id,
          hash: state.hash,
          products: state.products,
          locations: state.warehouses,
          rcvdItems: state.rcvdItems,
          ticketItems: state.ticketItems,
        }}
        action={{
          handleClose: () => setReceivedOpen(false),
          handleSubmit: () => {
            handler.handleLoad()
            setReceivedOpen(false)
          },
        }}
      /> */}
      <RefundReceivedDrawer
        open={receivedOpen}
        value={receivedValue}
        data={{ locations: state.warehouses }}
        action={{
          handleClose: handleReceivedClose,
          handleSubmit: handler.handleLoad,
        }}
      />
      <RefundDrawer
        open={open}
        value={value}
        data={{
          products: state.products,
          locations: state.warehouses,
          rcvdItems: state.rcvdItems,
        }}
        action={{ handleClose, handleSubmit: handler.handleLoad }}
      />
      <RefundAdjustDrawer
        open={adjustOpen}
        value={adjustValue}
        data={{
          products: state.productVariants,
          locations: state.warehouses,
        }}
        action={{
          handleClose: handleAdjustClose,
          handleSubmit: handler.handleLoad,
        }}
      />
      <Modal open={previewOpen} onClose={() => setPreviewOpen(false)}>
        <Image {...previewImage} maxHeight="calc(100vh - 128px)" />
        <Divider bg="light.3" />
        <Flex justifyContent="flex-end" alignItems="center" mt={3}>
          <Button
            variant="secondary"
            text="btn.close"
            onClick={() => setPreviewOpen(false)}
          />
        </Flex>
      </Modal>
    </>
  )
}
