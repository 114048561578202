import account from './account/zh-TW'
import role from './role/zh-TW'
import staff from './staff/zh-TW'
import warehouse from './warehouse/zh-TW'
import salesDept from './salesDept/zh-TW'
import dealer from './dealer/zh-TW'
import dealerOrg from './dealerOrg/zh-TW'
import company from './company/zh-TW'
import category from './category/zh-TW'
import product from './product/zh-TW'
import productComboCode from './productComboCode/zh-TW'
import combo from './combo/zh-TW'
import purchase from './purchase/zh-TW'
import adjust from './adjust/zh-TW'
import move from './move/zh-TW'
import transferOut from './transferOut/zh-TW'
import transferIn from './transferIn/zh-TW'
import lock from './lock/zh-TW'
import reverse from './reverse/zh-TW'
import dispatch from './dispatch/zh-TW'
import sell from './sell/zh-TW'
import repair from './repair/zh-TW'
import repairFeedback from './repairFeedback/zh-TW'
import repairReport from './repairReport/zh-TW'
import refund from './refund/zh-TW'
import process from './process/zh-TW'
import defer from './defer/zh-TW'
import lend from './lend/zh-TW'
import transport from './transport/zh-TW'
import processConfig from './processConfig/zh-TW'
import inventory from './inventory/zh-TW'
import customer from './customer/zh-TW'
import plugin from './plugin/zh-TW'
import sy from './sy/zh-TW'
import sdj from './sdj/zh-TW'
import pxmart from './pxmart/zh-TW'
import carrefour from './carrefour/zh-TW'
import best from './best/zh-TW'
import report from './report/zh-TW'
import mrWarehouse from './mrWarehouse/zh-TW'
import vicInvoice from './vicInvoice/zh-TW'

export default {
  'error.required': '此欄位為必填欄',
  'error.requiredField': '{name}為必填欄',
  'error.min': '最小值需為{val}',
  'error.minLength': '最少字符需為{val}',
  'error.maxLength': '最多字符為{val}',
  'error.passNotMatch': '輸入密碼不一致',
  'error.invalidAddress': '街道、電梯為必填欄位',
  'error.token.expired': '請重新登錄！',
  'error.token.invalid': '帳戶與密碼不符！',
  'error.timestamp.invalid': '請稍後重試！',
  'error.staff.notFound': '帳戶與密碼不符！',
  'error.staff.invalidCredential': '帳戶與密碼不符！',
  'error.role.exist': '角色已存在！',
  'error.location.duplicate': '經銷商已存在！',
  'error.ticket.exist': '訂單已存在！',
  'error.ticket.duplicate': '訂單已存在！',
  'error.ticket.invalidBatchQuantity': '批號數量有誤！',
  'error.ticket.invalidWarehouseGroup': '倉庫與倉庫群不符！',
  'error.file-too-large': '圖檔「{filename}」需小於{maxSize}MB',
  'error.ticket.missingPurchaseQuantity': '出貨數量為必填欄位',
  'error.ticket.productNotFound': '查無此商品：{productCode}',
  'error.ticket.recipientNotFound': '查無此收貨處：{recipient}-{contact}',
  'error.ticketNo.required': '廠商單號為必填欄位',
  'error.product.required': '商品為必填欄位',
  'error.ticket.modified': '此訂單已被修改，請重新進入訂單詳情',
  'error.ticket.locked': '此訂單已被鎖定',
  'error.file-invalid-type': '檔案類型有誤！',
  'error.import.invalidFile': '檔案格式有誤！',
  'btn.submit': '提交',
  'btn.confirm': '確認',
  'btn.approve': '核准',
  'btn.complete': '完成',
  'btn.reject': '駁回',
  'btn.save': '儲存',
  'btn.back': '返回',
  'btn.cancel': '取消',
  'btn.preview': '預覽',
  'btn.close': '關閉',
  'btn.add': '新增',
  'btn.edit': '修改',
  'btn.delete': '刪除',
  'btn.more': '更多',
  'btn.sort': '排序',
  'btn.import': '匯入',
  'btn.export': '匯出',
  'btn.download': '下載',
  'btn.print': '列印',
  'btn.search': '搜尋',
  'btn.openSync': '開啟同步',
  'btn.closeSync': '關閉同步',
  'btn.sync': '重新同步',
  'btn.copy': '複製',
  'btn.locked': '已鎖定',
  'btn.unlocked': '未鎖定',
  'btn.pdf': '下載PDF',
  'placeholder.textfield': '請輸入{label}',
  'placeholder.time': 'hh:mm',
  'placeholder.select.create': '新增「{name}」',
  'save.success': '儲存成功！',
  'delete.success': '刪除成功！',
  'sync.success': '同步成功！',
  'sync.failed': '同步失敗！',
  'submit.success': '提交成功！',
  'submit.batch.success': '成功提交 {count} 筆{name}！',
  'submit.failed': '提交失敗！',
  'title.delete': '刪除{name}',
  'title.approve': '核准{name}',
  'title.complete': '完成{name}',
  'message.delete': '確認刪除{name}?',
  'message.approve': '確認核准{name}?',
  'message.complete': '確認完成{name}?',
  'message.empty': '[無資料]',
  'field.address': '地址',
  'field.zipcode': '郵遞區號',
  'field.city': '縣市',
  'field.district': '鄉鎮市區',
  'field.street': '巷弄 / 街道 / 門牌號',
  'field.elevator': '有電梯',
  'field.noElevator': '無電梯',
  'field.nullElevator': '待確認',
  'field.empty': '無資料',
  'address.tooltip.placeholder': '範例：中正路一段3巷5弄2-1號8樓之1',
  'elevator.true': '有電梯',
  'elevator.false': '無電梯',
  'field.seq': '序號',
  'field.ticketId': '單號',
  'field.parentId': '關聯單號',
  'field.ticketNo': '廠商單號',
  'field.type': '類型',
  'field.productName': '商品名稱',
  'field.sku': '品號',
  'field.productCode': '原廠代碼',
  'field.partName': '零件名稱',
  'field.partSku': '零件代碼',
  'field.warehouse': '倉別',
  'field.dealer': '經銷商',
  'field.org': '經銷群',
  'field.dept': '業務部門',
  'field.warehouseDealer': '倉別 / 經銷商',
  'field.dealerCode': '經銷商代碼',
  'field.storage': '收貨處',
  'field.shipping': '物流',
  'field.sales': '業務人員',
  'field.contact': '聯絡人',
  'field.contactPhone': '聯絡人電話',
  'field.contactCellphone': '聯絡人手機',
  'field.contactAddress': '聯絡人地址',
  'field.shipAddress': '收件地址',
  'field.billing': '歸帳',
  'field.customer': '客戶',
  'field.tech': '維修人員',
  'field.date': '日期',
  'field.quantity': '數量',
  'field.dispatchQuantity': '出貨量',
  'field.dispatchGiftQuantity': '出貨量(贈品)',
  'field.adjustQuantity': '調整量',
  'field.refundQuantity': '退貨量',
  'field.sellQuantity': '銷貨量',
  'field.sellGiftQuantity': '銷貨量(贈品)',
  'field.amount': '金額',
  'field.sellAmount': '銷貨金額',
  'field.sellGiftAmount': '銷貨金額(贈品)',
  'field.price': '金額',
  'field.unitPrice': '單價',
  'field.totalPrice': '總價',
  'field.subTotal': '小計',
  'field.total': '總計',
  'field.memo': '備註',
  'field.status': '狀態',
  'field.outboxStatus': '同步狀態',
  'field.locked': '已上鎖',
  'field.createdAt': '下單日',
  'type.true': '是',
  'type.false': '否',
  'status.ACTIVE': '已完成',
  'status.INACTIVE': '已刪除',
  'status.PENDING': '待審核',
  'status.CALLBACK_PENDING': '待三方確認',
  'status.ACTION_PENDING': '待處理',
  'outbox.status.PENDING': '待同步',
  'outbox.status.FAILED': '同步失敗',
  'itemStatus.ACTIVE': '正常',
  'itemStatus.INACTIVE': '刪除',
  'itemStatus.PENDING': '待處理',
  'module.variant': '商品',
  'module.purchase': '進倉單',
  'module.lock': '鎖貨單',
  'module.reverse': '歸還單',
  'module.dispatch': '出貨單',
  'module.sell': '銷貨單',
  'module.refund': '退貨單',
  'module.process': '加工單',
  'module.adjust': '調整單',
  'module.move': '調撥單',
  'module.transferOut': '跨系統調出單',
  'module.transferIn': '跨系統調入單',
  'module.defer': '延出單',
  'module.lend': '借貨單',
  'module.transport': '派工單',
  'module.repairType': '維修類型',
  'module.repairTag': '維修註記',
  'module.repairRemark': '維修附加內容',
  'module.repairFeedback': '維修滿意度',
  'module.repairReceipt': '維修單發票',
  'module.customerMemo': '客服內容',
  'app.stock': '慎康倉儲系統',
  'app.repair': '慎康維修系統',
  'app.login': '登錄',
  'app.logout': '登出',
  'app.signup': '註冊',
  'app.account': '帳戶管理',
  'app.login.required': '請先登錄會員！',
  'table.message.empty': '無資料',
  'table.pagination.totalPage': '{page}/{totalPage} 頁',
  'table.pagination.totalCount': '共 {totalCount} 筆',
  'filter.title': '搜尋條件',
  'filter.clear': '全部清除',
  'login.title': '登錄管理平台',
  'login.field.username': '帳號',
  'login.field.password': '密碼',
  'login.btn.login': '登錄',
  'login.success': '成功登錄',
  'logout.success': '成功登出',
  'filter.op.eq': '等於',
  'dashboard.title': 'Dashboard',
  'ticket.title': '訂單管理',
  'inventory.title': '訂單管理',
  'repair.title': '維修管理',
  'customer.title': '客戶管理',
  'plugin.title': '插件管理',
  'setting.title': '系統設置',
  'location.type.WAREHOUSE': '倉庫',
  'location.type.DEPT': '業務部門',
  'location.type.DEALER_ORG': '經銷群組',
  'location.type.DEALER': '經銷商',
  'dashboard.title.sync': '同步三方系統庫存差異',
  'dashboard.message.sync':
    '同步所需時長會依倉別與商品數量多寡而不同，確認同步？',
  'dashboard.btn.sync': '立即同步',
  'dashboard.sync.success': '同步成功',
  'ticket.address':
    '客戶服務電話: 北部02-8913-1001 中部04-2382-2630 南部07-382-2234',
  'ticket.phone': '客戶服務LINE ID: @ASERVICE',
  'ticket.manager': '主管',
  'ticket.accounting': '會計',
  'ticket.warehouse': '倉管',
  'ticket.technician': '工程師',
  'ticket.customer': '客戶',
  'ticket.createdAt': '創建日期',
  'ticket.createdBy': '創建人員',
  'ticket.updatedAt': '最後更新日期',
  'ticket.updatedBy': '最後更新人員',
  'ticketType.PURCHASE': '進倉',
  'ticketType.LOCK': '鎖貨',
  'ticketType.REVERSE': '歸還',
  'ticketType.MOVE': '調撥',
  'ticketType.TRANSFER_OUT': '跨系統調出',
  'ticketType.TRANSFER_IN': '跨系統調入',
  'ticketType.ADJUST': '調整',
  'ticketType.DISPATCH': '出貨',
  'ticketType.SELL': '銷貨',
  'ticketType.REFUND': '退貨',
  'ticketType.PROCESS': '加工',
  'ticketType.DEFER': '延出',
  'ticketType.LEND': '借貨',
  'ticketType.REPAIR': '維修',
  'report.title': '報表匯出',
  'report.title2': '報表匯出[新]',
  'report.title.staffDispatch': '業務出貨目錄',
  'report.title.staffSales': '業務業績表',
  'report.title.staffSales2': '業務業績表[新]',
  'report.title.dealerStock': '通路進銷表',
  'report.title.dealerStock2': '通路進銷表[新]',
  'report.title.dealerSales': '通路業績表',
  'report.title.dealerSales2': '通路業績表[新]',
  'report.title.warehouse': '庫存存量表',
  'report.title.repair': '維修紀錄表',
  ...account,
  ...category,
  ...product,
  ...productComboCode,
  ...role,
  ...staff,
  ...warehouse,
  ...salesDept,
  ...dealer,
  ...dealerOrg,
  ...company,
  ...purchase,
  ...adjust,
  ...move,
  ...transferOut,
  ...transferIn,
  ...inventory,
  ...customer,
  ...lock,
  ...reverse,
  ...dispatch,
  ...sell,
  ...repair,
  ...repairFeedback,
  ...repairReport,
  ...refund,
  ...process,
  ...defer,
  ...lend,
  ...transport,
  ...processConfig,
  ...combo,
  ...plugin,
  ...sy,
  ...sdj,
  ...pxmart,
  ...carrefour,
  ...best,
  ...report,
  ...mrWarehouse,
  ...vicInvoice,
}
