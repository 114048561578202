import React from 'react'
import { getLockStatusOptions, getStatusColor } from 'constants/status'
import { request } from 'utilities/request'
import { getSelectOptions, showAddress, showDate } from 'utilities/form'
import { textFilter, dateFilter, selectFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { LinkButton, StatusChip, Link } from 'components/core'
import { MdEdit, MdLock } from 'react-icons/md'
import { renderDeleteButton } from 'utilities/app'
import { handleDelete } from 'actions/ticket'
import { filterLocations, hasAccess } from 'utilities/permission'
import { FILTER_SET } from 'constants/actionType'
import { formatDate } from 'utilities/date'
import { getTypes } from './refundReceivedDrawer'
import { Flex } from 'reflexbox'

export const filters = ({ data, message }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    input: textFilter,
  },
  {
    id: 'transDate',
    label: 'lock.field.transDate',
    input: dateFilter,
    // defaultValue: [getDate(), addDays(getDate(), 1)],
  },
  {
    id: 'ticketNo',
    label: 'field.ticketNo',
    input: textFilter,
  },
  {
    id: 'fromLocation',
    label: 'refund.field.dealerId',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: getSelectOptions(data.dealers),
      }),
  },
  {
    id: 'recipientName',
    label: 'refund.field.recipientId',
    input: textFilter,
  },
  {
    id: 'recipientContact',
    label: 'refund.field.recipientContact',
    input: textFilter,
  },
  {
    id: 'sales',
    label: 'refund.field.salesId',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: data.sales,
      }),
  },
  {
    id: 'staff',
    label: 'refund.field.staffId',
    input: (props) =>
      selectFilter({
        ...props,
        isSearchable: true,
        options: [
          { value: '__EMPTY__', label: props.message({ id: 'message.empty' }) },
          ...data.staffs,
        ],
      }),
  },
  {
    id: 'status',
    label: 'field.status',
    input: (props) =>
      selectFilter({
        ...props,
        options: getStatusOptions(props.message),
      }),
  },
  {
    id: 'lockStatus',
    label: 'field.locked',
    input: (props) =>
      selectFilter({
        ...props,
        options: getLockStatusOptions(props.message),
      }),
  },
  {
    id: 'itemType',
    label: 'refund.field.type',
    input: (props) =>
      selectFilter({
        ...props,
        options: [
          ...getTypes(props.message),
          {
            value: 'ADJUST',
            label: message({ id: `refund.type.ADJUST` }),
          },
        ],
      }),
  },
  {
    id: 'productName',
    label: 'field.productName',
    input: textFilter,
  },
  {
    id: 'trackingNo',
    label: 'refund.field.trackingNo',
    input: textFilter,
  },
  {
    id: 'createdBy',
    label: 'ticket.createdBy',
    input: textFilter,
  },
]

function getStatusOptions(message) {
  return [
    { value: 'PENDING', label: message({ id: 'refund.status.PENDING' }) },
    {
      value: 'ACTION_PENDING',
      label: message({ id: 'refund.status.ACTION_PENDING' }),
    },
    { value: 'ACTIVE', label: message({ id: 'refund.status.ACTIVE' }) },
    { value: 'INACTIVE', label: message({ id: 'refund.status.INACTIVE' }) },
  ]
}

export const columns = ({ session, app, message, action }) => [
  {
    id: 'id',
    label: 'refund.field.id',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <Flex>
        <Link variant="primaryLink" href={`/refund/${row.id}/view`}>
          {row.id}
        </Link>
        {row.extra?.locked ? <MdLock color="#ffb445" /> : ''}
      </Flex>
    ),
  },
  {
    id: 'parentId',
    label: 'field.parentId',
    noWrap: true,
    format: ['excel'],
  },
  {
    id: 'transDate',
    label: 'refund.field.transDate',
    noWrap: true,
    format: ['html', 'excel'],
    render: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'ticketNo',
    label: 'refund.field.ticketNo',
    format: ['html', 'excel'],
  },
  {
    id: 'fromLocationName',
    label: 'refund.field.dealerId',
    format: ['html', 'excel'],
  },
  {
    id: 'recipientName',
    label: 'refund.field.recipientId',
    format: ['excel'],
    render: ({ row }) => row.extra?.recipientName,
  },
  {
    id: 'recipientAddress',
    label: 'refund.field.recipientAddress',
    format: ['excel'],
    render: ({ row }) => showAddress(row.extra?.recipientAddress, message),
  },
  {
    id: 'recipientContact',
    label: 'refund.field.recipientContact',
    format: ['excel'],
    render: ({ row }) => row.extra?.recipientContact,
  },
  {
    id: 'sales',
    label: 'refund.field.salesId',
    format: ['excel'],
    render: ({ row }) => {
      if (!row.extra?.salesName) return ''
      return row.extra?.salesName.join(', ')
    },
  },
  {
    id: 'memo',
    label: 'refund.field.memo',
    format: ['excel'],
    render: ({ row }) => row.extra?.memo,
  },
  {
    id: 'productVariantName',
    label: 'product.field.spu',
    format: ['excel'],
  },
  {
    id: 'sku',
    label: 'product.field.sku',
    format: ['excel'],
  },
  {
    id: 'productCode',
    label: 'field.productCode',
    format: ['excel'],
  },
  {
    id: 'quantity',
    label: 'refund.field.quantity',
    format: ['excel'],
  },
  {
    id: 'locked',
    label: 'field.locked',
    format: ['excel'],
    render: ({ row }) => (row.extra?.locked ? 'Y' : 'N'),
  },
  {
    id: 'status',
    label: 'refund.field.status',
    format: ['html', 'excel'],
    renderHtml: ({ row }) => (
      <StatusChip
        label={`refund.status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    render: ({ row }) => message({ id: `refund.status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    noWrap: true,
    render: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          disabled={
            row.extra.locked ||
            row.status === 'INACTIVE' ||
            !hasEditPermission(app.state.staff, row.transDate)
          }
          variant="icon"
          icon={<MdEdit />}
          href={`/refund/${row.id}/edit`}
        />
        {renderDeleteButton({
          module: 'refund',
          session,
          locked: row.extra.locked,
          status: row.status,
          onSubmit: () =>
            handleDelete('refund', {
              session,
              app,
              id: row.id,
              hash: row.hash,
              action,
            }),
        })}
      </>
    ),
  },
]

export const handlers = ({
  setState,
  session,
  app,
  message,
  setFilterValues,
  setPagination,
  setDealers,
  setSales,
  setStaffs,
}) => ({
  handleInit: async () => {
    const resp = await getInit({ session, app })
    setDealers(resp.locations)
    setSales(resp.sales)
    setStaffs(resp.staffs)
  },
  handleLoad: async ({ pagination, filterValues } = {}) => {
    const filterId = 'refundList'
    if (!filterValues) {
      filterValues = session.state.filterMap[filterId] || []
      pagination = session.state.paginationMap[filterId] || []
    }
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
    session.dispatch({
      type: FILTER_SET,
      key: filterId,
      filterValues,
      pagination,
    })
  },
  handleExport: async ({ filterValues, createExcel }) => {
    const title = 'refund.title.list'
    const cols = columns({ message }).filter(({ format = [] }) =>
      format.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows, filterValues })
  },
})

async function getInit({ session, app }) {
  const variables = {
    staffInput: { type: ['SALES', 'WAREHOUSE'] },
    locationInput: { type: ['DEALER'] },
  }
  const query = `
    query($locationInput: LocationQueryInput, $staffInput: StaffQueryInput) {
      locations(input: $locationInput) {
        id
        name
      }
      staffs(input: $staffInput) {
        id
        name
        type
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const locations = filterLocations(app.state.staff, data.locations)
  const sales = []
  const staffs = []

  data.staffs.forEach((item) => {
    const record = { value: item.id, label: item.name }
    switch (item.type) {
      case 'SALES':
        sales.push(record)
        break
      case 'WAREHOUSE':
        staffs.push(record)
        break
      default:
    }
  })

  return { locations, sales, staffs }
}

async function getData({ session, app, pagination = {}, filterValues = [] }) {
  const variables = { input: getDataInput({ filterValues, pagination }) }
  const query = `
    query($input: TicketQueryInput) {
      refundTicketCount(input: $input)
      refundTickets(input: $input) {
        id
        ticketNo
        fromLocationName
        transDate
        extra
        status
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.refundTickets,
    pagination: getPagination(page, countPerPage, data.refundTicketCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: TicketQueryInput) {
      refundTickets(input: $input) {
        id
        parentId
        ticketNo
        fromLocationName
        productVariantName
        sku
        productCode
        quantity
        transDate
        extra
        itemExtra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.refundTickets.filter((item) => {
    const { itemExtra } = item
    return itemExtra?.isReceived
  })
}

function getDataInput({ filterValues, pagination, joinItem = false }) {
  const id = filterValues.find(({ id }) => id === 'id')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const ticketNo = filterValues.find(({ id }) => id === 'ticketNo')
  const fromLocation = filterValues.find(({ id }) => id === 'fromLocation')
  const recipientName = filterValues.find(({ id }) => id === 'recipientName')
  const recipientContact = filterValues.find(
    ({ id }) => id === 'recipientContact',
  )
  const sales = filterValues.find(({ id }) => id === 'sales')
  const staff = filterValues.find(({ id }) => id === 'staff')
  const status = filterValues.find(({ id }) => id === 'status')
  const lockStatus = filterValues.find(({ id }) => id === 'lockStatus')
  const itemType = filterValues.find(({ id }) => id === 'itemType')
  const productName = filterValues.find(({ id }) => id === 'productName')
  const trackingNo = filterValues.find(({ id }) => id === 'trackingNo')
  const createdBy = filterValues.find(({ id }) => id === 'createdBy')
  const input = {
    joinItem,
    showProductCode: true,
    id: id?.value,
    ticketNo: ticketNo?.value,
    transDate: transDate?.value,
    fromLocationId: fromLocation?.value.value,
    extra: {
      recipientName: recipientName?.value,
      recipientContact: recipientContact?.value,
      salesId: sales?.value.value,
      staffId: staff?.value.value,
      locked: lockStatus?.value.value,
      trackingNo: trackingNo?.value,
    },
    itemRefundType: itemType?.value.value,
    status: status?.value.value,
    productVariantName: productName?.value,
    createdBy: createdBy?.value,
    orderBy: [{ key: 'id', sort: 'DESC' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

function hasEditPermission(staff, transDate) {
  if (hasAccess(staff, 'lockRefundTicket')) return true

  const editableDate = new Date()
  editableDate.setDate(editableDate.getDate() - 3)

  return formatDate(editableDate) <= formatDate(new Date(transDate))
}
