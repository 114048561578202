import React from 'react'
import { ALERT_ADD, CONFIRM_SET } from 'constants/actionType'
import { Button, Center, Text, Icon } from 'components/core'
import { MdDelete, MdEdit, MdErrorOutline } from 'react-icons/md'
import { request } from 'utilities/request'
import { showAddress, validateRows } from 'utilities/form'
import { getTag } from 'actions/token'
import { today } from 'utilities/date'

export const initialState = (value) => ({
  dealers: value.dealers,
  recipients: value.recipients,
  ticketItems: value.ticketItems || [],
  errorItems: [],
})

const validation = {
  ticketNo: [{ type: 'required', message: 'error.required' }],
  productVariantId: [{ type: 'required', message: 'error.required' }],
}

export const columns = ({ session, state, setState, message, action }) => {
  const renderText = (id, label, align = 'left') => ({
    id,
    label,
    align,
    cellProps: { py: 2 },
    render: ({ row }) => <Text sx={{ whiteSpace: 'nowrap' }}>{row[id]}</Text>,
  })

  const renderBoolean = (id, label) => ({
    id,
    label,
    cellProps: { py: 2 },
    render: ({ row }) => (
      <Text sx={{ whiteSpace: 'nowrap' }}>{row[id] ? 'Y' : 'N'}</Text>
    ),
  })

  const renderSelect = (id, label) => ({
    id,
    label,
    cellProps: { py: 2 },
    render: ({ row }) => (
      <Text sx={{ whiteSpace: 'nowrap' }}>{row[id]?.label}</Text>
    ),
  })

  const renderMultiSelect = (id, label) => ({
    id,
    label,
    cellProps: { py: 2 },
    render: ({ row }) => (
      <Text sx={{ whiteSpace: 'nowrap' }}>
        {row[id]?.map(({ label }) => label).join(', ')}
      </Text>
    ),
  })

  const renderAddress = (id, label) => ({
    id,
    label,
    cellProps: { py: 2 },
    render: ({ row }) => (
      <Text sx={{ whiteSpace: 'nowrap' }}>{showAddress(row[id], message)}</Text>
    ),
  })

  return [
    renderText('transDate', 'defer.field.transDate'),
    renderText('ticketNo', 'defer.field.ticketNo'),
    renderSelect('billingId', 'defer.field.billingId'),
    renderSelect('dealerId', 'defer.field.dealerId'),
    renderText('dealerCode', 'defer.field.dealerCode'),
    renderSelect('recipientId', 'defer.field.recipientId'),
    renderText('recipientContact', 'defer.field.recipientContact'),
    renderAddress('recipientAddress', 'defer.field.recipientAddress'),
    renderText('elevator', 'field.elevator'),
    renderText('recipientPhone', 'defer.field.recipientPhone'),
    renderText('recipientCellphone', 'defer.field.recipientCellphone'),
    renderText('recipientEmail', 'defer.field.recipientEmail'),
    renderMultiSelect('salesId', 'defer.field.salesId'),
    renderMultiSelect('backupId', 'defer.field.backupId'),
    renderText('memo', 'defer.field.memo'),
    renderText('contractFromDate', 'dealer.field.contractFromDate'),
    renderText('contractToDate', 'dealer.field.contractToDate'),
    renderText('contractSignDate', 'dealer.field.contractSignDate'),
    renderText('contractAmount', 'dealer.field.contractAmount'),
    renderBoolean('issueReceipt', 'dealer.field.issueReceipt'),
    renderSelect('productVariantId', 'field.productName'),
    renderBoolean('isDemo', 'defer.field.isDemo'),
    renderBoolean('isGift', 'defer.field.isGift'),
    renderBoolean('isContract', 'defer.field.isContract'),
    renderText('quantity', 'field.quantity'),
    renderText('price', 'field.price'),
    renderText('itemMemo', 'defer.field.itemMemo'),
    {
      id: 'actions',
      align: 'right',
      headProps: {
        width: '100px',
        height: '56px',
        bg: 'light.0',
        sx: {
          position: 'sticky',
          top: 0,
          right: 0,
          zIndex: 1,
          borderLeftWidth: '1px',
          borderLeftStyle: 'solid',
          borderLeftColor: 'light.3',
        },
      },
      cellProps: {
        py: 2,
        width: '100px',
        bg: 'light.0',
        sx: {
          position: 'sticky',
          right: 0,
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'light.3',
          borderLeftWidth: '1px',
          borderLeftStyle: 'solid',
          borderLeftColor: 'light.3',
        },
      },
      render: ({ row, index }) => {
        const err = state.errorItems.find(
          ({ ticketId }) => ticketId === row.ticketId,
        )
        return (
          <Center>
            <Icon
              color={err ? 'error.1' : 'light.3'}
              px={1}
              tooltip={err?.message}
              tooltipProps={{ place: 'bottom', type: 'error' }}
              value={<MdErrorOutline />}
            />
            <Button
              px={1}
              variant="icon"
              icon={<MdEdit />}
              onClick={() => action.handleOpen({ ...row, index })}
            />
            <Button
              variant="icon"
              icon={<MdDelete />}
              onClick={() => {
                const item = {
                  open: true,
                  title: { id: 'defer.title.deleteImportItem' },
                  text: { id: 'defer.message.deleteImportItem' },
                  onSubmit: () => {
                    const ticketItems = [...state.ticketItems]
                    ticketItems.splice(index, 1)
                    setState({ ...state, ticketItems })
                    return true
                  },
                }
                session.dispatch({ type: CONFIRM_SET, item })
              }}
            />
          </Center>
        )
      },
    },
  ]
}

export const handlers = ({
  app,
  session,
  state,
  data,
  setState,
  history,
  setDisabled,
  setProgress,
}) => ({
  handleLoad: async () => {},
  handleSubmit: async (event) => {
    event.preventDefault()

    setDisabled(true)

    const { ticketItems } = state
    const inputErrors = validateRows(ticketItems, validation)
    if (inputErrors.length > 0) {
      setState({ ...state, errorItems: inputErrors })
      setDisabled(false)
      return
    }

    const tickets = getSubmitInput(state, data)
    const ticketCount = tickets.length
    const errorItems = []
    let successCount = 0

    try {
      for (let i = 0; i < ticketCount; i++) {
        const ticket = tickets[i]
        const { ticketNo } = ticket
        const tag = await getTag({ session, app })
        const [ok, err] = await addDeferTicket(app, ticket, tag)

        if (ok) {
          successCount++
        } else {
          errorItems.push({ ticketNo, message: err[0].message })
        }

        setProgress(Math.floor((i / ticketCount) * 100))
      }

      if (successCount > 0) {
        session.dispatch({
          type: ALERT_ADD,
          item: {
            type: 'success',
            message: {
              id: 'submit.batch.success',
              values: { count: successCount },
              texts: { name: 'module.dispatch' },
            },
          },
        })
      }

      if (errorItems.length === 0) {
        history.push('/defer')
        return
      }

      const idSet = new Set(errorItems.map(({ ticketNo }) => ticketNo))
      setState({
        ...state,
        ticketItems: ticketItems.filter(({ ticketNo }) => idSet.has(ticketNo)),
        errorItems,
      })
    } finally {
      setDisabled(false)
      setProgress(null)
    }
  },
  editTicketItem: ({ index, ...value }) => {
    const ticketItems = [...state.ticketItems]
    ticketItems.splice(index, 1, value)

    const errorItems = state.errorItems.filter(
      ({ ticketNo }) => ticketNo !== value.ticketNo,
    )

    setState({ ...state, ticketItems, errorItems })
  },
})

async function addDeferTicket(app, input, tag) {
  const variables = { input }
  const query = `
    mutation($input: TicketInput!) {
      addDeferTicket(input: $input)
    }
  `
  return request({ query, variables }, { app, tag })
}

function getSubmitInput(state, data) {
  const { ticketItems } = state
  const { dealerLocations, recipientLocations } = data
  return ticketItems.reduce((result, item) => {
    let ticket = result.find(({ ticketNo }) => ticketNo === item.ticketNo)
    const dealerId = item.dealerId?.value
    const recipientId = item.recipientId?.value
    if (!ticket) {
      ticket = {
        ticketNo: `${item.ticketNo || ''}`,
        transDate: item.transDate || today(),
        toLocationId: dealerId,
        extra: {
          recipientId,
          recipientName: item.recipientId?.label,
          recipientContact: item.recipientContact,
          recipientAddress: item.recipientAddress,
          recipientPhone: item.recipientPhone,
          recipientCellphone: item.recipientCellphone,
          dealerCode: getDealerCode(
            dealerId,
            dealerLocations,
            recipientId,
            recipientLocations,
          ),
          recipientEmail: item.recipientEmail,
          billingId: item.billingId?.value,
          billingName: item.billingId?.label,
          salesId: item.salesId?.map((item) => item.value),
          salesName: item.salesId?.map((item) => item.label),
          backupId: item.backupId?.map((item) => item.value),
          backupName: item.backupId?.map((item) => item.label),
          memo: item.memo,
          contractFromDate: item.contractFromDate,
          contractToDate: item.contractToDate,
          contractSignDate: item.contractSignDate,
          contractAmount: item.contractAmount,
          issueReceipt: item.issueReceipt,
        },
        itemsToAdd: [],
      }
      result.push(ticket)
    }

    ticket.itemsToAdd.push(getTicketItem(item))
    return result
  }, [])
}

function getDealerCode(dealerId, dealers, recipientId, recipients) {
  const recipient = recipients.find((item) => item.id === recipientId)
  if (recipient?.extra.code) return recipient?.extra.code

  const dealer = dealers.find((item) => item.id === dealerId)
  return dealer?.extra.code ? dealer.extra.code : ''
}

function getTicketItem(item) {
  return {
    productVariantId: item.productVariantId?.value,
    isGift: item.isGift,
    quantity: parseInt(item.quantity || 0),
    price: parseFloat(item.price || 0),
    extra: {
      isDemo: item.isDemo,
      isGift: item.isGift,
      isContract: item.isContract,
      memo: item.itemMemo,
    },
  }
}
